import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  DropdownProps,
  Grid,
  Icon,
  Input,
  Modal
} from 'semantic-ui-react';
import api, { axiosHeaderJson } from '../../utils/helpers/axios.instance';
import { matchB2SDropdownOptions } from '../../utils/helpers/matchDropdownOptions';
import { IDropDown } from '../Commom/_interfaces/dropdown.interface';
import AxiosErrors from '../Errors/AxiosErrors';
import {
  IB2sList,
  IMatchUpdate,
  IUpdateReturn
} from './_interfaces/match.interfaces';

interface Props {
  catalogB2S: IB2sList[] | undefined;
  itemUpdate: IMatchUpdate | null;
  updateClick: boolean;
  setUpdateClick: (value: boolean) => void;
  setItemUpdateReturn: (data: IUpdateReturn) => void;
}

const MatchModalUpdate: React.FC<Props> = ({
  itemUpdate,
  setUpdateClick,
  updateClick,
  setItemUpdateReturn,
  catalogB2S
}) => {
  const [b2sItem, setB2sItem] = useState<
    string | number | true | (string | number | boolean)[]
  >('');
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [b2sOptions, setB2sOptions] = useState<IDropDown[]>([]);
  const [oldSkuB2S, setOldSkuB2s] = useState<number | null>(null);
  const [oldB2SClient, setOldB2SClient] = useState<string | null>(null);
  const [dataUpdate, setDataUpdate] = useState<IMatchUpdate | null>(null);
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const modal = () => {
      if (updateClick && itemUpdate) {
        setDataUpdate(itemUpdate);
        setOldB2SClient(itemUpdate.b2s.b2S_DESC);
        setB2sItem(itemUpdate.b2s.b2S_SKU);
        setOldSkuB2s(itemUpdate.b2s.b2S_SKU);
      }

      if (catalogB2S) {
        setB2sOptions(matchB2SDropdownOptions(catalogB2S));
      }
    };
    modal();
  }, [setUpdateClick, itemUpdate, updateClick, catalogB2S]);

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    setIsLoadingButton(true);
    if (dataUpdate && itemUpdate) {
      try {
        const { b2S_SKU } = dataUpdate.b2s;
        const { sku_cliente } = itemUpdate;
        const updateData = {
          b2S_SKU,
          sku_cliente
        };
        await api.put(`match/${oldSkuB2S}`, updateData, axiosHeaderJson);
        const data = catalogB2S?.find((catalog) => catalog.b2S_SKU === b2S_SKU);
        if (data && oldSkuB2S) {
          const returnData = {
            oldSkuB2S,
            data
          };
          setItemUpdateReturn(returnData);
          // setSuccess(true);
        }
      } catch (error) {
        setErrors(error.response);
      }
    }
    setIsLoadingButton(false);
  };

  const handleChange = async (e: React.SyntheticEvent, data: DropdownProps) => {
    e.preventDefault();
    setIsLoadingButton(true);
    if (data && data.value) {
      setB2sItem(data.value);
      if (dataUpdate) {
        const { desc_cliente, sku_cliente } = dataUpdate;
        const b2s = catalogB2S?.find((b2s) => b2s.b2S_SKU === data.value);
        if (b2s) {
          const data = {
            sku_cliente,
            desc_cliente,
            b2s
          };
          setDataUpdate(data);
          setIsLoadingButton(false);
        }
      }
    }
  };

  return (
    <Modal
      onUnmount={() => {
        setUpdateClick(false);
      }}
      onClose={() => setUpdateClick(false)}
      open={updateClick}
      size="large"
    >
      <Modal.Header>
        <Icon name="edit" />
        Editar
      </Modal.Header>
      {errors ? <AxiosErrors error={errors} /> : ''}
      <Modal.Content>
        <Modal.Description>
          <Grid verticalAlign="top">
            <Grid.Row columns={1}>
              <Grid.Column>
                <Input
                  readOnly
                  fluid
                  name="desc_cliente"
                  label={{ color: 'blue', content: 'Descrição cliente' }}
                  value={dataUpdate && dataUpdate.desc_cliente}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Input
                  fluid
                  name="b2S_DESC"
                  label={{ color: 'yellow', content: 'Item B2S Antigo' }}
                  value={oldB2SClient}
                  readOnly
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row columns={1}>
              <Grid.Column>
                <label>
                  <strong>Novo Item B2S</strong>
                </label>
                <Dropdown
                  options={b2sOptions}
                  placeholder="Escolha o novo item B2S para associar"
                  search
                  selection
                  fluid
                  disabled={isLoadingButton}
                  value={b2sItem || ''}
                  onChange={(e: React.SyntheticEvent, data: DropdownProps) =>
                    handleChange(e, data)
                  }
                  noResultsMessage="Não foi encontrado nenhum registro"
                  name="b2s_desc_new"
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3} verticalAlign="middle">
              <Grid.Column>
                <Input
                  name="b2S_SKU"
                  label={{ color: 'teal', content: 'B2S SKU' }}
                  value={dataUpdate && dataUpdate.b2s.b2S_SKU}
                  readOnly
                />
              </Grid.Column>
              <Grid.Column>
                <Input
                  name="b2S_CATEGORIA"
                  readOnly
                  label={{ color: 'teal', content: 'Categoria' }}
                  value={
                    dataUpdate && dataUpdate.b2s?.b2S_CATEGORIA.split('-')[1]
                  }
                />
              </Grid.Column>
              {/* <Grid.Column>
                <Input
                  name="prinC_ATIVO"
                  readOnly
                  label={{ color: 'teal', content: 'Princípio Ativo' }}
                  value={
                    dataUpdate &&
                    dataUpdate?.b2s.prinC_ATIVO.toLowerCase() === 'none'
                      ? ''
                      : dataUpdate?.b2s.prinC_ATIVO
                  }
                />
              </Grid.Column> */}
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button.Group>
          <Button
            color="grey"
            loading={isLoadingButton}
            style={{ marginRight: '10px' }}
            onClick={() => {
              setUpdateClick(false);
              setSuccess(false);
            }}
          >
            Voltar
          </Button>
          <Button
            loading={isLoadingButton}
            color="green"
            floated="right"
            style={{ marginRight: '10px' }}
            onClick={(e: React.MouseEvent) => handleSubmit(e)}
            disabled={success}
            icon={success ? 'check' : 'paper plane outline'}
            labelPosition="right"
            content={success ? 'Editado com sucesso!' : 'Atualizar'}
          />
        </Button.Group>
      </Modal.Actions>
    </Modal>
  );
};

export default MatchModalUpdate;
