const formatNumbers = (
  value: number,
  style: 'decimal' | 'currency'
): string => {
  return new Intl.NumberFormat('pt-BR', { style, currency: 'BRL' }).format(
    value
  );
};

export default formatNumbers;
