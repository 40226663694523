import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Header,
  Icon,
  Message,
  Modal,
  Segment
} from 'semantic-ui-react';
import api, { axiosHeaderMultipart } from '../../utils/helpers/axios.instance';
import ConfirmSecondModal from '../Commom/ConfirmSecondModal';
import AxiosUploadErrors from '../Errors/AxiosUploadErrors';

interface Props {
  upload: boolean;
  flagCnpj?: boolean;
  flagGeo?: boolean;
  flagItem?: boolean;
  mutate: () => void;
  setUpload?: (value: boolean) => void;
  setFlagCnpj?: (value: boolean) => void;
  setFlagGeo?: (value: boolean) => void;
  setFlagItem?: (value: boolean) => void;
}

const RulesModalUpload: React.FC<Props> = ({
  upload,
  setUpload,
  setFlagGeo,
  flagGeo,
  setFlagItem,
  flagItem,
  setFlagCnpj,
  flagCnpj
  // mutate
}) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [check, setCheck] = useState<CheckboxProps | boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [errors, setErrors] = useState(false);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleConfirmationButton = () => {
    if (setUpload) {
      setOpen(false);
      setUpload(false);
      setFile(null);
      setConfirmation(false);
      // mutate();
    }
  };

  const handleSendFile = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setIsLoading(true);
      try {
        if (flagItem) {
          await api.post('items/excel', formData, axiosHeaderMultipart);
          if (setFlagItem) {
            setFlagItem(false);
          }
        } else if (flagGeo) {
          await api.post('geo/excel', formData, axiosHeaderMultipart);
          if (setFlagGeo) {
            setFlagGeo(false);
          }
        } else if (flagCnpj) {
          await api.post('cnpj/excel', formData, axiosHeaderMultipart);
          if (setFlagCnpj) {
            setFlagCnpj(false);
          }
        }
        setConfirmation(true);
      } catch (error) {
        setErrors(error.response);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const uploadModal = () => {
      if (upload) {
        setOpen(true);
      }
    };
    uploadModal();
  }, [upload, open]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmer="blurring"
      size="large"
    >
      (
      <>
        <Modal.Header>Importar nova planilha</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <strong>
              <Message
                icon="warning"
                attached="top"
                content="Lembre-se que quando fizer o upload, todos os dados antigos serão perdidos e apenas os novos que estão na planilha serão válidos"
                color="red"
              />
            </strong>
            <Segment placeholder loading={isLoading}>
              <Header icon>
                <Icon name="file excel" />
                {file
                  ? `Arquivo ${file.name.toUpperCase()} foi selecionado`
                  : 'Importe um novo arquivo .xlsx'}
              </Header>
              <Button color="blue" as="label" htmlFor="file">
                Carregar arquivo
                <input
                  hidden
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleUpload(e)
                  }
                />
              </Button>
            </Segment>
            <strong>
              <Checkbox
                onClick={(e, data: CheckboxProps) => setCheck(data)}
                label="Estou de acordo que após clicar em enviar, TODOS os dados antigos serão perdidos e apenas os que estiverem na nova planilha serão válidos"
              />
            </strong>
          </Modal.Description>
        </Modal.Content>
        {errors ? <AxiosUploadErrors error={errors} /> : ''}
        <Modal.Actions>
          <Button
            onClick={() => {
              if (setUpload) {
                setOpen(false);
                setUpload(false);
                setFile(null);
              }
            }}
            loading={isLoading}
            content="Fechar"
          />
          <Button
            onClick={handleSendFile}
            icon="upload"
            color="green"
            disabled={!!(file === null || !check)}
            labelPosition="right"
            content="Enviar!"
            loading={isLoading}
          />
        </Modal.Actions>
        <ConfirmSecondModal
          confirmation={confirmation}
          errors={errors}
          setConfirmation={handleConfirmationButton}
          customMsg="Item inserido com sucesso"
        />
      </>
    </Modal>
  );
};

export default RulesModalUpload;
