import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownProps,
  Grid,
  Icon,
  Input,
  Modal
} from 'semantic-ui-react';
import api, { axiosHeaderJson } from '../../utils/helpers/axios.instance';
import { matchB2SDropdownOptions } from '../../utils/helpers/matchDropdownOptions';
import { IDropDown } from '../Commom/_interfaces/dropdown.interface';

import AxiosErrors from '../Errors/AxiosErrors';
import { IB2sList, IMatchInsert } from './_interfaces/match.interfaces';

interface Props {
  catalogB2S: IB2sList[] | undefined;
  itemInsert: IMatchInsert | null;
  insertClick: boolean;
  setInsertClick: (value: boolean) => void;
  setItemInsertReturn: (data: IB2sList) => void;
}

const MatchModalInsert: React.FC<Props> = ({
  itemInsert,
  setInsertClick,
  insertClick,
  setItemInsertReturn,
  catalogB2S
}) => {
  const [b2sItem, setB2sItem] = useState<
    string | number | true | (string | number | boolean)[]
  >('');
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [b2sOptions, setB2sOptions] = useState<IDropDown[]>([]);
  const [dataInsert, setDataInsert] = useState<IMatchInsert | undefined>();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    const modal = () => {
      if (insertClick && itemInsert) {
        setDataInsert(itemInsert);
      }

      if (catalogB2S) {
        setB2sOptions(matchB2SDropdownOptions(catalogB2S));
      }
    };
    modal();
  }, [insertClick, itemInsert, catalogB2S]);

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    setIsLoadingButton(true);
    if (dataInsert && dataInsert.b2s && itemInsert) {
      try {
        const b2s_sku: number[] = [];
        const { b2S_SKU } = dataInsert?.b2s;
        b2s_sku.push(b2S_SKU);
        const { sku_cliente } = itemInsert;
        const data = {
          b2s_sku,
          sku_cliente
        };
        await api.post(`match`, data, axiosHeaderJson);
        const returnData = catalogB2S?.find(
          (catalog) => catalog.b2S_SKU === b2S_SKU
        );
        if (returnData) {
          setItemInsertReturn(returnData);
          setB2sItem('');
          setInsertClick(false);
        }
      } catch (error) {
        setErrors(error.response);
      }
    }
    setIsLoadingButton(false);
  };

  const handleChange = async (e: React.SyntheticEvent, data: DropdownProps) => {
    e.preventDefault();
    setIsLoadingButton(true);
    if (data && data.value) {
      setB2sItem(data.value);
      const b2s = catalogB2S?.find((b2s) => b2s.b2S_SKU === data.value);
      if (b2s && dataInsert) {
        setDataInsert({ ...dataInsert, b2s });
      }
    }
    setIsLoadingButton(false);
  };

  return (
    <Modal
      onUnmount={() => {
        setInsertClick(false);
        setB2sItem('');
      }}
      onClose={() => {
        setInsertClick(false);
        setB2sItem('');
      }}
      open={insertClick}
      size="large"
    >
      {errors ? <AxiosErrors error={errors} /> : ''}
      <Modal.Header>
        <Icon name="plus" color="green" />
        Inserir nova associação
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Grid verticalAlign="top">
            <Grid.Row columns={1}>
              <Grid.Column>
                <Input
                  readOnly
                  fluid
                  name="desc_cliente"
                  label={{ color: 'blue', content: 'Descrição cliente' }}
                  value={dataInsert && dataInsert.desc_cliente}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <label>Item B2S</label>
                <Dropdown
                  options={b2sOptions}
                  placeholder="Item B2S para associar"
                  search
                  selection
                  fluid
                  disabled={isLoadingButton}
                  value={b2sItem}
                  onChange={(e: React.SyntheticEvent, data: DropdownProps) =>
                    handleChange(e, data)
                  }
                  noResultsMessage="Não foi encontrado nenhum registro"
                  name="b2S_DESC"
                  label="Descrição Item B2S"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3} verticalAlign="middle">
              <Grid.Column>
                <Input
                  name="b2S_SKU"
                  label={{ color: 'teal', content: 'B2S SKU' }}
                  value={
                    dataInsert?.b2s?.b2S_SKU ? dataInsert.b2s?.b2S_SKU : ''
                  }
                  readOnly
                />
              </Grid.Column>
              <Grid.Column>
                <Input
                  name="b2S_CATEGORIA"
                  readOnly
                  label={{ color: 'teal', content: 'Categoria' }}
                  value={
                    dataInsert?.b2s?.b2S_CATEGORIA
                      ? dataInsert.b2s?.b2S_CATEGORIA.split('-')[1]
                      : ''
                  }
                />
              </Grid.Column>
              {/* <Grid.Column>
                <Input
                  name="prinC_ATIVO"
                  readOnly
                  label={{ color: 'teal', content: 'Princípio Ativo' }}
                  value={
                    dataInsert?.b2s?.prinC_ATIVO
                      ? dataInsert.b2s.prinC_ATIVO.toLowerCase() === 'none'
                        ? ''
                        : dataInsert.b2s.prinC_ATIVO
                      : ''
                  }
                />
              </Grid.Column> */}
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button.Group>
          <Button
            color="grey"
            loading={isLoadingButton}
            style={{ marginRight: '10px' }}
            onClick={() => {
              setInsertClick(false);
              setSuccess(false);
              setB2sItem('');
            }}
          >
            Voltar
          </Button>
          <Button
            loading={isLoadingButton}
            color="green"
            floated="right"
            style={{ marginRight: '10px' }}
            onClick={(e: React.MouseEvent) => handleSubmit(e)}
            disabled={success}
            icon={success ? 'check' : 'paper plane outline'}
            labelPosition="right"
            content={success ? 'Associado com sucesso!' : 'Associar'}
          />
        </Button.Group>
      </Modal.Actions>
    </Modal>
  );
};

export default MatchModalInsert;
