import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Confirm,
  Divider,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Popup
} from 'semantic-ui-react';
import ItemsContext from '../../contexts/ItemsContext';
import api from '../../utils/helpers/axios.instance';
import ConfirmModal from '../Commom/ConfirmModal';

import Loading from '../Commom/Loading';
import { IError } from '../Commom/_interfaces/error.interface';
import AxiosErrors from '../Errors/AxiosErrors';

import MatchModalInsert from './MatchModalInsert';
import MatchModalUpdate from './MatchModalUpdate';
import {
  IB2sList,
  IMatch,
  IMatchInsert,
  IMatchUpdate,
  IUpdateReturn
} from './_interfaces/match.interfaces';

interface Props {
  modalItem: IMatch | null;
  click: boolean;
  catalogB2S: IB2sList[] | undefined;
  catalogError: IError | undefined;
  mutateMatch: () => void;
  mutateCatalog: () => void;
  mutateRawMatch: () => void;
  setClick: (value: boolean) => void;
}

const MatchModal: React.FC<Props> = ({
  modalItem,
  setClick,
  click,
  mutateMatch,
  catalogB2S,
  mutateCatalog,
  mutateRawMatch
}) => {
  const { mutateItems } = useContext(ItemsContext);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [modalData, setModalData] = useState<IMatch | null>(modalItem);
  const [itemUpdate, setItemUpdate] = useState<IMatchUpdate | null>(null);
  const [itemInsert, setItemInsert] = useState<IMatchInsert | null>(null);
  const [errors, setErrors] = useState<boolean | IError>(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmRemoveAlert, setConfirmRemoveAlert] = useState(false);
  const [refreshFetch, setRefreshFetch] = useState(true);
  const [removeB2S, setRemoveB2S] = useState<IB2sList | null>(null);
  const [updateClick, setUpdateClick] = useState(false);
  const [
    itemUpdateReturn,
    setItemUpdateReturn
  ] = useState<IUpdateReturn | null>(null);
  const [itemInsertReturn, setItemInsertReturn] = useState<IB2sList | null>(
    null
  );
  const [insertClick, setInsertClick] = useState(false);

  useEffect(() => {
    const modal = () => {
      if (click && modalItem && refreshFetch) {
        setModalData(modalItem);
        setOpen(true);
        setRefreshFetch(false);
      }
    };
    modal();
  }, [modalItem, click, refreshFetch]);

  useEffect(() => {
    const updateModal = async () => {
      if (itemUpdateReturn && modalData) {
        const { data } = itemUpdateReturn;
        const listab2s =
          modalData.listab2s &&
          modalData.listab2s.filter(
            (list) => list.b2S_SKU !== itemUpdateReturn.oldSkuB2S
          );
        listab2s?.push(data);
        setModalData({ ...modalData, listab2s });
        setItemUpdateReturn(null);
        setUpdateClick(false);
      } else if (itemInsertReturn && modalData) {
        const { listab2s } = modalData;
        listab2s?.push(itemInsertReturn);
        setModalData({ ...modalData, listab2s });
        setItemInsertReturn(null);
        setInsertClick(false);
      }
      mutateCatalog();
      mutateRawMatch();
    };
    updateModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemUpdateReturn, modalData, itemInsertReturn]);

  const handleUpdateClick = (updateClick: boolean) => {
    setUpdateClick(updateClick);
  };

  const handleInsertClick = (insertClick: boolean) => {
    setInsertClick(insertClick);
  };

  const handleUpdateReturn = (itemUpdateReturn: IUpdateReturn) => {
    setItemUpdateReturn(itemUpdateReturn);
  };

  const handleInsertReturn = (itemInsertReturn: IB2sList) => {
    setItemInsertReturn(itemInsertReturn);
  };

  const handleEditButton = (e: React.MouseEvent, b2s: IB2sList) => {
    if (modalData) {
      const data: IMatchUpdate = {
        b2s,
        desc_cliente: modalData.desc_cliente,
        sku_cliente: modalData.sku_cliente
      };
      setItemUpdate(data);
      setUpdateClick(true);
    }
  };

  const handleInsertButton = () => {
    if (modalData) {
      const data: IMatchInsert = {
        desc_cliente: modalData.desc_cliente,
        sku_cliente: modalData.sku_cliente
      };
      setItemInsert(data);
      setInsertClick(true);
    }
  };

  const handleConfirmationButton = () => {
    setOpen(false);
    setClick(false);
    setConfirmation(false);
    setModalData(null);
  };

  const handleConfirmDeleteAll = async (
    e: React.MouseEvent,
    sku_cliente: string
  ): Promise<void | undefined> => {
    e.preventDefault();
    setIsLoadingButton(true);
    try {
      await api.delete(`match/${sku_cliente}`);
      setConfirmation(true);
      mutateMatch();
      mutateItems();
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoadingButton(false);
  };

  const handleRemove = (e: React.MouseEvent, data: IB2sList) => {
    e.preventDefault();
    setConfirmRemoveAlert(true);
    setRemoveB2S(data);
  };

  const handleConfirmationRemove = async (e: React.MouseEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setIsLoadingButton(true);
    setConfirmRemoveAlert(false);
    if (modalData && removeB2S) {
      const { b2S_SKU } = removeB2S;
      const { sku_cliente } = modalData;
      try {
        await api.delete(
          `match/remove?b2s_sku=${b2S_SKU}&sku_cliente=${sku_cliente}`
        );
        if (modalData) {
          const listab2s = await modalData.listab2s?.filter(
            (list) => list.b2S_SKU !== b2S_SKU
          );
          setModalData({ ...modalData, listab2s });
        }
        setRefreshFetch(false);
      } catch (error) {
        if (error.response) {
          setErrors(error.response);
        } else {
          setErrors({ status: 400 });
        }
      }
    }
    setIsLoadingButton(false);
    setIsLoading(false);
  };

  return (
    <Modal
      onUnmount={() => {
        setOpen(false);
        setClick(false);
        setModalData(null);
        mutateMatch();
        mutateItems();
      }}
      onClose={() => {
        setOpen(false);
        mutateMatch();
        mutateItems();
      }}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
    >
      {confirmRemoveAlert ? (
        <Confirm
          open={confirmRemoveAlert}
          header="Tem certeza que deseja remover esta associação?"
          content="Você irá remover permanentemente esta associação ao apertar em confirmar"
          onConfirm={(e: React.MouseEvent) => handleConfirmationRemove(e)}
          onCancel={() => setConfirmRemoveAlert(false)}
          confirmButton="Confirmar"
          cancelButton="Cancelar"
        />
      ) : (
        ''
      )}

      {!modalData ? (
        <Loading />
      ) : (
        <React.Fragment>
          {confirmation ? (
            <ConfirmModal
              confirmation={confirmation}
              errors={errors}
              setConfirmation={handleConfirmationButton}
            />
          ) : (
            <React.Fragment>
              {errors ? <AxiosErrors error={errors} /> : ''}
              <Modal.Header>{modalData?.desc_cliente}</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <Grid verticalAlign="top">
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Input
                          readOnly
                          label={{ color: 'blue', content: 'SKU Cliente' }}
                          fluid
                          name="sku_cliente"
                          value={modalData.sku_cliente}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column>
                        <Divider horizontal>
                          <Header as="h3" color="blue">
                            <Icon name="list alternate outline" size="large" />
                            Itens Associados
                          </Header>
                        </Divider>
                      </Grid.Column>
                    </Grid.Row>
                    {modalData &&
                      modalData.listab2s?.map((b2s: IB2sList) => (
                        <React.Fragment key={b2s.b2S_SKU}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Input
                                readOnly
                                fluid
                                label={{
                                  color: 'green',
                                  content: 'Descrição Item B2S'
                                }}
                                value={b2s.b2S_DESC}
                                style={{ marginRight: '10px' }}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={3} verticalAlign="middle">
                            <Grid.Column>
                              <Input
                                label={{ color: 'teal', content: 'B2S SKU' }}
                                value={b2s.b2S_SKU}
                                readOnly
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Input
                                readOnly
                                label={{ color: 'teal', content: 'Categoria' }}
                                value={
                                  b2s.b2S_CATEGORIA &&
                                  b2s.b2S_CATEGORIA.split('-')[1]
                                }
                                style={{ marginRight: '10px' }}
                              />
                            </Grid.Column>
                            {/* <Grid.Column>
                              <Input
                                readOnly
                                label={{
                                  color: 'teal',
                                  content: 'Princípio Ativo'
                                }}
                                style={{ marginRight: '10px' }}
                                value={
                                  b2s.prinC_ATIVO?.toLowerCase() === 'none'
                                    ? ''
                                    : b2s.prinC_ATIVO
                                }
                              />
                            </Grid.Column> */}
                          </Grid.Row>
                          <Grid.Row style={{ textAlign: '-webkit-center' }}>
                            <Grid.Column>
                              <Button.Group>
                                <Button
                                  content="Editar associação"
                                  color="blue"
                                  icon="edit"
                                  labelPosition="right"
                                  onClick={(e: React.MouseEvent) =>
                                    handleEditButton(e, b2s)
                                  }
                                  loading={isLoadingButton}
                                  style={{ marginRight: '10px' }}
                                />
                                <Button
                                  content="Remover esta associação"
                                  color="red"
                                  icon="close"
                                  labelPosition="right"
                                  onClick={(e: React.MouseEvent) =>
                                    handleRemove(e, b2s)
                                  }
                                  loading={isLoadingButton}
                                  style={{ marginRight: '10px' }}
                                />
                              </Button.Group>
                            </Grid.Column>
                          </Grid.Row>
                          <Divider />
                        </React.Fragment>
                      ))}
                  </Grid>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button.Group>
                  {modalData ? (
                    <Popup
                      trigger={
                        <Button
                          color="red"
                          loading={isLoading}
                          style={{ marginRight: '10px' }}
                        >
                          Excluir
                        </Button>
                      }
                      on="click"
                      open={isOpen}
                      onClose={() => setIsOpen(false)}
                      onOpen={() => setIsOpen(true)}
                      position="top right"
                    >
                      <Popup.Header>
                        <span>Tem certeza?</span>
                      </Popup.Header>
                      <Popup.Content>
                        <p>
                          Ao confirmar, você irá excluir <strong>TODAS</strong>{' '}
                          as associações deste registro
                        </p>
                        <Button
                          loading={isLoading}
                          color="red"
                          content="Confirmar exclusão"
                          onClick={(event: React.MouseEvent) =>
                            handleConfirmDeleteAll(event, modalData.sku_cliente)
                          }
                        />
                      </Popup.Content>
                    </Popup>
                  ) : (
                    ''
                  )}
                  <Button
                    color="grey"
                    loading={isLoadingButton}
                    onClick={() => {
                      setOpen(false);
                      setClick(false);
                      setModalData(null);
                    }}
                    style={{ marginRight: '10px' }}
                  >
                    Fechar
                  </Button>
                  <Button
                    color="green"
                    loading={isLoadingButton}
                    onClick={() => {
                      handleInsertButton();
                    }}
                    style={{ marginRight: '10px' }}
                  >
                    Novo
                  </Button>
                </Button.Group>
              </Modal.Actions>
            </React.Fragment>
          )}
          <MatchModalUpdate
            catalogB2S={catalogB2S}
            itemUpdate={itemUpdate}
            updateClick={updateClick}
            setUpdateClick={handleUpdateClick}
            setItemUpdateReturn={handleUpdateReturn}
          />
          <MatchModalInsert
            catalogB2S={catalogB2S}
            itemInsert={itemInsert}
            insertClick={insertClick}
            setInsertClick={handleInsertClick}
            setItemInsertReturn={handleInsertReturn}
          />
        </React.Fragment>
      )}
    </Modal>
  );
};

export default MatchModal;
