/* eslint-disable @typescript-eslint/no-explicit-any */
import states from '../constants/states.json';
import { IReports } from '../../components/Views/Reports';
import { IDropDown } from '../../components/Commom/_interfaces/dropdown.interface';
import _ from 'lodash';

const formatReportChart = (
  data: IReports[],
  key: string
): {
  finalArray: Array<string | number>;
  header: string[];
  names: IDropDown[];
  top8: Array<string | number>;
} => {
  const statesArray = states.map((st) => st.key);
  const header = ['Name'].concat(statesArray);

  const list: any[] = [];
  const names: IDropDown[] = [];
  if (data.length > 0 || data) {
    for (let i = 0; i < data.length; i++) {
      const aux: Array<string | number> = [];
      const name = data[i].Value[0].name.substring(0, 55);
      aux.push(name);
      for (let j = 0; j < statesArray.length; j++) {
        let val;
        if (key === 'totalEstado') {
          val = data[i].Value[0].totalEstado.filter(
            (f: any) => f.key === statesArray[j]
          );
        } else {
          val = data[i].Value[0].quantidadeEstado.filter(
            (f: any) => f.key === statesArray[j]
          );
        }
        aux.push(val.length > 0 ? val[0].value : 0);
      }
      list.push(aux);
    }
  }

  const resume = list.map((li) => {
    return {
      name: li[0],
      ...li
    };
  });

  const grouped = _.chain(resume)
    .groupBy('name')
    .map((value, key) => {
      return { name: key, items: value };
    })
    .value();

  const finalArray: any[] = [];
  const top8: any[] = [];
  grouped.forEach((el) => {
    let dt: any;
    // eslint-disable-next-line prefer-const
    dt = el.items.reduce((sum, curr) => {
      const reduceArray = [];
      for (let i = 0; i < 27; i++) {
        sum[i] = sum[i] ? sum[i] : 0;
        reduceArray.push(sum[i] + curr[`${i + 1}`]);
      }
      return reduceArray;
    }, []);

    names.push({
      value: el.name,
      text: el.name,
      key: el.name
    });

    finalArray.push([el.name.substring(0, 55), ...dt]);

    top8.push([
      el.name.substring(0, 55),
      ...dt.sort((a: any, b: any) => b - a).slice(0, 7)
    ]);

    return finalArray;
  });

  return { finalArray, header, names, top8 };
};

export default formatReportChart;
