const cnpjFormatter = (cnpj: string): string => {
  if (cnpj.length !== 14) {
    return cnpj;
  } else {
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }
};

export default cnpjFormatter;
