/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Message } from 'semantic-ui-react';
import handleCognitoErrors from '../../utils/constants/handleCognitoErrors';

interface Props {
  props: any;
}

const CognitoNegativeMessage: React.FC<Props> = ({ props }) => {
  return (
    <Message negative>
      <Message.Header>{handleCognitoErrors(props)}</Message.Header>
    </Message>
  );
};

export default CognitoNegativeMessage;
