import React from 'react';
import { Button, Grid } from 'semantic-ui-react';

interface Props {
  handleExport: () => Promise<void>;
  handleDownload: () => Promise<void>;
  handleUpload: (value: boolean) => void;
  handleCreateClick: (value: boolean) => void;
  isLoadingButton: boolean;
  hideButtons?: boolean;
}

const RulesButtons: React.FC<Props> = ({
  handleExport,
  handleDownload,
  handleUpload,
  handleCreateClick,
  isLoadingButton,
  hideButtons = false
}) => {
  return hideButtons ? (
    <>
      <Grid.Column largeScreen={2} tablet={4} mobile={1}>
        <Button
          loading={isLoadingButton}
          content="Exportar"
          icon="file alternate outline"
          labelPosition="right"
          color="youtube"
          onClick={handleExport}
        />
      </Grid.Column>
      <Grid.Column largeScreen={2} tablet={4} mobile={1}>
        <Button
          content="Novo"
          icon="plus"
          labelPosition="right"
          color="green"
          onClick={() => handleCreateClick(true)}
        />
      </Grid.Column>
    </>
  ) : (
    <>
      <Grid.Column largeScreen={2} tablet={4} mobile={1}>
        <Button
          loading={isLoadingButton}
          content="Exportar"
          icon="file alternate outline"
          labelPosition="right"
          color="youtube"
          onClick={handleExport}
        />
      </Grid.Column>
      <Grid.Column largeScreen={2} tablet={4} mobile={1}>
        <Button
          content="Novo"
          icon="plus"
          labelPosition="right"
          color="green"
          onClick={() => handleCreateClick(true)}
        />
      </Grid.Column>
      <Grid.Column largeScreen={2} tablet={4} mobile={1}>
        <Button
          content="Importar"
          icon="file excel"
          labelPosition="right"
          color="blue"
          onClick={() => handleUpload(true)}
        />
      </Grid.Column>
      <Grid.Column largeScreen={3} tablet={4} mobile={1}>
        <Button
          loading={isLoadingButton}
          content="Baixar modelo"
          icon="cloud download"
          labelPosition="right"
          color="teal"
          onClick={handleDownload}
        />
      </Grid.Column>
    </>
  );
};

export default RulesButtons;
