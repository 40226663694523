import React, { useState } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import { IDropDown } from '../../Commom/_interfaces/dropdown.interface';
import { IMapPoints } from '../_interfaces/reports.interfaces';

interface Props {
  mapPoints: IMapPoints[];
  setIsSearching: (value: boolean) => void;
  handleSearchResult: (searchResult: IMapPoints[]) => void;
}

const ReportSearch: React.FC<Props> = ({
  mapPoints,
  setIsSearching,
  handleSearchResult
}) => {
  const [searchResult, setSearchResult] = useState<IMapPoints[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<string>('item_name');

  const options: IDropDown[] = [
    {
      key: 'item_name',
      value: 'item_name',
      text: 'Item'
    },
    {
      key: 'city',
      value: 'city',
      text: 'Cidade'
    }
  ];

  const handleSearchChange = (value: string | undefined) => {
    setIsLoading(true);
    if (value === '') {
      setSearch('');
      setIsSearching(false);
      handleSearchResult([]);
      setSearchResult([]);
    } else if (value !== undefined) {
      setSearch(value);
      setSearchResult(
        mapPoints.filter((point) =>
          point[filter].toLowerCase().includes(value.toLowerCase())
        )
      );
      setIsSearching(true);
      if (searchResult.length === 0) {
        handleSearchResult([]);
      } else {
        handleSearchResult(searchResult);
      }
    } else {
      setSearch('');
      setIsSearching(false);
      handleSearchResult([]);
      setSearchResult([]);
    }
    setIsLoading(false);
  };

  return (
    <Input
      label={
        <Dropdown
          defaultValue="item_name"
          options={options}
          onChange={(e, data) => setFilter(data.value as keyof IMapPoints)}
        />
      }
      loading={isLoading}
      labelPosition="left"
      placeholder="Procurar..."
      value={search}
      onChange={(e, data) => handleSearchChange(data.value)}
    />
  );
};

export default ReportSearch;
