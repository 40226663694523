import React from 'react';
import IssuesItems from '../Issues/IssuesItems';

const Issues: React.FC = () => {
  return (
    <div style={{ height: '100vh' }}>
      <IssuesItems />
    </div>
  );
};

export default Issues;
