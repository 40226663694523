import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const Loading: React.FC = () => {
  return (
    <React.Fragment>
      <Dimmer active inverted>
        <Loader indeterminate size="large">
          Carregando...
        </Loader>
      </Dimmer>
    </React.Fragment>
  );
};

export default Loading;
