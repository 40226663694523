import React from 'react';
import { Embed } from 'semantic-ui-react';
interface Props {
  source?: string;
}

const Iframe: React.FC<Props> = ({ source }) => {
  return (
    <>
      {(source != null || undefined || '') && (
        <>
          <div style={{ height: 'auto' }}>
            <Embed
              url={source}
              brandedUI={false}
              defaultActive={true}
              style={{
                border: 'none',
                top: '0',
                width: '100%',
                left: '0',
                height: '400vw'
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Iframe;
