import * as Blockly from 'blockly/core';
import 'blockly/python'
import 'blockly/javascript';

Blockly.JavaScript['get_tag_1'] = function (block) {
  return [block.getField('TAG_1').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_tag_1'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'tag_1',
      Blockly.JavaScript.ORDER_NONE) || '0';
  return `tag_1 = ${argument0} \n`
};

Blockly.Python['get_tag_1'] = function (block) {
  return [block.getField('TAG_1').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_tag_1'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'tag_1',
      Blockly.Python.ORDER_NONE) || '0';
  return `tag_1 = ${argument0} \n`
};

Blockly.JavaScript['get_tag_2'] = function (block) {
  return [block.getField('TAG_2').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_tag_2'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'tag_2',
    Blockly.JavaScript.ORDER_NONE) || '0';
  return `tag_2 = ${argument0} \n`;
};

Blockly.Python['get_tag_2'] = function (block) {
  return [block.getField('TAG_2').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_tag_2'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'tag_2',
      Blockly.Python.ORDER_NONE) || '0';
  return `tag_2 = ${argument0} \n`;
};

Blockly.JavaScript['get_tag_3'] = function (block) {
  return [block.getField('TAG_3').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_tag_3'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'tag_3',
    Blockly.JavaScript.ORDER_NONE) || '0';
  return `tag_3 = ${argument0} \n`;
};

Blockly.Python['get_tag_3'] = function (block) {
  return [block.getField('TAG_3').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_tag_3'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'tag_3',
      Blockly.Python.ORDER_NONE) || '0';
  return `tag_3 = ${argument0} \n`;
};

Blockly.JavaScript['get_tag_4'] = function (block) {
  return [block.getField('TAG_4').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_tag_4'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'tag_4',
    Blockly.JavaScript.ORDER_NONE) || '0';
  return `tag_4 = ${argument0} \n`;
};

Blockly.Python['get_tag_4'] = function (block) {
  return [block.getField('TAG_4').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_tag_4'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'tag_4',
      Blockly.Python.ORDER_NONE) || '0';
  return `tag_4 = ${argument0} \n`;
};

Blockly.JavaScript['get_tag_5'] = function (block) {
  return [block.getField('TAG_5').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_tag_5'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'tag_5',
    Blockly.JavaScript.ORDER_NONE) || '0';
  return `tag_5 = ${argument0} \n`;
};

Blockly.Python['get_tag_5'] = function (block) {
  return [block.getField('TAG_5').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_tag_5'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'tag_5',
      Blockly.Python.ORDER_NONE) || '0';
  return `tag_5 = ${argument0} \n`;
};

Blockly.JavaScript['get_comprador_cnpj'] = function (block) {
  return [block.getField('COMPRADOR_CNPJ').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_comprador_cnpj'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'comprador_cnpj',
    Blockly.JavaScript.ORDER_NONE) || '0';
  return `comprador_cnpj = ${argument0} \n`;
}

Blockly.Python['get_comprador_cnpj'] = function (block) {
  return [block.getField('COMPRADOR_CNPJ').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_comprador_cnpj'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'comprador_cnpj',
      Blockly.Python.ORDER_NONE) || '0';
  return `comprador_cnpj = ${argument0} \n`;
};

Blockly.JavaScript['get_comprador_cidade'] = function (block) {
  return [block.getField('COMPRADOR_CIDADE').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_comprador_cidade'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'comprador_cidade',
    Blockly.JavaScript.ORDER_NONE) || '0';
  return `comprador_cidade = ${argument0} \n`;
};

Blockly.Python['get_comprador_cidade'] = function (block) {
  return [block.getField('COMPRADOR_CIDADE').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_comprador_cidade'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'comprador_cidade',
    Blockly.Python.ORDER_NONE) || '0';
  return `comprador_cidade = ${argument0} \n`;
};

Blockly.JavaScript['get_comprador_estado'] = function (block) {
  return [block.getField('COMPRADOR_ESTADO').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_comprador_estado'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'comprador_estado',
    Blockly.JavaScript.ORDER_NONE) || '0';
  return `comprador_estado = ${argument0} \n`;
};

Blockly.Python['get_comprador_estado'] = function (block) {
  return [block.getField('COMPRADOR_ESTADO').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_comprador_estado'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'comprador_estado',
      Blockly.Python.ORDER_NONE) || '0';
  return `comprador_estado = ${argument0} \n`;
};

Blockly.JavaScript['get_quantidade'] = function (block) {
  return [block.getField('QUANTIDADE').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_quantidade'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'quantidade',
      Blockly.JavaScript.ORDER_NONE) || '0';
  return `quantidade = ${argument0} \n`
};

Blockly.Python['get_quantidade'] = function (block) {
  return [block.getField('QUANTIDADE').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_quantidade'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'quantidade',
      Blockly.Python.ORDER_NONE) || '0';
  return `quantidade = ${argument0} \n`
};

Blockly.JavaScript['get_cliente_partnumber'] = function (block) {
  return [block.getField('CLIENTE_PARTNUMBER').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_cliente_partnumber'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'cliente_partnumber',
      Blockly.JavaScript.ORDER_NONE) || '0';
  return `cliente_partnumber = ${argument0} \n`
};

Blockly.Python['get_cliente_partnumber'] = function (block) {
  return [block.getField('CLIENTE_PARTNUMBER').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_cliente_partnumber'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'cliente_partnumber',
      Blockly.Python.ORDER_NONE) || '0';
  return `cliente_partnumber = ${argument0} \n`
};

Blockly.JavaScript['get_cliente_preco'] = function (block) {
  return [block.getField('CLIENTE_PRECO').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_cliente_preco'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'cliente_preco',
      Blockly.JavaScript.ORDER_NONE) || '0';
  return `cliente_preco = ${argument0} \n`
};

Blockly.Python['get_cliente_preco'] = function (block) {
  return [block.getField('CLIENTE_PRECO').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_cliente_preco'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'cliente_preco',
    Blockly.Python.ORDER_NONE) || '0';
  return `cliente_preco = ${argument0} \n`
};

Blockly.JavaScript['get_multiplicador_preco'] = function (block) {
  return [block.getField('MULTIPLICADOR_PRECO').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_multiplicador_preco'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'multiplicador_preco',
      Blockly.JavaScript.ORDER_NONE) || '0';
  return `multiplicador_preco = ${argument0} \n`
};

Blockly.Python['get_multiplicador_preco'] = function (block) {
  return [block.getField('MULTIPLICADOR_PRECO').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_multiplicador_preco'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'multiplicador_preco',
    Blockly.Python.ORDER_NONE) || '0';
  return `multiplicador_preco = ${argument0} \n`
};

Blockly.JavaScript['get_enabled'] = function (block) {
  return [block.getField('ENABLED').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_enabled'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'enabled',
      Blockly.JavaScript.ORDER_NONE) || '0';
  return `enabled = ${argument0} \n`
};

Blockly.Python['get_enabled'] = function (block) {
  return [block.getField('ENABLED').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_enabled'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'enabled',
    Blockly.Python.ORDER_NONE) || '0';
  return `enabled = ${argument0} \n`
};

Blockly.JavaScript['get_cliente_fatmin'] = function (block) {
  return [block.getField('CLIENTE_FATMIN').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_cliente_fatmin'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'cliente_fatmin',
      Blockly.JavaScript.ORDER_NONE) || '0';
  return `cliente_fatmin = ${argument0} \n`
};

Blockly.Python['get_cliente_fatmin'] = function (block) {
  return [block.getField('CLIENTE_FATMIN').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_cliente_fatmin'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'cliente_fatmin',
    Blockly.Python.ORDER_NONE) || '0';
  return `cliente_fatmin = ${argument0} \n`
};

Blockly.JavaScript['get_cliente_frete'] = function (block) {
  return [block.getField('CLIENTE_FRETE').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_cliente_frete'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'cliente_frete',
      Blockly.JavaScript.ORDER_NONE) || '0';
  return `cliente_frete = ${argument0} \n`
};

Blockly.Python['get_cliente_frete'] = function (block) {
  return [block.getField('CLIENTE_FRETE').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_cliente_frete'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'cliente_frete',
    Blockly.Python.ORDER_NONE) || '0';
  return `cliente_frete = ${argument0} \n`
};

Blockly.JavaScript['get_cliente_condpagto'] = function (block) {
  return [block.getField('CLIENTE_CONDPAGTO').getText(), Blockly.JavaScript.ORDER_ADDITION]
};

Blockly.JavaScript['set_cliente_condpagto'] = function (block) {
  var argument0 = Blockly.JavaScript.valueToCode(block, 'cliente_condpagto',
      Blockly.JavaScript.ORDER_NONE) || '0';
  return `cliente_condpagto = ${argument0} \n`
};

Blockly.Python['get_cliente_condpagto'] = function (block) {
  return [block.getField('CLIENTE_CONDPAGTO').getText(), Blockly.Python.ORDER_NONE]
};

Blockly.Python['set_cliente_condpagto'] = function (block) {
  var argument0 = Blockly.Python.valueToCode(block, 'cliente_condpagto',
    Blockly.Python.ORDER_NONE) || '0';
  return `cliente_condpagto = ${argument0} \n`
};
