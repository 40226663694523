/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Button, Table } from 'semantic-ui-react';
import dateTimeConverter from '../../../utils/helpers/dateTimeConverter';
import { IActivity } from '../_interfaces/activity.interfaces';

interface Props {
  tableData: IActivity[];
  setModal: (value: IActivity) => void;
  setClick: (value: boolean) => void;
}

const ActivityTableRows: React.FC<Props> = ({
  tableData,
  setModal,
  setClick
}): JSX.Element | null => {
  let result;
  const handleClick = (item: IActivity) => {
    setModal(item);
    setClick(true);
  };

  const createTableRows = (array: IActivity[]) => {
    const tableArray = array.map((data) => (
      <Table.Row
        key={Math.random() * 99999}
        positive={data.status_cotacao.startsWith('COTADO')}
        negative={!data.status_cotacao.startsWith('COTADO')}
      >
        <Table.Cell>
          <Button icon="search" onClick={() => handleClick(data)} />
        </Table.Cell>
        <Table.Cell width={1}>{data.external_id}</Table.Cell>
        <Table.Cell width={6}>{data.comprador_nome.split('(')[0]}</Table.Cell>
        <Table.Cell
          width={3}
        >{`${data.comprador_cidade}/${data.comprador_estado}`}</Table.Cell>
        <Table.Cell width={2}>
          {dateTimeConverter(data.data_expiracao, 'DD/MM/YY - HH:mm:ss')}
        </Table.Cell>
        <Table.Cell width={1}>{data.status_cotacao}</Table.Cell>
        <Table.Cell width={2}>{`R$ ${data.total}`}</Table.Cell>
      </Table.Row>
    ));

    return tableArray;
  };
  return <>{createTableRows(tableData)}</>;
};

export default ActivityTableRows;
