/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { IUserData } from '../components/Commom/_interfaces/user-data.interface';
import api from '../utils/helpers/axios.instance';
import { IError } from '../components/Commom/_interfaces/error.interface';
import { addToken, addIdToken, removeToken } from '../utils/service/auth';

interface IAppContext {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  userHasAuthenticated: (value: boolean) => void;
  userData: IUserData;
  errorsAppContext: IError | boolean;
  onLoad: () => void;
}

export type Attributes = {
  email: string;
  email_verified: boolean;
  name: string;
  sub: string;
};

export type CognitoResponse = {
  attributes: Attributes;
  signInUserSession: {
    accessToken: {
      jwtToken: string;
    };
    idToken: {
      jwtToken: string;
    };
  };
};

const AppContext = createContext<IAppContext>({} as IAppContext);

export const AppProvider: React.FC = ({ children }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const [errorsAppContext, setErrorsAppContext] = useState<IError | boolean>(
    false
  );

  async function onLoad() {
    try {
      const {
        attributes,
        signInUserSession
      }: CognitoResponse = await Auth.currentAuthenticatedUser();
      if (attributes) {
        addToken(signInUserSession.accessToken.jwtToken);
        addIdToken(signInUserSession.idToken.jwtToken);
        const { data } = await api.get(`/user/cognito/${attributes.sub}`);
        if (data) {
          setUserData({ attributes, data });
          userHasAuthenticated(true);
        } else {
          userHasAuthenticated(false);
          removeToken();
        }
      }
    } catch (e) {
      setIsAuthenticating(false);
    }
  }

  useEffect(() => {
    onLoad();
  }, [isAuthenticated]);

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        userData,
        isAuthenticating,
        userHasAuthenticated,
        errorsAppContext,
        onLoad
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
