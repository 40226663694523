import React from 'react';
import { Table } from 'semantic-ui-react';

const GeoTableHeader: React.FC = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell>Comprador Estado</Table.HeaderCell>
        <Table.HeaderCell>Multiplicador</Table.HeaderCell>
        <Table.HeaderCell>Ativo</Table.HeaderCell>
        <Table.HeaderCell>Cliente Frete</Table.HeaderCell>
        <Table.HeaderCell>Cliente Faturamento Mínimo</Table.HeaderCell>
        <Table.HeaderCell>Cliente Condição de Pagamento</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export default GeoTableHeader;
