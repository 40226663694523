import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import AppContext from '../../contexts/AppContext';

export interface AuthRoutesProps extends RouteProps {
  children: React.ReactNode;
}

const AuthenticatedRoute: React.FC<AuthRoutesProps> = ({
  children,
  ...rest
}) => {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useContext(AppContext);
  return (
    <Route {...rest}>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect to={`/auth/entrar?redirect=${pathname}${search}`} />
      )}
    </Route>
  );
};

export default AuthenticatedRoute;
