/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownProps,
  Form,
  Grid,
  Input,
  InputOnChangeData,
  Label,
  Modal
} from 'semantic-ui-react';
import api, { axiosHeaderJson } from '../../../utils/helpers/axios.instance';
import { validateCnpjModal } from '../../../utils/helpers/validateCnpjModal';
import {
  IError,
  ICnpjFormErrors
} from '../../Commom/_interfaces/error.interface';
import { ICnpj } from '../_interfaces/cnpj.interfaces';
import { freteOptions } from '../Geo/GeoModal';
import ConfirmSecondModal from '../../Commom/ConfirmSecondModal';
import { IDropDown } from '../../Commom/_interfaces/dropdown.interface';
import { condPgtoOptions } from '../../../utils/helpers/condPgtoDropdown';

interface Props {
  modalItem: ICnpj;
  click: boolean;
  create: boolean;
  setClick: (value: boolean) => void;
  setCreate: (value: boolean) => void;
  mutate: () => void;
}

const CnpjModal: React.FC<Props> = ({
  modalItem,
  create,
  click,
  setClick,
  setCreate,
  mutate
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [modalData, setModalData] = useState<ICnpj>({} as ICnpj);
  const [errors, setErrors] = useState<IError | boolean>(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [condPgto, setCondPgto] = useState<IDropDown[]>([]);
  const [formErrors, setFormErrors] = useState<ICnpjFormErrors | null>(null);
  const [clienteFrete, setClienteFrete] = useState<any>('');
  const [clienteCondPgto, setClienteCondPgto] = useState<any>('');
  const numPattern = /^\d*\.?\d*$/;
  const [numPatternError, setNumPatternError] = useState<undefined | string>(
    undefined
  );

  useEffect(() => {
    const condPgto = async () => {
      setIsLoading(true);
      setCondPgto((await condPgtoOptions()) as IDropDown[]);
      setIsLoading(false);
    };
    condPgto();

    const modal = () => {
      setIsLoading(true);
      if (click && modalItem) {
        setModalData(modalItem);
        setOpen(true);
      }
      if (create) {
        setModalData({} as ICnpj);
        setOpen(true);
      }
      setIsLoading(false);
    };
    modal();
  }, [setClick, create, modalItem, click]);

  const handleChange = (data: InputOnChangeData) => {
    if (data.name && modalData) {
      if (
        data.name === 'multiplicador_preco' ||
        data.name === 'cliente_fatmin'
      ) {
        setModalData({ ...modalData, [data?.name]: parseInt(data.value) });
      } else {
        setModalData({ ...modalData, [data?.name]: data.value });
      }
    }
  };

  const handleDeleteCnpj = async (id: number) => {
    setIsLoadingButton(true);
    try {
      await api.delete(`cnpj/${id}`);
      setSecondOpen(true);
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoadingButton(false);
  };

  const handleConfirmationButton = () => {
    setOpen(false);
    setClick(false);
    setCreate(false);
    setModalData({} as ICnpj);
    setSecondOpen(false);
    mutate();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const data: ICnpj = {
      ...modalData,
      multiplicador_preco: parseFloat(modalData.multiplicador_preco as any),
      cliente_frete: clienteFrete || modalData.cliente_frete,
      cliente_condpagto: clienteCondPgto || modalData.cliente_condpagto
    };
    const { validForm, errors } = validateCnpjModal(data);
    if (validForm && modalData) {
      try {
        if (create) {
          await api.post('cnpj', data, axiosHeaderJson);
        } else {
          await api.put(`cnpj/${modalData.id}`, data, axiosHeaderJson);
        }
        setSecondOpen(true);
      } catch (error) {
        setErrors(error.response);
      }
    } else {
      setFormErrors(errors);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      onUnmount={() => {
        setOpen(false);
        setClick(false);
        setCreate(false);
        setSecondOpen(false);
        setModalData({} as ICnpj);
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmer="blurring"
      size="large"
    >
      <Modal.Header>
        {modalData && modalData.id
          ? `Item ${modalData.comprador_cnpj}`
          : 'Criar novo item'}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}
            loading={isLoading}
          >
            <Grid verticalAlign="top">
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Form.Input
                    required
                    label="Comprador CNPJ"
                    name="comprador_cnpj"
                    error={formErrors?.comprador_cnpj}
                    value={modalData?.comprador_cnpj}
                    onChange={(e, data: InputOnChangeData) =>
                      handleChange(data)
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Multiplicador Preço</label>
                    <Input
                      required
                      name="multiplicador_preco"
                      onChange={(e, { value }) => {
                        if (!numPattern.test(value)) {
                          setNumPatternError(
                            'Tecla não é válida, apenas números e um ponto para separar'
                          );
                        } else {
                          setNumPatternError(undefined);
                          setModalData({
                            ...modalData,
                            multiplicador_preco: value as any
                          });
                        }
                      }}
                    >
                      <input
                        name="multiplicador_preco"
                        value={modalData.multiplicador_preco}
                      />
                    </Input>
                    {numPatternError && (
                      <Label basic color="red" pointing="above">
                        {numPatternError}
                      </Label>
                    )}
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <label>Ativo</label>
                  <Dropdown
                    fluid
                    selection
                    options={[
                      {
                        key: 'S',
                        value: 'S',
                        text: 'SIM'
                      },
                      {
                        key: 'N',
                        value: 'N',
                        text: 'NÃO'
                      }
                    ]}
                    name="enabled"
                    value={modalData?.enabled}
                    onChange={(e, data: DropdownProps) => {
                      if (data && modalData) {
                        setModalData({
                          ...modalData,
                          enabled: data.value
                        });
                      }
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Form.Input
                    required
                    type="number"
                    label="Cliente Faturamento Mínimo"
                    name="cliente_fatmin"
                    error={formErrors?.cliente_fatmin}
                    value={modalData?.cliente_fatmin}
                    onChange={(e, data: InputOnChangeData) =>
                      handleChange(data)
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <label>Cliente Frete</label>
                  <Dropdown
                    placeholder="Cliente Frete"
                    fluid
                    selection
                    loading={isLoading}
                    options={freteOptions}
                    name="cliente_frete"
                    value={modalData.cliente_frete}
                    onChange={(e, data: DropdownProps) => {
                      if (data) {
                        setClienteFrete(data.value);
                      }
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label>Cliente Condição de Pagamento</label>
                  <Dropdown
                    required
                    placeholder="Cliente Condição de Pagamento"
                    fluid
                    search
                    selection
                    options={condPgto}
                    loading={isLoading}
                    name="cliente_condpagto"
                    value={modalData.cliente_condpagto}
                    onChange={(e, data: DropdownProps) => {
                      if (data) {
                        setClienteCondPgto(data.value);
                      }
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Button color="green" floated="right" style={{ marginTop: '35px' }}>
              Enviar
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="grey"
          onClick={() => {
            setOpen(false);
            setClick(false);
            setCreate(false);
            setModalData({} as ICnpj);
          }}
        >
          Fechar
        </Button>
        {modalData && modalData.id ? (
          <Button
            color="red"
            loading={isLoadingButton}
            onClick={() => handleDeleteCnpj(modalData.id)}
          >
            Excluir
          </Button>
        ) : (
          ''
        )}
      </Modal.Actions>
      <ConfirmSecondModal
        confirmation={secondOpen}
        setConfirmation={handleConfirmationButton}
        errors={errors}
        customMsg="Operação realizada com sucesso"
      />
    </Modal>
  );
};

export default CnpjModal;
