import moment from 'moment';

const dateTimeConverter = (date: Date | undefined, format: string): string => {
  if (date === null) {
    return '';
  }
  return moment(date).format(format);
};

export default dateTimeConverter;
