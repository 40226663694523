import React, { useEffect, useState } from 'react';
import { Statistic } from 'semantic-ui-react';
import infosCalculation, { Infos } from '../../../utils/helpers/activityCalcs';
import '../../../assets/css/activityInfos.css';
import { IActivity } from '../_interfaces/activity.interfaces';
import formatNumbers from '../../../utils/helpers/formatNumbers';

interface Props {
  countQuoted: number;
  totalValueQuoted: number;
  units: number;
  average: number;
}

const ActivityInfos: React.FC<Props> = ({
  countQuoted,
  totalValueQuoted,
  units,
  average
}) => {
  const marginLeft = '40px';

  return (
    <div>
      {/* <Grid.Column>
        <Message
          className="infos-message"
          info
          header="Total cotados"
          content={infos ? infos.countQuoted : 0}
        />
      </Grid.Column>
      <Grid.Column>
        <Message
          className="infos-message"
          positive
          header="Valor cotado"
          content={infos ? infos.totalValueQuoted : 0}
        />
      </Grid.Column>
      <Grid.Column>
        <Message
          className="infos-message"
          info
          header="Unidades cotadas"
          content={infos ? infos.units : 0}
        />
      </Grid.Column>
      <Grid.Column>
        <Message
          className="infos-message"
          positive
          header="Média por cotação"
          content={infos ? infos.average : 0}
        />
      </Grid.Column> */}

      <Statistic.Group
        size="tiny"
        style={{ justifyContent: 'center', textAlign: 'center' }}
        widths={16}
      >
        <Statistic color="blue">
          <Statistic.Value>{countQuoted}</Statistic.Value>
          <Statistic.Label>Total cotados</Statistic.Label>
        </Statistic>

        <Statistic color="teal" style={{ marginLeft }}>
          <Statistic.Value>
            {formatNumbers(totalValueQuoted, 'currency')}
          </Statistic.Value>
          <Statistic.Label>Valor cotado</Statistic.Label>
        </Statistic>

        <Statistic color="blue" style={{ marginLeft }}>
          <Statistic.Value>{units}</Statistic.Value>
          <Statistic.Label>Unidades cotadas</Statistic.Label>
        </Statistic>

        <Statistic color="teal" style={{ marginLeft }}>
          <Statistic.Value>
            {formatNumbers(average, 'currency')}
          </Statistic.Value>
          <Statistic.Label>Média por cotação</Statistic.Label>
        </Statistic>
      </Statistic.Group>
    </div>
  );
};

export default ActivityInfos;
