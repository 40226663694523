/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Divider, Grid, Header, Icon, Modal } from 'semantic-ui-react';
import api from '../../utils/helpers/axios.instance';
import cnpjFormatter from '../../utils/helpers/cnpjFormatter';
import Loading from '../Commom/Loading';
import dateTimeConverter from '../../utils/helpers/dateTimeConverter';
import AppContext from '../../contexts/AppContext';
import { IActivity } from './_interfaces/activity.interfaces';
import { IIssue } from '../Issues/_interfaces/issues.interface';
import ConfirmSecondModal from '../Commom/ConfirmSecondModal';

interface Props {
  modalItem: IActivity;
  setClick: (value: boolean) => void;
}

const ActivityModal: React.FC<Props> = ({ modalItem, setClick }) => {
  const { userData } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [errors, setErrors] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    const getItem = async () => {
      setIsLoading(true);
      setIsButtonLoading(true);
      setOpen(true);
      if (modalItem.id_atividade) {
        const { id_atividade } = modalItem;
        const issuesList = await api.get(`/issue/get`);
        const filterDisabled = issuesList.data.find(
          (i: IIssue) =>
            i.activity_id === id_atividade && i.user_id === userData.data.id
        );
        if (filterDisabled) setDisabled(true);
      }
      setIsButtonLoading(false);
      setIsLoading(false);
    };
    getItem();
  }, [modalItem.id_atividade]);

  const handleReport = async (e: React.MouseEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const { company_id, id } = userData.data;
    const report = {
      company_id,
      activity_id: modalItem.id_atividade,
      user_id: id,
      verified: false
    };
    try {
      await api.post('/issue', report);
      setSecondOpen(true);
      setDisabled(true);
    } catch (err) {
      setErrors(err.response);
    }
    setIsLoading(false);
  };

  const handleConfirmationButton = () => {
    setSecondOpen(false);
  };

  const handleDisabled = (value: boolean) => {
    setDisabled(value);
  };

  return !modalItem ? (
    <Loading />
  ) : (
    <Modal
      onUnmount={() => {
        setOpen(false);
        setClick(false);
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmer="blurring"
    >
      <>
        <Modal.Header>{`${modalItem.external_id}`}</Modal.Header>
        {isLoading ? <Loading /> : ''}
        <Modal.Content>
          <Modal.Description>
            <Grid verticalAlign="top">
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header>Nome do comprador</Header>
                  <p>{modalItem.comprador_nome.split('(')[0]}</p>
                </Grid.Column>
                <Grid.Column>
                  <Header>CNPJ do comprador</Header>
                  <p>{cnpjFormatter(modalItem.comprador_cnpj)}</p>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Header>Item do comprador</Header>
                  <p>{modalItem.comprador_item}</p>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Header>Item do cliente</Header>
                  <p>{modalItem.cliente_item}</p>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header>Cidade / Estado</Header>
                  <p>{`${modalItem.comprador_cidade} / ${modalItem.comprador_estado}`}</p>
                </Grid.Column>
                <Grid.Column>
                  <Header>Status</Header>
                  <Icon
                    name={
                      modalItem.status_cotacao.startsWith('COTADO')
                        ? 'check circle'
                        : 'dont'
                    }
                    color={
                      modalItem.status_cotacao.startsWith('COTADO')
                        ? 'green'
                        : 'red'
                    }
                    size="large"
                  />
                  &nbsp;
                  <b>
                    {modalItem.status_cotacao === '0'
                      ? 'Não Cotado'
                      : modalItem.status_cotacao}
                  </b>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row columns={3}>
                <Grid.Column verticalAlign="middle">
                  <Header>Data de abertura</Header>
                  <p>
                    {dateTimeConverter(
                      modalItem.data_abertura,
                      'DD/MM/YY - HH:mm:ss'
                    )}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <Header>Data de expiração</Header>
                  <p>
                    {dateTimeConverter(
                      modalItem.data_expiracao,
                      'DD/MM/YY - HH:mm:ss'
                    )}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <Header>Cotado Em</Header>
                  <p>
                    {dateTimeConverter(
                      modalItem.cotadoEm,
                      'DD/MM/YY - hh:mm:ss'
                    )}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row columns={5}>
                <Grid.Column>
                  <Header>Frete</Header>
                  <p>{modalItem.cliente_frete}</p>
                </Grid.Column>
                <Grid.Column verticalAlign="middle">
                  <Header>Unidade</Header>
                  <p>{modalItem.unidade}</p>
                </Grid.Column>
                <Grid.Column>
                  <Header>Quantidade</Header>
                  <p>{modalItem.quantidade}</p>
                </Grid.Column>
                <Grid.Column>
                  <Header>Preço</Header>
                  <p>{`R$ ${modalItem.cliente_preco}`}</p>
                </Grid.Column>

                <Grid.Column>
                  <Header>Total</Header>
                  <p>{`R$ ${modalItem.total}`}</p>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row columns={3}>
                <Grid.Column verticalAlign="middle">
                  <Header>Múltiplo</Header>
                  <p>{modalItem.cliente_multiplo}</p>
                </Grid.Column>
                <Grid.Column>
                  <Header>Faturamento mínimo</Header>
                  <p>{modalItem.cliente_fatmin}</p>
                </Grid.Column>
                <Grid.Column>
                  <Header>Condição de pagamento</Header>
                  <p>{modalItem.cliente_condpagto}</p>
                </Grid.Column>
              </Grid.Row>
              <Divider />
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={() => {
              setOpen(false);
              setClick(false);
            }}
          >
            Fechar
          </Button>
          {!disabled ? (
            <Button
              content="Reportar inconsistência"
              labelPosition="right"
              icon="warning sign"
              onClick={(e) => handleReport(e)}
              negative
            />
          ) : (
            <Button
              loading={isButtonLoading}
              content="Já foi reportado"
              labelPosition="right"
              icon="check"
              positive
              disabled={!!disabled}
            />
          )}
        </Modal.Actions>
        <ConfirmSecondModal
          confirmation={secondOpen}
          setConfirmation={handleConfirmationButton}
          errors={errors}
          avisos={true}
          setDisabled={handleDisabled}
          customMsg={
            <Fragment>
              A cotação deverá ser corrigida no Bionexo.
              <br />
              <br />A inconsistencia foi enviada para análise e correções
              necessárias pelo time B2BMax.
            </Fragment>
          }
        />
      </>
    </Modal>
  );
};

export default ActivityModal;
