import React from 'react';
import { Chart } from 'react-google-charts';
import Loading from '../../Commom/Loading';

interface Props {
  header: Array<string | number>;
  barChart: Array<string | number>;
}

const ReportBarChart: React.FC<Props> = ({ header, barChart }) => {
  return (
    <Chart
      width={'1500px'}
      height={'650px'}
      legendToggle={true}
      chartType="BarChart"
      loader={<Loading />}
      data={[header, ...barChart]}
      options={{
        title: 'Valores em R$',
        chartArea: { width: '50%' },
        isStacked: true,
        hAxis: {
          title: 'Potencial',
          minValue: 0
        },
        vAxis: {
          title: 'Item'
        }
      }}
    />
  );
};

export default ReportBarChart;
