import { useEffect, useState } from 'react';
import ReactBlockly from 'react-blockly';
import Blockly from 'blockly';
import BlocklyJS from 'blockly/javascript';
import * as Pt from 'blockly/msg/pt-br'
import BlocklyPY from 'blockly/python';
import '../../../utils/generator/generator'
import '../../Rules/Advanced/CustomBlocks/CustomBlocks';
import '../../Rules/Advanced/styles/blockly.css';
import { Button, Grid, Segment } from 'semantic-ui-react';
import api, { axiosHeaderJson } from '../../../utils/helpers/axios.instance';
import toolboxCategories from '../../Rules/Advanced/CustomBlocks/blockly-list';
import Loading from '../../Commom/Loading';
Blockly.setLocale(Pt)
/* Fluxo de criação/funcionamento:
  1 => Criar o bloco dentro de /Rules/Advanced/CustomBlocks/CustomBlocks.js
    1.1 => Utilizar a paleta de cores dentro do arquivo blockly-list.js ou criar uma nova caso
            tenha uma nova categoria

  2 => Criar a função de como será retornado o código e em qual linguagem dentro de generator.js
  3 => Adicionar em uma categoria dentro de /Rules/Advanced/CustomBlocks/blockly-list.js
  4 => Converter o código para a linguagem escolhida:
    4.1 => É necessário alterar dentro de "generator.js" linguagem
    4.2 => Após isso, importar a linguagem aqui e trocar na função que gera o código
           import BlocklyPY from 'blockly/python';

  5 => Qualquer alteração no workspace será atualizada ao mesmo tempo o componente
  6 => O XML fica salvo no localStorage, qualquer atualização irá remover o que foi
       feito e não salvo
  7 => Criar um hook para puxar o xml salvo no banco e recuperar o último estado salvo
  8 => Não há suporte para Typescript, então esse arquivo será mantido como .js
*/

function Advanced () {
  const [clientXML, setClientXML] = useState('');
  const [advancedData, setAdvancedData] = useState('')
  const [clientCode, setClientCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [savingError, setSavingError] = useState(false);
  const [jsCode, setJSCode] = useState('')

  const checkVariables = () => {
    Blockly.JavaScript.INFINITE_LOOP_TRAP = 'checkTimeout();\n';
    let timeouts = 0;
    const checkTimeout = function() {
      if (timeouts++ > 1000000) {
        throw MSG['timeout'];
      }
    };

    Blockly.JavaScript.INFINITE_LOOP_TRAP = null;
    if(jsCode !== '') {
      try {
        eval(jsCode);
      } catch (e) {
        alert(e);
      }
    }
  }

  const saveChanges = async (e) => {
    e.preventDefault()
    setIsSaving(true)
    try {
      const { id } = advancedData;
      const data = {
        id,
        xml: clientXML,
        code: clientCode
      }
      await api.put('avancada', data, axiosHeaderJson);
    } catch (error) {
      setSavingError(error.response)
    }
    setIsSaving(false)
  }

  useEffect(() => {
    const getInitialXML = async () => {
      setIsLoading(true)
      try {
        const { data } = await api.get('avancada/get');
        setAdvancedData(data)
        setClientXML(data.xml)
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false)
    };
    getInitialXML()
  }, []);

  async function workspaceDidChange(workspace) {
    const newXml = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
    const code = BlocklyPY.workspaceToCode(workspace);
    const js = BlocklyJS.workspaceToCode(workspace);
    setJSCode(js)
    document.getElementById('code').value = code;
    setClientXML(newXml)
    setClientCode(code)
  }

  return (
    <Segment>
      <Grid>
        {isLoading || clientXML === '' ? <Loading /> :
        (<Grid.Row>
          <Grid.Column>
            <ReactBlockly
              toolboxCategories={toolboxCategories}
              initialXml={clientXML}
              wrapperDivClassName="fill-height"
              workspaceConfiguration={{
                grid: {
                  spacing: 20,
                  length: 3,
                  colour: '#ccc',
                  snap: true
                }
              }}
              workspaceDidChange={workspaceDidChange}
              />
          </Grid.Column>
        </Grid.Row>
        )}
      </Grid>
      <br/>
      <Segment basic padded stacked>
        <Grid verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={6}>
                <label><strong>ESTRUTURA MATEMÁTICA – REGRA AVANÇADA</strong></label>
                <textarea readOnly id="code" style={{ height: "200px", width: "400px" }} value="" />
            </Grid.Column>
            <Grid.Column width={6}>
              <Button
                color="green"
                onClick={e => saveChanges(e)}
                content="Salvar alterações"
                icon="save"
                labelPosition="right"
                loading={isSaving}
              />
              <Button
                onClick={checkVariables}
                content="Testar código"
                />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment>
  );
};

export default Advanced;
