/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import formatReportChart from '../../utils/helpers/formatReportChart';
import mapsGroup from '../../utils/helpers/mapsGroup';
import AppContext from '../../contexts/AppContext';

import {
  Button,
  Dropdown,
  DropdownProps,
  Grid,
  Header,
  Popup,
  Segment
} from 'semantic-ui-react';
import ReportTable from '../Reports/Table/ReportTable';
import ReportPieChart from '../Reports/Charts/ReportPieChart';
import ReportBarChart from '../Reports/Charts/ReportBarChart';
import { useFetch } from '../../hooks/useFetch';
import ReportDatepicker from '../Reports/ReportDatepicker';
import AxiosErrors from '../Errors/AxiosErrors';
import { IError } from '../Commom/_interfaces/error.interface';
import { IDropDown } from '../Commom/_interfaces/dropdown.interface';
import {
  IDateInterval,
  IMapPoints
} from '../Reports/_interfaces/reports.interfaces';
import baseColors from '../../assets/colors';
import NoData from '../Commom/NoData';
import ReportStatistics from '../Reports/ReportStatistics';
import ReportMap from '../Reports/Map/ReportMap';
import Iframe from '../Commom/IFrame';

interface KeyValues {
  key: string;
  value: number;
}

export interface IReports {
  Key: string;
  Value: {
    name: string;
    data_abertura: Date;
    totalEstado: KeyValues[];
    quantidadeEstado: KeyValues[];
  };
}

const Reports: React.FC = () => {
  const [header, setHeader] = useState<string[]>([]);
  const [select, setSelect] = useState<any>('');
  const [loadingInterval, setLoadingInterval] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [barChart, setBarChart] = useState<Array<string | number>>([]);
  const [pieArray, setSetPieArray] = useState<Array<string | number>>([]);
  const [amountTop8, setAmountTop8] = useState<Array<string | number>>([]);
  const [namesArray, setNamesArray] = useState<IDropDown[]>([]);
  const [valueTop8, setValueTop8] = useState<Array<string | number>>([]);
  const [mapPoints, setMapPoints] = useState<IMapPoints[]>([]);
  const [dates, setDates] = useState<Date | Date[] | null | undefined>([]);
  const [itemsErrors, setItemsErrors] = useState<IError | boolean | null>(
    false
  );
  const { userHasAuthenticated, userData, isAuthenticated } = useContext(
    AppContext
  );
  const [citiesErrors, setCitiesErrors] = useState<IError | boolean | null>(
    false
  );
  const [dateInterval, setDateInterval] = useState<IDateInterval | null>(null);
  const [value, setValue] = useState<any>('');
  const config = {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false
  };

  const handleDateChange = (date: any) => {
    setDates(date);
  };

  const handleLoadingButton = () => {
    setLoadingButton(true);
    setTimeout(() => {
      setLoadingButton(false);
    }, 2000);
  };

  const { data, error } = useFetch<IReports[]>(
    `report/get${
      dateInterval
        ? `?startDate=${dateInterval?.startDate}&endDate=${dateInterval?.endDate}`
        : ''
    }`,
    config
  );

  const { data: dataCities, error: errorCities } = useFetch<IMapPoints[]>(
    'municipios/get',
    config
  );

  const handleDropdown = (data: DropdownProps) => {
    handleLoadingButton();
    if (data.value) {
      setSelect(data.value);
      setValue(data.value);
    }
  };

  const handleDateInterval = (dateInterval: {
    startDate: string;
    endDate: string;
  }) => {
    setDateInterval(dateInterval);
    setLoadingInterval(true);
  };

  useEffect(() => {
    if (!data) {
      setLoadingInterval(true);
    } else {
      setLoadingInterval(false);
    }

    const getReports = async () => {
      if (data && !select) {
        const { finalArray: barChart, header, top8: value } = formatReportChart(
          data,
          'totalEstado'
        );
        const { finalArray: pieChart, names, top8: amount } = formatReportChart(
          data,
          'quantidadeEstado'
        );
        setBarChart(barChart);
        setHeader(header);
        setNamesArray(names);
        setSetPieArray(pieChart);
        setValueTop8(value);
        setAmountTop8(amount);
      } else {
        if (typeof select === 'string') {
          const filter = [
            data?.find((el) => el.Value[0].name.substring(0, 55) === select)
          ];
          if (filter[0] !== undefined) {
            const { finalArray: barChart, header } = formatReportChart(
              filter as IReports[],
              'totalEstado'
            );
            const { finalArray: pieChart } = formatReportChart(
              filter as IReports[],
              'quantidadeEstado'
            );
            setSetPieArray(pieChart);
            setHeader(header);
            setBarChart(barChart);
          }
        }
      }

      if (dataCities) {
        const group = mapsGroup(dataCities);
        setMapPoints(group);
      }

      if (errorCities) {
        setCitiesErrors(errorCities);
      }

      if (error) {
        setItemsErrors(error);
      }
    };
    getReports();

    if (
      select &&
      dateInterval?.startDate &&
      dateInterval.endDate &&
      data?.length === 0
    ) {
      setBarChart([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, dataCities, errorCities, select]);
  const Data: any = userData;
  const returnURL = () => {
    const url: string = Data.data.empresa.report_link;
    console.log(url);
    return url;
  };
  returnURL();
  return (
    <>
      <Grid>
        <Grid.Row style={{ textAlignLast: 'center', placeContent: 'center' }}>
          <Grid.Column>
            <Iframe source={returnURL()} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {(returnURL() === null || undefined || '') && (
        <>
          {itemsErrors && <AxiosErrors error={itemsErrors} />}
          <Segment basic padded stacked data-component="reports">
            <Grid>
              <Grid.Row
                style={{ textAlignLast: 'center', placeContent: 'center' }}
              >
                <Grid.Column largeScreen={6} tablet={7} mobile={16}>
                  <ReportDatepicker
                    setDateInterval={handleDateInterval}
                    setDates={handleDateChange}
                    dates={dates}
                    loadingButton={loadingButton}
                    handleLoadingButton={handleLoadingButton}
                  />
                </Grid.Column>
                <Grid.Column largeScreen={6} tablet={7} mobile={16}>
                  <Dropdown
                    loading={loadingInterval}
                    value={value}
                    options={namesArray}
                    noResultsMessage="Não foi encontrado nenhum registro"
                    search
                    selection
                    onChange={(e, data: DropdownProps) => handleDropdown(data)}
                  />
                  <br />
                  <Button
                    content="Mostrar todos"
                    loading={loadingButton}
                    onClick={(e) => {
                      setSelect('');
                      setValue('');
                    }}
                    style={{
                      marginTop: 20,
                      background: baseColors.blue,
                      color: 'white'
                    }}
                  />
                </Grid.Column>
                <Grid.Column largeScreen={4} tablet={2} mobile={16}>
                  <Popup
                    content="Limpar pesquisa e datas"
                    trigger={
                      <Button
                        icon="close"
                        loading={loadingButton}
                        onClick={() => {
                          setDateInterval(null);
                          setSelect('');
                          setValue('');
                          setDates(null);
                          handleLoadingButton();
                        }}
                        style={{ marginTop: 20 }}
                        negative
                      />
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <ReportStatistics value={barChart} amount={pieArray} />
          <Segment basic padded>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Header
                    content="Potencial de vendas por Produto/UF"
                    style={{
                      color: baseColors.blue,
                      textTransform: 'uppercase'
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          {loadingInterval ? (
            <NoData
              customMainMsg="Buscando as informações"
              customSubMsg="Estamos buscando as novas informações e gerando novos gráficos, aguarde um momento"
            />
          ) : (
            <>
              <div style={{ overflowX: 'scroll' }}>
                <Segment textAlign="center" basic padded>
                  {!loadingInterval && barChart.length === 0 ? (
                    <NoData
                      style={{ color: 'red' }}
                      loading={false}
                      iconName="warning sign"
                      customMainMsg="Não há resultados"
                      customSubMsg="Não foi encontrado nenhum dado para essas datas"
                    />
                  ) : (
                    <ReportBarChart header={header} barChart={barChart} />
                  )}
                </Segment>
              </div>
              {barChart.length === 0 ? (
                <></>
              ) : (
                <div
                  style={{
                    overflowX: 'scroll',
                    textAlign: 'center',
                    justifyItems: 'center'
                  }}
                >
                  <Segment padded basic>
                    <ReportPieChart
                      select={select as string}
                      pieData={pieArray}
                      pieHeader={header}
                      namesArray={namesArray}
                      amountTop8={amountTop8}
                      valueTop8={valueTop8}
                      valueArray={barChart}
                    />
                  </Segment>
                </div>
              )}
            </>
          )}
          <Segment style={{ border: 'none', boxShadow: 'none' }}>
            <ReportMap mapPoints={mapPoints} citiesErrors={citiesErrors} />
            <ReportTable mapPoints={mapPoints} />
          </Segment>
        </>
      )}
    </>
  );
};

export default Reports;
