import React from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';

const NotFound: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  };

  return (
    <Segment
      padded="very"
      size="massive"
      textAlign="center"
      style={{ height: '50vh' }}
    >
      <Header icon>
        <Icon name="warning sign" size="huge" color="red" />
      </Header>
      <Segment.Inline>
        <Header size="large">
          404 Página não encontrada
          <Header.Subheader>
            Talvez a página que você tentou acessar não exista ou está
            indisponível no momento, por favor, tente mais tarde!
          </Header.Subheader>
        </Header>
        <Button animated onClick={handleClick} primary>
          <Button.Content visible>Voltar</Button.Content>
          <Button.Content hidden>
            <Icon name="arrow left" />
          </Button.Content>
        </Button>
        <Button animated as={NavLink} exact to="/user/relatorios" primary>
          <Button.Content visible>Relatórios</Button.Content>
          <Button.Content hidden>
            <Icon name="home" />
          </Button.Content>
        </Button>
      </Segment.Inline>
    </Segment>
  );
};

export default NotFound;
