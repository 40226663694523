import React, { useState } from 'react';
import { Search } from 'semantic-ui-react';
import { ICnpj } from '../_interfaces/cnpj.interfaces';

interface Props {
  tableData: ICnpj[];
  setIsSearching: (value: boolean) => void;
  handleSearchResult: (searchResult: ICnpj[]) => void;
}

const CnpjSearch: React.FC<Props> = ({
  tableData,
  setIsSearching,
  handleSearchResult
}) => {
  const [searchResult, setSearchResult] = useState<ICnpj[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const handleSearchChange = (value: string | undefined) => {
    setIsLoading(true);
    if (value === '') {
      setSearch('');
      setIsSearching(false);
      handleSearchResult([]);
      setSearchResult([]);
    } else if (value !== undefined) {
      setSearch(value);
      setSearchResult(
        tableData.filter((data) =>
          String(data.comprador_cnpj).includes(String(value))
        )
      );
      setIsSearching(true);
      if (searchResult.length === 0) {
        handleSearchResult([]);
      } else {
        handleSearchResult(searchResult);
      }
    } else {
      setSearch('');
      setIsSearching(false);
      handleSearchResult([]);
      setSearchResult([]);
    }
    setIsLoading(false);
  };

  return (
    <Search
      onSearchChange={(e, data) => handleSearchChange(data.value)}
      showNoResults={false}
      loading={isLoading}
      value={search}
    />
  );
};

export default CnpjSearch;
