import { IDropDown } from '../../components/Commom/_interfaces/dropdown.interface';
import { ICondPgto } from '../../components/Rules/_interfaces/condPgto.interface';
import api from './axios.instance';

export const condPgtoOptions = async (): Promise<IDropDown[] | void> => {
  let result: IDropDown;
  try {
    const { data } = await api.get('condpagto/get');
    if (data.length > 0) {
      const aux: IDropDown[] = [];
      if (data.length > 0) {
        data.map((d: ICondPgto) => {
          result = {
            key: d.id,
            value: d.name,
            text: d.name
          };
          aux.push(result);
          return aux;
        });
      }
      return aux;
    }
  } catch (error) {
    console.log(error);
  }
};
