/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownProps,
  Form,
  Grid,
  Input,
  InputOnChangeData,
  Label,
  Modal
} from 'semantic-ui-react';
import api, { axiosHeaderJson } from '../../../utils/helpers/axios.instance';
import { validateGeoModal } from '../../../utils/helpers/validateGeoModal';
import { IGeo } from '../_interfaces/geo.interfaces';
import {
  IError,
  IGeoFormErrors
} from '../../Commom/_interfaces/error.interface';
import { IDropDown } from '../../Commom/_interfaces/dropdown.interface';
import ConfirmSecondModal from '../../Commom/ConfirmSecondModal';
import { condPgtoOptions } from '../../../utils/helpers/condPgtoDropdown';
import { geoStatesDropdown } from '../../../utils/helpers/geoStatesDropdown';

export const freteOptions: IDropDown[] = [
  {
    key: 'CIF',
    value: 'CIF',
    text: 'CIF'
  },
  {
    key: 'FOB',
    value: 'FOB',
    text: 'FOB'
  }
];

interface Props {
  modalItem: IGeo | null;
  click: boolean;
  create: boolean;
  takenStates: string[];
  setClick: (value: boolean) => void;
  setCreate: (value: boolean) => void;
  mutate: () => void;
}

const GeoModal: React.FC<Props> = ({
  modalItem,
  create,
  click,
  setClick,
  setCreate,
  mutate
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [modalData, setModalData] = useState<IGeo>({} as IGeo);
  const [formErrors, setFormErrors] = useState<IGeoFormErrors | null>(null);
  const [clienteCondPgto, setClienteCondPgto] = useState<any>('');
  const [errors, setErrors] = useState<IError | boolean>(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [comprador_estado, setCompradorEstado] = useState<any>('');
  const [clienteFrete, setClienteFrete] = useState<any>('');
  const [condPgto, setCondPgto] = useState<IDropDown[]>([]);
  const [freeStates, setFreeStates] = useState<IDropDown[]>([]);
  const numPattern = /^\d*\.?\d*$/;
  const [numPatternError, setNumPatternError] = useState<undefined | string>(
    undefined
  );

  const handleChange = (data: InputOnChangeData) => {
    if (data.name && modalData) {
      if (
        data.name === 'multiplicador_preco' ||
        data.name === 'cliente_fatmin'
      ) {
        setModalData({ ...modalData, [data?.name]: parseInt(data.value) });
      } else {
        setModalData({ ...modalData, [data?.name]: data.value });
      }
    }
  };

  const handleDeleteCnpj = async (id: number) => {
    setIsLoadingButton(true);
    try {
      await api.delete(`geo/${id}`);
      setSecondOpen(true);
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoadingButton(false);
  };

  useEffect(() => {
    const condPgto = async () => {
      setIsLoading(true);
      setCondPgto((await condPgtoOptions()) as IDropDown[]);
      setIsLoading(false);
    };
    condPgto();

    const states = async () => {
      try {
        const { data } = await api.get('estados/get');
        if (data.length > 0) {
          setFreeStates(geoStatesDropdown(data));
        }
      } catch (error) {
        console.log(error);
      }
    };
    states();

    const modal = () => {
      setIsLoading(true);
      if (click && modalItem) {
        setModalData(modalItem);
        setClienteFrete(modalItem.cliente_frete);
        setClienteCondPgto(modalItem.cliente_condpagto);

        setOpen(true);
        setCompradorEstado(modalItem.comprador_estado);
      }
      if (create) {
        setModalData({} as IGeo);
        setOpen(true);
      }
    };
    modal();
  }, [setClick, create, modalItem, click]);

  const handleConfirmationButton = () => {
    setOpen(false);
    setClick(false);
    setCreate(false);
    setModalData({} as IGeo);
    setSecondOpen(false);
    mutate();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const data: IGeo = {
      ...modalData,
      multiplicador_preco: parseFloat(modalData.multiplicador_preco as any),
      comprador_estado,
      cliente_frete: clienteFrete || modalData.cliente_frete,
      cliente_condpagto: clienteCondPgto || modalData.cliente_condpagto
    };
    const { validForm, errors } = validateGeoModal(data);
    if (validForm) {
      try {
        if (create) {
          await api.post('geo', data, axiosHeaderJson);
        } else {
          await api.put(`geo/${data.id}`, data, axiosHeaderJson);
        }
        setSecondOpen(true);
      } catch (error) {
        setErrors(error.response);
      }
    } else {
      setFormErrors(errors);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      onUnmount={() => {
        setOpen(false);
        setClick(false);
        setCreate(false);
        setSecondOpen(false);
        setModalData({} as IGeo);
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmer="blurring"
      size="large"
    >
      <>
        <Modal.Header>
          {modalData && modalData.id
            ? `${modalData.comprador_estado}`
            : 'Criar novo item'}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
                handleSubmit(e)
              }
              loading={isLoading}
            >
              <Grid verticalAlign="top">
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Field>
                      <label>Multiplicador Preço</label>
                      <Input
                        required
                        name="multiplicador_preco"
                        onChange={(e, { value }) => {
                          if (!numPattern.test(value)) {
                            setNumPatternError(
                              'Tecla não é válida, apenas números e um ponto para separar'
                            );
                          } else {
                            setNumPatternError(undefined);
                            setModalData({
                              ...modalData,
                              multiplicador_preco: value as any
                            });
                          }
                        }}
                      >
                        <input
                          name="multiplicador_preco"
                          value={modalData.multiplicador_preco}
                        />
                      </Input>
                      {numPatternError && (
                        <Label basic color="red" pointing="above">
                          {numPatternError}
                        </Label>
                      )}
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <label>Ativo</label>
                    <Dropdown
                      fluid
                      selection
                      options={[
                        {
                          key: 'S',
                          value: 'S',
                          text: 'SIM'
                        },
                        {
                          key: 'N',
                          value: 'N',
                          text: 'NÃO'
                        }
                      ]}
                      name="enabled"
                      value={modalData.enabled}
                      onChange={(e, data: DropdownProps) => {
                        if (data && modalData) {
                          setModalData({
                            ...modalData,
                            enabled: data.value
                          });
                        }
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Form.Input
                      required
                      type="number"
                      label="Cliente Faturamento Mínimo"
                      name="cliente_fatmin"
                      value={modalData.cliente_fatmin}
                      error={formErrors?.cliente_fatmin}
                      onChange={(e, data: InputOnChangeData) =>
                        handleChange(data)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <label>Cliente Frete</label>
                    <Dropdown
                      placeholder="Cliente Frete"
                      fluid
                      selection
                      options={freteOptions}
                      name="cliente_frete"
                      value={clienteFrete}
                      onChange={(e, data: DropdownProps) => {
                        if (data) {
                          setClienteFrete(data.value);
                        }
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <label>Cliente Condição de Pagamento</label>
                    <Dropdown
                      required
                      placeholder="Cliente Condição de Pagamento"
                      fluid
                      search
                      selection
                      options={condPgto}
                      loading={isLoading}
                      name="cliente_condpagto"
                      value={clienteCondPgto}
                      onChange={(e, data: DropdownProps) => {
                        if (data) {
                          setClienteCondPgto(data.value);
                        }
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Button
                color="green"
                floated="right"
                style={{ marginTop: '35px' }}
              >
                Enviar
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="grey"
            onClick={() => {
              setOpen(false);
              setClick(false);
              setCreate(false);
              setModalData({} as IGeo);
            }}
          >
            Fechar
          </Button>
          {/* modalData && modalData.id ? (
            <Button
              color="red"
              loading={isLoadingButton}
              onClick={() => handleDeleteCnpj(modalData.id)}
            >
              Excluir
            </Button>
          ) : (
            ''
          ) */}
        </Modal.Actions>
        <ConfirmSecondModal
          confirmation={secondOpen}
          setConfirmation={handleConfirmationButton}
          errors={errors}
          customMsg="Operação realizada com sucesso"
        />
      </>
    </Modal>
  );
};

export default GeoModal;
