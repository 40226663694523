import { ICnpjFormErrors } from '../../components/Commom/_interfaces/error.interface';
import { ICnpj } from '../../components/Rules/_interfaces/cnpj.interfaces';

export function validateCnpjModal(
  modal: ICnpj
): { errors: ICnpjFormErrors | null; validForm: boolean } {
  const errors: ICnpjFormErrors = {} as ICnpjFormErrors;
  let validForm = true;
  const numPattern = /^[0-9]*$/;

  if (
    !modal.comprador_cnpj ||
    modal.comprador_cnpj.length !== 14 ||
    !numPattern.test(modal.comprador_cnpj)
  ) {
    errors.comprador_cnpj = 'É necessário inserir um CNPJ válido';
    validForm = false;
  }

  if (!modal.multiplicador_preco) {
    errors.multiplicador_preco = 'É necessário inserir um preço';
    validForm = false;
  }

  if (!modal.cliente_fatmin) {
    errors.cliente_fatmin = 'É necessário inserir um faturamento mínimo';
    validForm = false;
  }

  if (!modal.cliente_condpagto) {
    errors.cliente_condpagto = 'É necessário inserir uma condição de pagamento';
    validForm = false;
  }

  if (!modal.enabled) {
    errors.enabled = 'É necessário escolher uma opção';
    validForm = false;
  }

  if (!modal.cliente_frete) {
    errors.cliente_frete = 'É necessário inserir um frete';
    validForm = false;
  }

  return { errors, validForm };
}
