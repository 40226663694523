const dev = {
  cognito: {
    region: process.env.REACT_APP_COGNITO_REGION_DEV,
    userPoolId: process.env.REACT_APP_COGNITO_REGION_POOL_ID_DEV,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_REGION_CLIENT_ID_DEV
  },
  database: process.env.REACT_APP_BACKEND_DEV
  // database: 'https://localhost:44319'
  // database: process.env.REACT_APP_BACKEND_PROD
};

const prod = {
  cognito: {
    region: process.env.REACT_APP_COGNITO_REGION_PROD,
    userPoolId: process.env.REACT_APP_COGNITO_REGION_POOL_ID_PROD,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_REGION_CLIENT_ID_PROD
  },
  database: process.env.REACT_APP_BACKEND_PROD
  // database: 'https://localhost:5001'
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default {
  ...config
};
