import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Progress, Segment } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';

const NotAuthorized: React.FC = () => {
  const history = useHistory();

  const [value, setValue] = useState(0);
  const maxValue = 7;

  useEffect(
    () => {
      setTimeout(() => {
        if (value === maxValue) {
          history.push('/user/relatorios');
        }
        setValue(value + 1);
      }, 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return (
    <Segment
      placeholder
      padded="very"
      size="massive"
      textAlign="center"
      style={{ height: '50vh' }}
    >
      <Header icon>
        <Icon name="ban" size="huge" color="red" />
      </Header>
      <Segment.Inline>
        <Header size="large">
          401 Não Autorizado
          <Header.Subheader>
            Você não tem permissão suficiente para acessar esta página! Você
            será redirecionado em alguns segundos...
          </Header.Subheader>
        </Header>
        <br />
        <Progress progress="value" error value={value} total={maxValue} />
        <Button animated as={NavLink} exact to="/user/relatorios" primary>
          <Button.Content visible>Página Inicial</Button.Content>
          <Button.Content hidden>
            <Icon name="home" />
          </Button.Content>
        </Button>
      </Segment.Inline>
    </Segment>
  );
};

export default NotAuthorized;
