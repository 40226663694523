import { IRegisterFormErrors } from '../../components/Commom/_interfaces/error.interface';
import { IRegisterForm } from '../../hooks/reducers/formRegisterReducer';

export const validateRegister = (
  form: IRegisterForm,
  cnpj: string,
  uf: (number | string | boolean)[],
  companyId: string | number | true | (string | number | boolean)[]
): { errors: IRegisterFormErrors | null; validForm: boolean } => {
  const errors: IRegisterFormErrors = {} as IRegisterFormErrors;
  let validForm = true;
  const numPattern = /^[0-9]*$/;
  const stringPattern = /[A-Za-z]/;

  if (cnpj) {
    const cnpjArray = cnpj.split(';');
    cnpjArray.forEach((e) => {
      if (!e || e.length !== 14 || !numPattern.test(e)) {
        errors.cnpj = `O(s) CNPJ(s) ${e} contém apenas números e 14 dígitos`;
        validForm = false;
      }
    });
  }

  if (!form.cognito_id) {
    errors.cognito_id = 'É necessário o ID do usuário no Cognito';
    validForm = false;
  }

  if (!companyId) {
    errors.company_id = 'É necessário escolher uma empresa';
    validForm = false;
  }

  if (!cnpj && !uf) {
    errors.radio = 'É necessário escolher um tipo de filtro';
    validForm = false;
  }

  if (form.role) {
    if (!stringPattern.test(form.role) || form.role.toLowerCase() !== 'admin') {
      errors.role = 'O valor inserido deve ser admin';
      validForm = false;
    }
  }

  return { validForm, errors };
};
