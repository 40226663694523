/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Header, Message, Segment } from 'semantic-ui-react';
import '../../../assets/css/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import MapMarkers from './MapMarkers';
import AxiosMessageErrors from '../../Errors/AxiosMessageErrors';
import { IError } from '../../Commom/_interfaces/error.interface';
import { IMapPoints } from '../_interfaces/reports.interfaces';
import baseColors from '../../../assets/colors';
import './styles.css';

interface Props {
  mapPoints: IMapPoints[];
  citiesErrors: IError | null | boolean;
}

const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'marker-cluster-custom',
    iconSize: L.point(40, 40, true)
  });
};

const ReportMap: React.FC<Props> = ({ mapPoints, citiesErrors }) => {
  return (
    <>
      {citiesErrors ? (
        <AxiosMessageErrors error={citiesErrors} />
      ) : (
        <Segment padded="very" data-component="report-map">
          <Header
            content="Distribuição Geográfica"
            style={{ color: baseColors.blue, textTransform: 'uppercase' }}
          />
          <MapContainer center={[-15.77938, -47.925739]} zoom={5}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {mapPoints.length === 0 ? (
              <Message content="Nenhum conteúdo foi encontrado. Aguarde alguns instantes ou recarregue a página" />
            ) : (
              // <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
              <MarkerClusterGroup>
                <MapMarkers mapPoints={mapPoints} />
              </MarkerClusterGroup>
            )}
          </MapContainer>
        </Segment>
      )}
    </>
  );
};

export default memo(ReportMap);
