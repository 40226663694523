import React, { useMemo, useState, memo } from 'react';
import { Form } from 'semantic-ui-react';
import { useModalData } from '../../../contexts/ItemsContext';

interface Props {
  stringValue: string;
  customKey?: string;
  create?: boolean;
  required?: boolean;
  customLabel: string;
  onChange?: () => void;
}

const FormItemRow: React.FC<Props> = ({
  customKey,
  create,
  stringValue,
  required,
  customLabel,
  onChange
}) => {
  const [stringState, setStringState] = useState('');
  const { setModalData } = useModalData();

  useMemo(() => {
    if (create !== true) {
      setStringState(stringValue);
      if (stringValue === null) {
        setStringState('');
        setModalData(customKey, stringState);
      }
    } else {
      setStringState(' ');
    }
  }, [create, stringValue]);

  return (
    <>
      <Form.Input
        required={required}
        label={customLabel}
        name={customKey}
        value={stringState}
        onChange={(e, { value }) => {
          setModalData(customKey, value);
          setStringState(value);
        }}
      />
    </>
  );
};

export default memo(FormItemRow);
