import React from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { IItem } from './_interfaces/item.interfaces';
// import SearchNotFound from '../../Errors/SearchNotFound'

interface Props {
  tableData: IItem[];
  page: number | string;
  limit: number;
  isSearching: boolean;
  totalItems: number;
  setTotalItemsActive: (totalItemsActive: number) => void;
  setTotalPages: (totalPages: number) => void;
  setModalItem: (modalItem: IItem) => void;
  setClick: (value: boolean) => void;
}

const ItemTableRows: React.FC<Props> = ({
  tableData,
  page,
  limit,
  isSearching,
  totalItems,
  setTotalItemsActive,
  setTotalPages,
  setModalItem,
  setClick
}): JSX.Element | null => {
  let result;
  let start = 0;
  if (typeof page === 'number') {
    start = page === 1 ? 0 : (page - 1) * limit;
  } else if (typeof page === 'string') {
    start = page === '1' ? 0 : (parseInt(page) - 1) * limit;
  }

  const handleClick = (item: IItem) => {
    setModalItem(item);
    setClick(true);
  };

  const createTableRows = (array: IItem[], slice: string) => {
    if (array.length === 0) {
      return null;
    }
    const table = array.map((arr) => (
      <Table.Row key={arr.id}>
        <Table.Cell>
          <Button icon="search" onClick={() => handleClick(arr)} />
        </Table.Cell>
        <Table.Cell>{arr.skU_CLIENTE}</Table.Cell>
        <Table.Cell>{arr.desC_CLIENTE}</Table.Cell>
        <Table.Cell>{arr.fabricante}</Table.Cell>
        <Table.Cell>
          {arr.enabled === 'S' ? (
            <Icon name="checkmark" color="green" />
          ) : (
            <Icon name="dont" color="red" />
          )}
        </Table.Cell>
      </Table.Row>
    ));
    setTotalItemsActive(isSearching ? array.length : tableData.length);
    if (slice === 'Y') {
      setTotalPages(
        Math.ceil(table.length / limit) - 1 !== 0
          ? Math.floor(table.length / limit) + 1
          : 1
      );
      return table.slice(start, start + limit);
    } else {
      return table;
    }
  };

  if (isSearching) {
    if (tableData.length === totalItems) {
      setTotalItemsActive(0);
      setTotalPages(1);
      // return SearchNotFound
    } else if (tableData.length > 0) {
      result = createTableRows(tableData, 'Y');
    }
  } else {
    if (tableData.length > 0) {
      result = createTableRows(tableData, 'Y');
    }
  }

  if (!result) {
    return <Table.Row />;
  } else {
    return <React.Fragment>{result}</React.Fragment>;
  }
};

export default ItemTableRows;
