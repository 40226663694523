import React, { useContext } from 'react';
import Amplify from 'aws-amplify';
import Routes from './Routes';
import AppContext, { AppProvider } from './contexts/AppContext';
import './App.css';
import config from './config';
import NotFound from './components/Errors/NotFound';

Amplify.configure(config.cognito);

const App: React.FC = () => {
  const { isAuthenticating, isAuthenticated } = useContext(AppContext);
  if (!isAuthenticating || isAuthenticated) {
    return (
      <div className="App">
        <AppProvider>
          <Routes />
        </AppProvider>
      </div>
    );
  }

  return <NotFound />;
};

export default App;
