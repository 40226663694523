import React from 'react';
import { Table } from 'semantic-ui-react';

const MatchTableHeader: React.FC = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell>SKU Cliente</Table.HeaderCell>
        <Table.HeaderCell>Descrição cliente</Table.HeaderCell>
        <Table.HeaderCell>Atualizado Em</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export default MatchTableHeader;
