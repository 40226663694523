import axios from 'axios';
import config from '../../config';
import { getToken, getIdToken } from '../service/auth';

const api = axios.create({
  baseURL: config.database
});

api.interceptors.request.use(async (configAxios) => {
  const token = getToken();
  if (token) {
    configAxios.headers.Authorization = `Bearer ${token}`;
    configAxios.headers['app-key'] = getIdToken();
  }
  return configAxios;
});

export const axiosHeaderJson = {
  headers: { 'Content-Type': 'application/json' }
};
export const axiosHeaderMultipart = {
  headers: { 'Content-Type': 'multipart/form-data' }
};
export default api;
