import React, { useEffect, useState } from 'react';
import {
  Grid,
  Icon,
  Menu,
  Message,
  Pagination,
  PaginationProps,
  Segment,
  Table
} from 'semantic-ui-react';
import AxiosMessageErrors from '../../Errors/AxiosMessageErrors';
import CnpjSearch from '../../Rules/CNPJ/CnpjSearch';
import CnpjTableHeader from '../../Rules/CNPJ/CnpjTableHeader';
import CnpjTableRows from '../../Rules/CNPJ/CnpjTableRows';
import CnpjModal from '../../Rules/CNPJ/CnpjModal';
import { revalidateOptions, useFetch } from '../../../hooks/useFetch';
import NoData from '../../Commom/NoData';
import { exportExcel } from '../../../hooks/exportExcel';
import RulesButtons from '../../Rules/RulesButtons';
import RulesModalUpload from '../../Rules/ModalUpload';
import { ICnpj } from '../../Rules/_interfaces/cnpj.interfaces';
import { IError } from '../../Commom/_interfaces/error.interface';

const CNPJ: React.FC = () => {
  const { data, error, mutate: mutateCnpj } = useFetch<ICnpj[]>(
    'cnpj/get',
    revalidateOptions
  );

  const limit = 10;
  const [flagCnpj, setFlagCnpj] = useState(false);
  const [page, setPage] = useState<number | string>(1);
  const [errors, setErrors] = useState<IError | boolean>(false);
  const [modalItem, setModalItem] = useState<ICnpj>({} as ICnpj);
  const [click, setClick] = useState(false);
  const [create, setCreate] = useState(false);
  const [upload, setUpload] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [totalItemsActive, setTotalItemsActive] = useState(0);
  const [searchResult, setSearchResult] = useState<ICnpj[]>([]);
  const [tableData, setTableData] = useState<ICnpj[]>([]);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (errors) {
        setTimeout(() => {
          setErrors(false);
        }, 10000);
      }

      if (data && data.length === 0) {
        setNoData(true);
      } else if (data) {
        setTableData(data);
        setNoData(false);
      }

      if (error) {
        setErrors(error);
      }
    };
    getData();
  }, [errors, data, error]);

  const handleTotalItems = (totalItemsActive: number) => {
    setTotalItemsActive(totalItemsActive);
  };

  const handleIsSearching = (isSearching: boolean) => {
    setIsSearching(isSearching);
  };

  const handlePageChange = (data: PaginationProps) => {
    if (data.activePage) {
      setPage(data.activePage);
    }
  };

  const handleTotalPages = (totalPages: number) => {
    setTotalPages(totalPages);
  };

  const handleSearchResult = (searchResult: ICnpj[]) => {
    setSearchResult(searchResult);
  };

  const handleModal = (modalItem: ICnpj) => {
    setModalItem(modalItem);
  };

  const handleClick = (click: boolean) => {
    setClick(click);
  };

  const handleFlagCnpj = (flagCnpj: boolean) => {
    setFlagCnpj(flagCnpj);
  };

  const handleCreateClick = (create: boolean) => {
    setCreate(create);
  };

  const handleUpload = (uploadState: boolean) => {
    setUpload(uploadState);
    setFlagCnpj(uploadState);
  };

  const handleExport = async () => {
    setIsLoadingButton(true);
    try {
      await exportExcel('cnpj/excel', 'exp_cnpj');
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoadingButton(false);
  };

  const handleDownload = async () => {
    setIsLoadingButton(true);
    try {
      await exportExcel('cnpj/excel/model', 'cnpj_modelo');
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoadingButton(false);
  };

  return (
    <Segment padded="very" textAlign="center" basic>
      {click || create ? (
        <CnpjModal
          modalItem={modalItem}
          click={click}
          create={create}
          setClick={handleClick}
          setCreate={handleCreateClick}
          mutate={mutateCnpj}
        />
      ) : (
        ''
      )}
      {upload ? (
        <RulesModalUpload
          setFlagCnpj={handleFlagCnpj}
          upload={upload}
          setUpload={setUpload}
          flagCnpj={flagCnpj}
          mutate={mutateCnpj}
        />
      ) : (
        ''
      )}
      <Grid
        style={{ marginBottom: '10px', justifyContent: 'center' }}
        stackable
        textAlign="center"
        verticalAlign="middle"
      >
        <Grid.Row>
          <RulesButtons
            handleCreateClick={handleCreateClick}
            handleUpload={handleUpload}
            handleExport={handleExport}
            handleDownload={handleDownload}
            isLoadingButton={isLoadingButton}
            hideButtons={true}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} tablet={6}>
            <CnpjSearch
              tableData={tableData}
              setIsSearching={handleIsSearching}
              handleSearchResult={handleSearchResult}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} tablet={3}>
            <Menu fluid vertical>
              <Menu.Item>
                Total <b>{totalItemsActive}</b>
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div>
        {errors ? <AxiosMessageErrors error={errors} /> : ''}
        {noData ? (
          <NoData />
        ) : (
          <div style={{ overflowX: 'scroll' }}>
            <Table
              celled
              striped
              collapsing
              fixed
              stackable
              sortable
              style={{ minWidth: '100vw' }}
            >
              <CnpjTableHeader />
              {searchResult.length === 0 &&
              tableData.length === 0 &&
              !errors &&
              !noData ? (
                <React.Fragment>
                  <Table.Cell />
                  <Message size="large">
                    <Icon name="sync" loading />
                    Os dados estão sendo carregados...
                  </Message>
                </React.Fragment>
              ) : (
                <Table.Body>
                  <CnpjTableRows
                    tableData={
                      searchResult.length > 0 ? searchResult : tableData
                    }
                    page={page}
                    limit={limit}
                    isSearching={isSearching}
                    totalItems={tableData.length}
                    setTotalPages={handleTotalPages}
                    setTotalItemsActive={handleTotalItems}
                    setModalItem={handleModal}
                    setClick={handleClick}
                  />
                </Table.Body>
              )}
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell colSpan="14">
                    <Pagination
                      defaultActivePage={1}
                      totalPages={
                        totalPages || Math.ceil(tableData.length / limit)
                      }
                      onPageChange={(e, data: PaginationProps) =>
                        handlePageChange(data)
                      }
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
        )}
      </div>
    </Segment>
  );
};

export default CNPJ;
