/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { Chart } from 'react-google-charts';
import { Grid, Header } from 'semantic-ui-react';
import baseColors from '../../../assets/colors';
import pieCalc from '../../../utils/helpers/pieCalc';
import Loading from '../../Commom/Loading';
import { IDropDown } from '../../Commom/_interfaces/dropdown.interface';

interface Props {
  pieData: any[];
  pieHeader: string[];
  namesArray: IDropDown[];
  amountTop8: any[];
  valueTop8: any[];
  valueArray: any[];
  select: string;
}

const ReportPieChart: React.FC<Props> = ({
  pieData,
  pieHeader,
  namesArray,
  amountTop8,
  valueTop8,
  valueArray,
  select
}) => {
  const [pieAmount, setPieAmount] = useState<any[]>([]);
  const [pieValue, setPieValue] = useState<any[]>([]);
  const staticHeader = ['Name', 'Total'];

  useEffect(
    () => {
      const formatPie = () => {
        if (
          pieData.length > 0 &&
          amountTop8.length > 0 &&
          valueArray.length > 0 &&
          namesArray.length > 0
        ) {
          const pieAmt = pieCalc(
            pieData,
            pieHeader,
            amountTop8,
            select || namesArray[0].text
          );
          setPieAmount(pieAmt);
          const pieVal = pieCalc(
            valueArray,
            pieHeader,
            valueTop8,
            select || namesArray[0].text
          );
          const final: (string | number)[][] = [];
          if (pieVal.length > 0 && pieVal[0][0] === undefined) {
            pieAmt.forEach((el, i) =>
              final.push([
                el[0],
                parseFloat((pieVal[i][1] as number).toFixed(2))
              ])
            );
            setPieValue(final);
          } else {
            setPieValue(pieVal);
          }
        }
      };
      formatPie();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pieData, amountTop8, valueTop8, valueArray, select, namesArray]
  );

  return (
    <>
      <Header
        content="Distribuição por UF"
        style={{ color: baseColors.blue, textTransform: 'uppercase' }}
      />
      <Grid
        style={{ marginBottom: '10px', justifyContent: 'center' }}
        stackable
        textAlign="center"
        verticalAlign="middle"
      >
        <Grid.Row>
          <Chart
            width={'500px'}
            height={'300px'}
            chartType="PieChart"
            loader={<Loading />}
            data={[staticHeader, ...pieAmount]}
            options={{
              title: 'Total de unidades'
            }}
          />
          <Chart
            width={'500px'}
            height={'300px'}
            chartType="PieChart"
            loader={<Loading />}
            data={[staticHeader, ...pieValue]}
            options={{
              title: 'Valor total (R$)'
            }}
          />
        </Grid.Row>
      </Grid>
    </>
  );
};

export default ReportPieChart;
