import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonProps,
  Form,
  Grid,
  Header,
  InputOnChangeData,
  Segment
} from 'semantic-ui-react';
import baseColors from '../../assets/colors';
import AppContext from '../../contexts/AppContext';
import { IError } from '../Commom/_interfaces/error.interface';
import CognitoNegativeMessage from '../Errors/CognitoNegativeMessage';

interface IChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePassword: React.FC = () => {
  const history = useHistory();
  const { userHasAuthenticated } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [changePasswordData, setChangePassword] = useState<IChangePassword>(
    {} as IChangePassword
  );

  const [user, setUser] = useState('');

  const [errors, setErrors] = useState<IError | boolean>(false);

  const handleChange = (data: InputOnChangeData) => {
    if (data && changePasswordData)
      setChangePassword({ ...changePasswordData, [data.name]: data.value });
  };

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      try {
        setUser(await Auth.currentUserPoolUser());
      } catch (error) {
        setErrors(error.response);
      }
      setIsLoading(false);
    };
    getUser();
  }, []);

  const handleNewPassword = async (e: ButtonProps) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (user) {
        if (
          changePasswordData.newPassword ===
          changePasswordData.confirmNewPassword
        ) {
          await Auth.changePassword(
            user,
            changePasswordData.oldPassword,
            changePasswordData.newPassword
          );
          alert(
            'Senha alterada com sucesso! Você será redirecionado para o login!'
          );
          await Auth.signOut();
          userHasAuthenticated(false);
          history.push('/auth/entrar');
        } else {
          alert('Confirmação da senha está diferente!');
        }
      }
    } catch (error) {
      console.log(error);
      setErrors(error.response);
      if ('code' in error && error.code === 'NotAuthorizedException') {
        alert('Senha atual inválida!');
      }
    }
    setIsLoading(false);
  };

  return (
    <Grid textAlign="center" style={{ height: '70vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" style={{ color: baseColors.blue }} textAlign="center">
          Trocar senha
        </Header>
        <Form size="large" loading={isLoading}>
          {errors ? <CognitoNegativeMessage props={errors} /> : ''}
          <Segment stacked padded="very">
            <Form.Input
              fluid
              icon="lock"
              name="oldPassword"
              iconPosition="left"
              label="Senha antiga"
              type="password"
              value={changePasswordData.oldPassword}
              onChange={(e, data: InputOnChangeData) => handleChange(data)}
            />
            <Form.Input
              fluid
              icon="lock"
              name="newPassword"
              iconPosition="left"
              label="Nova senha"
              type="password"
              value={changePasswordData.newPassword}
              onChange={(e, data: InputOnChangeData) => handleChange(data)}
            />
            <Form.Input
              fluid
              icon="lock"
              name="confirmNewPassword"
              iconPosition="left"
              label="Confirme a nova senha"
              type="password"
              value={changePasswordData.confirmNewPassword}
              onChange={(e, data: InputOnChangeData) => handleChange(data)}
            />
            <Button
              style={{ background: baseColors.blue, color: 'white' }}
              fluid
              size="medium"
              onClick={(e: ButtonProps) => handleNewPassword(e)}
            >
              Trocar a senha
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default ChangePassword;
