import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import dateTimeConverter from '../../utils/helpers/dateTimeConverter';
import { IMatch } from './_interfaces/match.interfaces';

interface Props {
  tableData: IMatch[];
  page: number | string;
  limit: number;
  isSearching: boolean;
  totalItems: number;
  setTotalItemsActive: (totalItemsActive: number) => void;
  setTotalPages: (totalPages: number) => void;
  setModalItem: (modalItem: IMatch) => void;
  setClick: (value: boolean) => void;
}

const MatchTableRows: React.FC<Props> = ({
  tableData,
  page,
  limit,
  isSearching,
  totalItems,
  setTotalItemsActive,
  setTotalPages,
  setModalItem,
  setClick
}): JSX.Element | null => {
  let result;
  let start = 0;
  if (typeof page === 'number') {
    start = page === 1 ? 0 : (page - 1) * limit;
  } else if (typeof page === 'string') {
    start = page === '1' ? 0 : (parseInt(page) - 1) * limit;
  }

  const handleClick = (item: IMatch) => {
    setModalItem(item);
    setClick(true);
  };

  const createTableRows = (array: IMatch[], slice: string) => {
    if (array.length === 0) {
      return null;
    }
    const table = array.map((arr: IMatch) => (
      <Table.Row key={arr.sku_cliente} rowSpan="2">
        <Table.Cell>
          <Button icon="search" onClick={() => handleClick(arr)} />
        </Table.Cell>
        <Table.Cell>{arr.sku_cliente}</Table.Cell>
        <Table.Cell>{arr.desc_cliente}</Table.Cell>
        <Table.Cell>
          {dateTimeConverter(arr.updated_at, 'DD/MM/YY - hh:mm')}
        </Table.Cell>
      </Table.Row>
    ));
    setTotalItemsActive(
      isSearching ? array.length : tableData ? tableData.length : 0
    );
    if (slice === 'Y') {
      setTotalPages(
        Math.ceil(table.length / limit) - 1 !== 0
          ? Math.floor(table.length / limit) + 1
          : 1
      );
      return table.slice(start, start + limit);
    } else {
      return table;
    }
  };

  if (isSearching) {
    if (tableData && tableData.length === totalItems) {
      setTotalItemsActive(0);
      setTotalPages(1);
      // return SearchNotFound;
    } else if (tableData && tableData.length > 0) {
      result = createTableRows(tableData, 'Y');
    }
  } else {
    if (tableData && tableData.length > 0) {
      result = createTableRows(tableData, 'Y');
    }
  }

  if (!result) {
    return null;
  } else {
    return <React.Fragment>{result}</React.Fragment>;
  }
};

export default MatchTableRows;
