import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownProps,
  Form,
  Grid,
  Header,
  InputOnChangeData,
  Label,
  Message,
  Radio,
  Segment
} from 'semantic-ui-react';
import states from '../../utils/constants/states.json';
import api from '../../utils/helpers/axios.instance';
import NotAuthorized from '../Errors/NotAuthorized';
import '../../assets/css/register.css';
import { validateRegister } from '../../utils/helpers/validateRegister';

import {
  IError,
  IRegisterFormErrors
} from '../Commom/_interfaces/error.interface';
import { IDropDown } from '../Commom/_interfaces/dropdown.interface';
import AppContext from '../../contexts/AppContext';
import {
  IRegisterForm,
  ICompanyData
} from '../../hooks/reducers/formRegisterReducer';
import baseColors from '../../assets/colors';
import CognitoNegativeMessage from '../Errors/CognitoNegativeMessage';

const Register: React.FC = () => {
  const initalState = {
    name: '',
    cognito_id: '',
    role: '',
    company_id: 0
  };
  const { userData } = useContext(AppContext);
  const [errors, setErrors] = useState<IError | boolean>(false);
  const [formError, setFormError] = useState<IRegisterFormErrors | null>(null);
  const [uf, setUf] = useState<
    string | number | true | (string | number | boolean)[]
  >([]);
  const [companyId, setCompanyId] = useState<
    string | number | true | (string | number | boolean)[]
  >(0);
  const [cnpj, setCNPJ] = useState('');
  const [companies, setCompanies] = useState<IDropDown[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [cnpjRadio, setCnpjRadio] = useState(false);
  const [ufRadio, setUfRadio] = useState(false);
  const [credentials, setCredentials] = useState<IRegisterForm>(initalState);
  const handleChange = (data: InputOnChangeData) => {
    if (data.name) {
      setCredentials({ ...credentials, [data.name]: data.value });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (userData && userData.data.role !== 'admin') {
      setIsAdmin(false);
    } else {
      setIsAdmin(true);
    }

    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }

    setIsLoading(false);
  }, [userData, success]);

  useEffect(() => {
    setIsLoading(true);
    const getCompanies = async () => {
      try {
        const { data }: ICompanyData = await api.get('/company/get');
        const drop: IDropDown[] = data.map((comp) => ({
          key: comp.id,
          value: comp.id,
          text: comp.name
        }));
        setCompanies(drop);
      } catch (error) {
        setErrors(error.response);
      }
    };
    getCompanies();

    setIsLoading(false);
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (uf && Array.isArray(uf)) {
      const { validForm, errors } = validateRegister(
        credentials,
        cnpj,
        uf,
        companyId
      );
      const { cognito_id, role, name } = credentials;
      if (validForm) {
        const user = {
          name,
          cognito_id,
          company_id: companyId,
          cnpj_filter: cnpjRadio ? cnpj : '',
          uf_filter: ufRadio ? uf.join(';') : '',
          role: role?.toLowerCase()
        };
        try {
          await api.post('/user/add', user);
          setCredentials({ name: '', cognito_id: '', role: '', company_id: 0 });
          setCompanyId(0);
          setCnpjRadio(false);
          setUfRadio(false);
          setUf([]);
          setCNPJ('');
          setSuccess(true);
        } catch (error) {
          setErrors(error.response);
        }
      } else {
        setFormError(errors);
      }
    }
    setIsLoading(false);
  };

  function renderDefaultForm() {
    return (
      <Grid
        textAlign="center"
        style={{ marginTop: '5%' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ minWidth: 350, maxWidth: 450 }}>
          <Header as="h2" style={{ color: baseColors.blue }} textAlign="center">
            Criar uma conta
          </Header>
          {success ? (
            <Message
              content="Usuário criado com sucesso!"
              success
              attached="top"
            />
          ) : (
            ''
          )}
          {errors ? <CognitoNegativeMessage props={errors} /> : ''}
          <Form
            size="large"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}
            loading={isLoading}
          >
            <Segment stacked padded="very">
              <Form.Input
                fluid
                required
                name="name"
                icon="user"
                iconPosition="left"
                label="Nome"
                value={credentials.name}
                onChange={(e, data: InputOnChangeData) => handleChange(data)}
              />
              <Form.Input
                fluid
                required
                name="cognito_id"
                icon="user"
                iconPosition="left"
                label="Cognito ID"
                value={credentials.cognito_id}
                error={formError?.cognito_id}
                onChange={(e, data: InputOnChangeData) => handleChange(data)}
              />

              {formError?.radio ? (
                <Label basic color="red" pointing="below">
                  {formError.radio}
                </Label>
              ) : (
                ''
              )}
              <div style={{ textAlign: 'left', marginTop: 25 }}>
                <Form.Field>
                  <Radio
                    label="Filtro por CNPJ"
                    name="cnpj"
                    value="cnpj"
                    checked={cnpjRadio}
                    onClick={() => {
                      setCnpjRadio(true);
                      setUfRadio(false);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Filtro por estados"
                    name="uf"
                    value="uf"
                    onClick={() => {
                      setCnpjRadio(false);
                      setUfRadio(true);
                    }}
                    checked={ufRadio}
                  />
                </Form.Field>
              </div>
              {cnpjRadio ? (
                <div style={{ marginTop: '20px' }}>
                  <Form.Input
                    name="cnpj"
                    fluid
                    icon="building outline"
                    iconPosition="left"
                    placeholder="00000000000000"
                    label="CNPJ (apenas números) separados por ponto e vírgula"
                    type="text"
                    value={cnpj}
                    error={formError?.cnpj}
                    onChange={(e) => setCNPJ(e.target.value)}
                  />
                </div>
              ) : ufRadio ? (
                <div style={{ marginTop: '20px' }}>
                  <Dropdown
                    name="uf"
                    placeholder="Estados"
                    clearable
                    fluid
                    multiple
                    selection
                    options={states}
                    onChange={(e, data: DropdownProps) => {
                      if (data && data.value) {
                        setUf(data?.value);
                      }
                    }}
                  />
                </div>
              ) : (
                ''
              )}

              <div style={{ marginTop: '20px' }}>
                <Form.Input
                  fluid
                  name="role"
                  icon="cog"
                  iconPosition="left"
                  label="Função (se não for admin, deixar em branco)"
                  type="text"
                  value={credentials.role}
                  error={formError?.role}
                  onChange={(e, data: InputOnChangeData) => handleChange(data)}
                />
                <div>
                  <label>Empresa</label>
                  <Dropdown
                    name="company_id"
                    placeholder="Escolha uma empresa"
                    clearable
                    fluid
                    loading={isLoading}
                    selection
                    value={companyId}
                    options={companies}
                    onChange={(e, data: DropdownProps) => {
                      if (data && data.value) {
                        setCompanyId(data.value);
                      }
                    }}
                  />
                </div>
              </div>
              {formError?.company_id ? (
                <Label basic color="red" pointing>
                  {formError.company_id}
                </Label>
              ) : (
                ''
              )}
              <Button
                style={{
                  background: baseColors.blue,
                  color: 'white',
                  marginTop: '20px'
                }}
                fluid
                size="large"
              >
                Criar
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }

  return !isAdmin ? <NotAuthorized /> : renderDefaultForm();
};

export default Register;
