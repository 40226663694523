/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Segment, Statistic } from 'semantic-ui-react';
import formatNumbers from '../../utils/helpers/formatNumbers';

export interface InfosNew {
  countQuoted?: string;
  units: string;
  totalValueQuoted: string;
}

interface Props {
  value: any;
  amount: any;
}

const handleSum = (list: any[][]) => {
  let sumTotal = 0;
  for (let i = 0; i < list.length; i++) {
    let sum = 0;
    for (let j = 1; j < list[i].length; j++) {
      sum += list[i][j];
    }
    sumTotal += sum;
  }
  return sumTotal;
};

const ReportStatistics: React.FC<Props> = ({ value, amount }) => {
  const [totals, setTotals] = useState<InfosNew | null>();
  const marginLeft = '40px';

  useEffect(() => {
    if (value.length > 0 && amount.length > 0) {
      const tot = handleSum(value);
      const amo = handleSum(amount);
      setTotals({
        totalValueQuoted: formatNumbers(tot, 'currency'),
        units: formatNumbers(amo, 'decimal')
      });
    }
  }, [value, amount]);

  const windowSize = window.innerHeight > 768 ? 'tiny' : 'small';

  return (
    <Segment basic padded data-component="report-statistics">
      <Statistic.Group
        widths="16"
        size={windowSize}
        style={{ justifyContent: 'center' }}
      >
        <Statistic color="teal" style={{ marginLeft: marginLeft }}>
          <Statistic.Value>
            {totals ? totals.totalValueQuoted : 0}
          </Statistic.Value>
          <Statistic.Label>Potencial de vendas</Statistic.Label>
        </Statistic>

        <Statistic color="blue" style={{ marginLeft: marginLeft }}>
          <Statistic.Value>{totals ? totals.units : 0}</Statistic.Value>
          <Statistic.Label>Total de itens (unidades)</Statistic.Label>
        </Statistic>
      </Statistic.Group>
    </Segment>
  );
};

export default ReportStatistics;
