import React, { useEffect, useState } from 'react';
import {
  Grid,
  Header,
  Menu,
  Pagination,
  PaginationProps,
  Segment,
  Table
} from 'semantic-ui-react';
import ReportTableRows from './ReportTableRows';
import Loading from '../../Commom/Loading';
import ReportSearch from './ReportSearch';
import { IMapPoints } from '../_interfaces/reports.interfaces';
import baseColors from '../../../assets/colors';
import tableReducer from '../../../hooks/reducers/tableReducer';

interface Props {
  mapPoints: IMapPoints[];
}

const ReportTable: React.FC<Props> = ({ mapPoints }) => {
  const limit = 10;
  const [tableData, setTableData] = useState<IMapPoints[]>(mapPoints);
  const [page, setPage] = useState<number | string>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [totalItemsActive, setTotalItemsActive] = useState(0);
  const [searchResult, setSearchResult] = useState<IMapPoints[]>([]);

  const [state, dispatch] = React.useReducer(tableReducer, {
    column: null,
    data: tableData,
    direction: null
  });

  const { column, data, direction } = state;

  const handleTotalItems = (totalItemsActive: number) => {
    setTotalItemsActive(totalItemsActive);
  };

  const handleIsSearching = (isSearching: boolean) => {
    setIsSearching(isSearching);
  };

  const handlePageChange = (data: PaginationProps) => {
    if (data.activePage !== undefined) {
      setPage(data.activePage);
    }
  };

  const handleTotalPages = (totalPages: number) => {
    setTotalPages(totalPages);
  };

  const handleSearchResult = (searchResult: IMapPoints[]) => {
    setSearchResult(searchResult);
  };

  useEffect(() => {
    if (!state.data.length) {
      setTableData(mapPoints);
      dispatch({
        type: 'ADD_DEFAULT_VALUES',
        column: 'city',
        initialValue: tableData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPoints]);

  useEffect(() => {
    if (isSearching) {
      setSearchResult(data);
    } else {
      if (data.length > 0) {
        setTableData(data);
      } else {
        setTableData(mapPoints);
      }
    }
  }, [isSearching, data, mapPoints]);

  return (
    <Segment padded="very" data-component="report-table">
      <Header
        content="Listagem Geral"
        style={{ color: baseColors.blue, textTransform: 'uppercase' }}
      />
      <Grid
        style={{ marginBottom: '10px', justifyContent: 'center' }}
        stackable
        textAlign="center"
        verticalAlign="middle"
      >
        <Grid.Row>
          <Grid.Column widescreen={2} largeScreen={4} tablet={6}>
            <ReportSearch
              mapPoints={mapPoints}
              setIsSearching={handleIsSearching}
              handleSearchResult={handleSearchResult}
            />
          </Grid.Column>
          <Grid.Column widescreen={2} largeScreen={2} tablet={3}>
            <Menu compact vertical>
              <Menu.Item>
                Total <b>{totalItemsActive}</b>
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <div style={{ overflowX: 'scroll' }}>
          <Table
            celled
            striped
            stackable
            sortable
            fixed
            collapsing
            style={{ minWidth: '100vw' }}
          >
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'city' ? direction : null}
                  onClick={() =>
                    dispatch({ type: 'CHANGE_SORT', column: 'city' })
                  }
                >
                  Cidade
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'uf' ? direction : null}
                  onClick={() =>
                    dispatch({ type: 'CHANGE_SORT', column: 'uf' })
                  }
                >
                  UF
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'item_name' ? direction : null}
                  onClick={() =>
                    dispatch({ type: 'CHANGE_SORT', column: 'item_name' })
                  }
                >
                  Item
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'quantidade' ? direction : null}
                  onClick={() =>
                    dispatch({ type: 'CHANGE_SORT', column: 'quantidade' })
                  }
                >
                  Quantidade
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'total' ? direction : null}
                  onClick={() =>
                    dispatch({ type: 'CHANGE_SORT', column: 'total' })
                  }
                >
                  Total
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {tableData.length === 0 ? (
                <Loading />
              ) : (
                <ReportTableRows
                  mapPoints={searchResult.length > 0 ? searchResult : tableData}
                  setTotalPages={handleTotalPages}
                  page={page}
                  limit={limit}
                  setTotalItemsActive={handleTotalItems}
                  isSearching={isSearching}
                  totalItems={tableData.length}
                />
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="6">
                  <Pagination
                    defaultActivePage={1}
                    totalPages={
                      totalPages || Math.ceil(tableData.length / limit)
                    }
                    onPageChange={(e, data) => handlePageChange(data)}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </Grid>
    </Segment>
  );
};

export default ReportTable;
