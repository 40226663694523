import React, { useMemo, useState } from 'react';
import { Form, Input, Label } from 'semantic-ui-react';
import { useModalData } from '../../../contexts/ItemsContext';

interface Props {
  UF?: string;
  stringValue: string;
  type?: 'STD' | 'P2' | 'P3' | 'multiplo';
  customKey?: string;
  create?: boolean;
}

const FormItemRow: React.FC<Props> = ({
  UF,
  stringValue,
  type,
  customKey,
  create
}) => {
  const numPattern = /^\d*\.?\d*$/;
  const [numPatternError, setNumPatternError] = useState<undefined | string>(
    undefined
  );
  const [stringState, setStringState] = useState<any>(0);
  const { setModalData } = useModalData();

  let key = '';
  if (customKey) {
    key = customKey;
  } else {
    key = 'precO_' + UF + '_' + type;
  }
  useMemo(() => {
    if (!create) {
      setStringState(stringValue);
      if (stringValue === null) {
        setStringState(0);
        setModalData(key, stringState);
      }
    } else {
      setStringState('0');
      setModalData(key, stringState);
    }
  }, [create, stringValue]);
  return (
    <>
      <Form.Field>
        <label>{type}</label>
        <Input
          name={key}
          onChange={(e, { value }) => {
            if (!numPattern.test(value)) {
              setNumPatternError(
                'Tecla não é válida, apenas números e um ponto para separar'
              );
            } else {
              setNumPatternError(undefined);
              setModalData(key, value);
              setStringState(value);
            }
          }}
        >
          <input name={key} value={stringState} type="text" />
        </Input>
        {numPatternError && (
          <Label basic color="red" pointing="above">
            {numPatternError}
          </Label>
        )}
      </Form.Field>
    </>
  );
};

export default FormItemRow;
