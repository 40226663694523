/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  DropdownProps,
  Grid,
  Header,
  Icon,
  Label,
  Segment
} from 'semantic-ui-react';
import ItemsContext from '../../contexts/ItemsContext';
import api, { axiosHeaderJson } from '../../utils/helpers/axios.instance';
import {
  matchClientDropdownOptions,
  matchB2SDropdownQueryOptions,
  matchB2SDropdownShowAll
} from '../../utils/helpers/matchDropdownOptions';
import AxiosErrors from '../Errors/AxiosErrors';
import CatalogContex from '../../contexts/CatalogContext';
import { IDropDown } from '../Commom/_interfaces/dropdown.interface';
import { IError } from '../Commom/_interfaces/error.interface';
import { IMatch } from './_interfaces/match.interfaces';
import ConfirmSecondModal from '../Commom/ConfirmSecondModal';

interface Props {
  mutateMatch: () => Promise<IMatch[] | undefined>;
}

const MatchDropdowns: React.FC<Props> = ({ mutateMatch }) => {
  const { notAssociatedList, notAssociatedCount, mutateItems } = useContext(
    ItemsContext
  );
  const { catalogB2S, mutateCatalog, mutateRawMatch } = useContext(
    CatalogContex
  );
  const [b2sItems, setB2sItems] = useState<any>([]);
  const [clientItem, setClientItem] = useState<any>(0);

  const [b2sOptions, setB2sOptions] = useState<IDropDown[]>([]);
  // const [b2sCategories, setB2sCategories] = useState<IDropDown[]>([]);
  const [category, setCategory] = useState<DropdownProps | null>(null);
  const [clientOptions, setClientOptions] = useState<IDropDown[]>([]);
  const [missingCounter, setMissingCounter] = useState(0);
  const [isLoadingButton, setIsLoading] = useState(false);
  const [isLoadingB2SOptions, setIsLoadingB2SOptions] = useState(false);
  const [errors, setErrors] = useState<IError | boolean | null>(false);
  const [confirmation, setConfirmation] = useState(false);
  const noResultsMsg = 'Não foi encontrado nenhum registro';

  const handleConfirmationButton = () => {
    setConfirmation(false);
    setB2sItems([]);
    setB2sOptions([]);
    setClientItem('');
  };

  const handleMatchesSubmit = async () => {
    setIsLoading(true);
    if (b2sItems && clientItem) {
      try {
        const s = notAssociatedList.find((not) => not.id === clientItem);
        const data = {
          sku_cliente: s?.skU_CLIENTE,
          b2s_sku: b2sItems
        };
        await api.post('match', data, axiosHeaderJson);
        setConfirmation(true);
        setErrors(false);
        setCategory(null);
        mutateItems();
        mutateMatch();
        mutateCatalog();
        mutateRawMatch();
      } catch (error) {
        setErrors(error.message);
      }
      setIsLoading(false);
    } else {
      setErrors({ status: 400 });
    }
  };

  const handleShowAll = async () => {
    setIsLoadingB2SOptions(true);
    setB2sOptions(matchB2SDropdownShowAll(catalogB2S));
    setIsLoadingB2SOptions(false);
  };

  const handleClientItemChange = async (data: any) => {
    setIsLoadingB2SOptions(true);
    setB2sItems([]);
    if (data && catalogB2S && catalogB2S.length > 0) {
      try {
        const clientSelection = clientOptions?.find(
          (opt) => opt.value === data
        );
        const b2sQueryResult = await api.get(
          `listab2s/query?name=${clientSelection?.text}`
        );
        setB2sOptions(matchB2SDropdownQueryOptions(b2sQueryResult.data));
        setClientItem(data);
      } catch ({ response }) {
        if (response.data.message === 'Sequence contains no elements') {
          handleShowAll();
          setClientItem(data);
        } else {
          setErrors(response);
        }
      }
    }

    setIsLoadingB2SOptions(false);
  };

  useEffect(() => {
    if (notAssociatedList && notAssociatedList.length > 0) {
      setClientOptions(matchClientDropdownOptions(notAssociatedList));
    }

    if (errors) {
      setTimeout(() => {
        setErrors(false);
      }, 5 * 1000);
    }

    if (notAssociatedCount) {
      setMissingCounter(notAssociatedCount);
    }

    if (!category) {
      handleClientItemChange(clientItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notAssociatedList, notAssociatedCount, category]);

  return (
    <div>
      {confirmation ? (
        <ConfirmSecondModal
          confirmation={confirmation}
          setConfirmation={handleConfirmationButton}
        />
      ) : (
        ''
      )}
      {errors ? <AxiosErrors error={errors} /> : ''}
      <Segment placeholder stacked size="large" raised>
        {missingCounter === 0 ? (
          <Label color="green" attached="top left">
            <Icon name="check" />
            Todos seus itens estão associados
          </Label>
        ) : (
          <Label color="red" attached="top left">
            <Icon name="bell outline" /> &nbsp;
            {missingCounter === 1
              ? `Você tem 1 item não associado`
              : `Você tem ${missingCounter} itens não associados`}
          </Label>
        )}
        <Divider horizontal>
          <Header as="h2">Associar novo item</Header>
        </Divider>
        <Divider horizontal />
        <Grid stackable textAlign="center">
          <Grid.Row verticalAlign="middle">
            <Grid.Column>
              <label>Itens não associados</label>
              <Dropdown
                options={clientOptions}
                placeholder="Seus itens não associados"
                search
                selection
                fluid
                name="clientItem"
                noResultsMessage={noResultsMsg}
                onChange={(e: React.SyntheticEvent, data: DropdownProps) => {
                  if (data.value) {
                    handleClientItemChange(data.value);
                    setCategory(null);
                  }
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Divider horizontal>
            <Icon />
          </Divider>
          <Grid.Row>
            <Grid.Column>
              <label>Itens B2S</label>
              <Dropdown
                options={b2sOptions}
                placeholder="Itens B2S para associar"
                loading={isLoadingB2SOptions}
                disabled={isLoadingB2SOptions}
                search
                selection
                multiple
                fluid
                value={b2sItems}
                name="b2sItems"
                noResultsMessage={noResultsMsg}
                onChange={(e, { value }: DropdownProps) => {
                  if (value) {
                    setB2sItems(value);
                  }
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button.Group>
                <Button
                  loading={isLoadingButton}
                  content="Mostrar todos"
                  color="blue"
                  disabled={!clientItem}
                  onClick={() => handleShowAll()}
                  style={{ marginRight: '10px' }}
                />
                <Button
                  loading={isLoadingButton}
                  content="Associar"
                  color="green"
                  disabled={!b2sItems || !clientItem}
                  onClick={() => handleMatchesSubmit()}
                  style={{ marginRight: '10px' }}
                />
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
};

export default MatchDropdowns;
