import React from 'react';
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  List,
  Segment
} from 'semantic-ui-react';
import baseColors from '../../assets/colors';

const AppFooter: React.FC = () => {
  return (
    <div className="footer">
      <Segment
        inverted
        vertical
        style={{
          padding: '5em 0em',
          background: baseColors.blue,
          border: 'none'
        }}
      >
        <Container textAlign="center">
          <Grid divided inverted stackable style={{ justifyContent: 'center' }}>
            {/* <Grid.Column width={3}>
              <Header inverted as="h4" content="Group 1" />
              <List link inverted>
                <List.Item as="a">Link One</List.Item>
                <List.Item as="a">Link Two</List.Item>
                <List.Item as="a">Link Three</List.Item>
                <List.Item as="a">Link Four</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Group 2" />
              <List link inverted>
                <List.Item as="a">Link One</List.Item>
                <List.Item as="a">Link Two</List.Item>
                <List.Item as="a">Link Three</List.Item>
                <List.Item as="a">Link Four</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Group 3" />
              <List link inverted>
                <List.Item as="a">Link One</List.Item>
                <List.Item as="a">Link Two</List.Item>
                <List.Item as="a">Link Three</List.Item>
                <List.Item as="a">Link Four</List.Item>
              </List>
            </Grid.Column> */}
            <Grid.Column width={7}>
              <Header inverted as="h3" content="Contato" />
              <p> suporte@b2bmax.com.br</p>
            </Grid.Column>
          </Grid>

          <Divider inverted section />
          <Image centered size="small" src="/b2bMax_white.png" />
          <List horizontal inverted divided link size="small">
            {/* <List.Item as="a" href="#">
              Site Map
            </List.Item>
            <List.Item as="a" href="#">
              Contact Us
            </List.Item>
            <List.Item as="a" href="#">
              Terms and Conditions
            </List.Item>
            <List.Item as="a" href="#">
              Privacy Policy
            </List.Item> */}
          </List>
        </Container>
      </Segment>
    </div>
  );
};

export default AppFooter;
