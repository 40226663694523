import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonProps,
  Form,
  Grid,
  Header,
  Icon,
  InputOnChangeData,
  Message,
  Segment
} from 'semantic-ui-react';
import { IError } from '../Commom/_interfaces/error.interface';
import CognitoNegativeMessage from '../Errors/CognitoNegativeMessage';
import baseColors from '../../assets/colors';

interface ICredentials {
  email: string;
  confirmationCode: string;
  oldPassword: string;
  password: string;
  repeatPassword: string;
}

const ConfirmSignUp: React.FC = () => {
  const history = useHistory();

  const [credentials, setCredentials] = useState<ICredentials>(
    {} as ICredentials
  );
  const [errors, setErrors] = useState<IError | boolean>(false);
  const handleChange = (data: InputOnChangeData) => {
    if (data && credentials) {
      setCredentials({ ...credentials, [data.name]: data.value });
    }
  };

  const handleConfirmationCode = async (e: ButtonProps) => {
    e.preventDefault();
    const { email, confirmationCode } = credentials;
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      history.push('/auth/confirmar');
    } catch (error) {
      setErrors(error.response);
    }
  };

  const handleResendCode = async (e: ButtonProps) => {
    e.preventDefault();
    try {
      await Auth.resendSignUp(credentials.email);
    } catch (error) {
      setErrors(error.reponse);
    }
  };

  return (
    <div>
      <Grid
        textAlign="center"
        style={{ height: '70vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" style={{ color: baseColors.blue }} textAlign="center">
            Confirme sua conta!
          </Header>
          {errors ? <CognitoNegativeMessage props={errors} /> : ''}
          <Form size="large" onSubmit={handleConfirmationCode}>
            <Segment stacked padded="very">
              <Form.Input
                required
                fluid
                name="email"
                icon="mail"
                iconPosition="left"
                label="E-mail cadastrado"
                value={credentials.email}
                onChange={(e, data: InputOnChangeData) => handleChange(data)}
              />
              <Form.Input
                required
                fluid
                name="confirmationCode"
                icon="help"
                iconPosition="left"
                label="Código de confirmação"
                value={credentials.confirmationCode}
                onChange={(e, data: InputOnChangeData) => handleChange(data)}
              />
              <Button
                style={{ background: baseColors.blue, color: 'white' }}
                fluid
                size="large"
              >
                Confirmar conta!
              </Button>
            </Segment>
          </Form>
          <Message attached="bottom" warning>
            <Icon name="help" />
            Não recebeu o código?&nbsp;
            <a href="#" onClick={handleResendCode}>
              Reenviar
            </a>
          </Message>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ConfirmSignUp;
