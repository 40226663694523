import React from 'react'
import { Icon, Message } from 'semantic-ui-react'

function AxiosUploadErrors({ error }) {
	return (
		<div style={{ textAlignLast: 'center', marginBottom: '15px' }}>
			<Message negative icon attached="top" size="large">
				<Icon name="asterisk" loading />
				<Message.Content>
					<Message.Header>{error.data.message}</Message.Header>
					Código {error.status}
				</Message.Content>
				<Icon name="asterisk" loading />
			</Message>
		</div>
	)
}

export default AxiosUploadErrors
