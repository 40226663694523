/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownProps, Grid, Menu } from 'semantic-ui-react';
import limitDropdown from '../../../utils/constants/limitPerPage.json';
import { IActivity } from '../_interfaces/activity.interfaces';

interface Props {
  totalItemsActive: number;
  limit: any;
  setChangeLimit: (value: any) => void;
}

const ActivityLimitTotal: React.FC<Props> = ({
  totalItemsActive,
  limit,
  setChangeLimit
}) => {
  const [total, setTotal] = useState(0);

  const handleChangeItemPerPage = (data: DropdownProps) => {
    if (data) {
      setChangeLimit(data.value);
    }
  };

  useEffect(() => {
    setTotal(totalItemsActive);
  }, [totalItemsActive]);

  return (
    <React.Fragment>
      <Grid.Column largeScreen={3} tablet={5}>
        Resultados por página:
        <b>
          <Dropdown
            options={limitDropdown}
            defaultValue={String(limit)}
            labeled
            onChange={(e, data: DropdownProps) => handleChangeItemPerPage(data)}
          />
        </b>
      </Grid.Column>
      <Grid.Column largeScreen={2} tablet={3}>
        <Menu compact vertical>
          <Menu.Item>
            Total <b>{total}</b>
          </Menu.Item>
        </Menu>
      </Grid.Column>
    </React.Fragment>
  );
};

export default ActivityLimitTotal;
