import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import Loading from '../Commom/Loading';
import api from '../../utils/helpers/axios.instance';
import { IIssue } from './_interfaces/issues.interface';

interface Props {
  modalItem: IIssue | null;
  setClick: (value: boolean) => void;
}

const IssueModal: React.FC<Props> = ({ modalItem, setClick }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [modalData, setModalData] = useState<IIssue>({} as IIssue);

  useEffect(() => {
    const getItem = async () => {
      if (modalItem?.id) {
        try {
          const { id } = modalItem;
          const { data } = await api.get(`/issue/get/${id}`);
          setModalData(data);
          setOpen(true);
        } catch (error) {
          console.log(error);
        }
      }
      setIsLoading(false);
    };
    getItem();
  }, [modalItem, setIsLoading]);

  return isLoading ? (
    <Loading />
  ) : (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmer="blurring"
    >
      <React.Fragment>
        <Modal.Header>{`Item ${modalData?.verified}`}</Modal.Header>
        <Modal.Content>
          <Modal.Description />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={() => {
              setOpen(false);
              setClick(false);
            }}
          >
            Fechar
          </Button>
        </Modal.Actions>
      </React.Fragment>
    </Modal>
  );
};

export default IssueModal;
