import React, { createContext, useEffect, useState } from 'react';
import { IError } from '../components/Commom/_interfaces/error.interface';
import { IB2sList } from '../components/Match/_interfaces/match.interfaces';

import { useFetch } from '../hooks/useFetch';

export interface ICatalogRaw {
  b2s_sku: number;
  sku_cliente: string;
  created_at: Date;
  updated_at: Date;
  listaB2s?: IB2sList[];
}

interface CatalogContexData {
  catalogB2S: IB2sList[] | undefined;
  catalogError: IError | undefined;
  matchesError: IError | undefined;
  mutateCatalog: () => Promise<IB2sList[] | undefined>;
  mutateRawMatch: () => Promise<ICatalogRaw[] | undefined>;
}

const CatalogContex = createContext<CatalogContexData>({} as CatalogContexData);

export const CatalogProvider: React.FC = ({ children }) => {
  const {
    data: rawMatch,
    error: matchesError,
    mutate: mutateRawMatch
  } = useFetch<ICatalogRaw[]>('match/get/raw');
  const {
    data: catalog,
    error: catalogError,
    mutate: mutateCatalog
  } = useFetch<IB2sList[]>(`listab2s/get`);
  const [catalogB2S, setCatalogB2S] = useState<IB2sList[]>([]);

  useEffect(() => {
    if (rawMatch && catalog) {
      const rawSKU = rawMatch.map((raw: ICatalogRaw) => raw.b2s_sku);
      const filterCatalog = catalog.filter(
        (cat: IB2sList) => !rawSKU.includes(cat.b2S_SKU)
      );

      setCatalogB2S(filterCatalog);
    }
  }, [rawMatch, catalog]);

  return (
    <CatalogContex.Provider
      value={{
        catalogB2S,
        catalogError,
        matchesError,
        mutateRawMatch,
        mutateCatalog
      }}
    >
      {children}
    </CatalogContex.Provider>
  );
};

export default CatalogContex;
