import { Auth } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Form,
  FormInputProps,
  Grid,
  Header,
  Segment
} from 'semantic-ui-react';
import baseColors from '../../assets/colors';
import AppContext from '../../contexts/AppContext';
import CognitoNegativeMessage from '../Errors/CognitoNegativeMessage';
import { IUserLogin } from './Login';

interface Props {
  user: IUserLogin;
  setChangePassword: (value: boolean) => void;
}

interface IChangePassword {
  name: string;
  newPassword: string;
}

const RequiredPasswordChange: React.FC<Props> = ({
  user,
  setChangePassword
}) => {
  const history = useHistory();
  const { userHasAuthenticated } = useContext(AppContext);
  const [newCredentials, setNewCredentials] = useState<IChangePassword>({
    name: '',
    newPassword: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');

  const handleChange = (data: FormInputProps) => {
    if (data.name) {
      setNewCredentials({ ...newCredentials, [data.name]: data.value });
    }
  };

  const handlePasswordChange = async () => {
    setIsLoading(true);
    try {
      await Auth.completeNewPassword(user, newCredentials.newPassword, {
        name: newCredentials.name
      });
      userHasAuthenticated(true);
      setChangePassword(false);
      history.push('/user/relatorios');
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoading(false);
  };

  return (
    <Segment basic>
      <Grid
        textAlign="center"
        style={{ height: '60ßvh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" style={{ color: baseColors.blue }} textAlign="center">
            É preciso alterar a senha
          </Header>
          {errors ? <CognitoNegativeMessage props={errors} /> : ''}
          <Form size="large" loading={isLoading}>
            <Segment stacked padded="very">
              <Header
                as="h2"
                style={{ color: baseColors.blue }}
                textAlign="center"
              >
                Troque sua senha
              </Header>
              <Form.Input
                fluid
                icon="user"
                name="name"
                iconPosition="left"
                label="Seu nome"
                type="text"
                value={newCredentials.name}
                onChange={(e, data: FormInputProps) => handleChange(data)}
              />
              <Form.Input
                fluid
                icon="lock"
                name="newPassword"
                iconPosition="left"
                label="Nova senha"
                type="password"
                value={newCredentials.newPassword}
                onChange={(e, data: FormInputProps) => handleChange(data)}
              />
              <Button
                style={{ background: baseColors.yellow, color: 'white' }}
                fluid
                size="large"
                onClick={handlePasswordChange}
              >
                Trocar senha
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default RequiredPasswordChange;
