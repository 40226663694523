import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import baseColors from '../../assets/colors';
import { IError } from './_interfaces/error.interface';

interface Props {
  confirmation: boolean;
  setConfirmation: (value: boolean) => void;
  setDisabled?: (value: boolean) => void;
  errors?: boolean | IError;
  avisos?: boolean;
  customMsg?: any;
}

const ConfirmSecondModal: React.FC<Props> = ({
  setConfirmation,
  setDisabled,
  confirmation,
  errors,
  avisos,
  customMsg
}) => {
  return (
    <Modal
      onClose={() => setConfirmation(false)}
      open={confirmation}
      size="small"
    >
      <Modal.Header>
        {!errors && !avisos
          ? `Sucesso!`
          : !errors
          ? `Atenção!`
          : `Ocorreu um erro`}
      </Modal.Header>
      <Modal.Content>
        <p>
          {errors
            ? `Ao tentar salvar sua requisição ocorreu algum erro`
            : customMsg ||
              `Sua solicitação foi gerada com sucesso! Os dados da tabela serão atualizados em alguns instantes. Dependendo da quantidade adicionada de uma vez, pode demorar um pouco mais`}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          style={{ background: baseColors.blue, color: 'white' }}
          icon="check"
          content="Ok"
          onClick={() => {
            setConfirmation(false);
            if (setDisabled) {
              setDisabled(true);
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmSecondModal;
