import React from 'react'
import { Icon, Message } from 'semantic-ui-react'

function AxiosErrors({ error }) {
  let response = {
    status: 0,
    message: '',
    error: '',
  };

  if(error.code === 'NotAuthorizedException') {
    response = {
      error: 'Credenciais incorretas',
      status: 401,
      message: 'E-mail e/ou senha estão incorretos!'
    }
  }

	return (
		<div style={{ textAlignLast: 'center', marginBottom: '15px' }}>
			<Message negative icon attached="top" size="large">
				<Icon name="asterisk" loading />
				<Message.Content>
					<Message.Header>{response.error !== '' ? response.error : error.message}</Message.Header>
					{response.message ? response.message : error.message}
          <br/>
          Código {response.status ? response.status : error.status}
				</Message.Content>
				<Icon name="asterisk" loading />
			</Message>
		</div>
	)
}

export default AxiosErrors
