import { IDropDown } from '../../components/Commom/_interfaces/dropdown.interface';
import { INotStates } from '../../components/Rules/_interfaces/geo.interfaces';

export const geoStatesDropdown = (data: INotStates[]): IDropDown[] => {
  let result: IDropDown;
  const aux: IDropDown[] = [];
  if (data.length > 0) {
    data.map((d) => {
      result = {
        key: d.uf,
        value: d.uf,
        text: d.nome
      };
      aux.push(result);
      return aux;
    });
  }
  return aux;
};
