/* eslint-disable no-useless-catch */
import api from '../utils/helpers/axios.instance';
import { saveAs } from 'file-saver';

export async function exportExcel(
  url: string,
  name: string
): Promise<string | Error> {
  try {
    const { data } = await api.get(url, { responseType: 'arraybuffer' });
    if (!data) {
      throw new Error();
    } else {
      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, `${name}.xlsx`);
      return 'ok';
    }
  } catch (error) {
    throw error;
  }
}
