import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import { IError } from '../components/Commom/_interfaces/error.interface';
import { IItem } from '../components/Rules/Item/_interfaces/item.interfaces';
import api, { axiosHeaderJson } from '../utils/helpers/axios.instance';

import { useFetch } from '../hooks/useFetch';
import AppContext from './AppContext';

interface ItemsContextData {
  items: IItem[] | undefined;
  itemsErrors: IError | undefined;
  mutateItems: () => Promise<IItem[] | undefined>;
  notAssociatedCount: number;
  notAssociatedList: IItem[];
  setModalData: (key: string, value: boolean | string | number) => void;
  setAllModalData: (IItem: IItem) => void;
  getModalData: () => void;
  modalData: IItem | any;
  isLoadingTable: boolean;
  setIsLoadingTable: (bool: boolean) => void;
}

const ItemsContext = createContext<ItemsContextData>({} as ItemsContextData);

export const ItemsProvider: React.FC = ({ children }) => {
  const modalData = useRef<IItem>({} as IItem);
  const setModalData = (key, value) => {
    modalData.current = { ...modalData.current, [key]: value };
  };
  const setAllModalData = (IItem: IItem) => {
    modalData.current = IItem;
  };
  const getModalData = () => {
    const IItem = modalData.current as IItem;
    return IItem;
  };
  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const { isAuthenticated } = useContext(AppContext);

  const { data: items, error: itemsErrors, mutate: mutateItems } = useFetch<
    IItem[]
  >(!isAuthenticated ? '' : 'items/get', {
    refreshInterval: 1000 * 60
  });

  const {
    data: itemsNA,
    error: itemsNAErrors,
    mutate: itemsNAItems
  } = useFetch<IItem[]>(!isAuthenticated ? '' : 'items/get?associado=false', {
    refreshInterval: 1000 * 60
  });

  const [notAssociatedCount, setNotAssociatedCount] = useState<number>(0);
  const [notAssociatedList, setNotAssociatedList] = useState<IItem[]>([]);

  useEffect(() => {
    if (itemsNA && itemsNA.length > 0) {
      setNotAssociatedList(itemsNA);

      setNotAssociatedCount(itemsNA.length as number);
    }
  }, [items, itemsNA]);

  return (
    <ItemsContext.Provider
      value={{
        items,
        itemsErrors,
        notAssociatedCount,
        notAssociatedList,
        mutateItems,
        modalData,
        setModalData,
        setAllModalData,
        getModalData,
        isLoadingTable,
        setIsLoadingTable
      }}
    >
      {children}
    </ItemsContext.Provider>
  );
};
export function useModalData() {
  const context = useContext(ItemsContext);
  const { modalData, setModalData, setAllModalData, getModalData } = context;

  return { modalData, setModalData, setAllModalData, getModalData };
}
export function useIsloadingTable() {
  const context = useContext(ItemsContext);
  const { isLoadingTable, setIsLoadingTable } = context;

  return { isLoadingTable, setIsLoadingTable };
}
export default ItemsContext;
