import Blockly from 'blockly';
import { optionsDropdown } from '../../../../utils/constants/advancedOptions';
import { colors } from './blockly-list'

Blockly.Blocks['get_tag_1'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("tag_1"), "TAG_1");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_tag_1'] = {
  init: function() {
    this.appendValueInput("tag_1")
        .appendField("set")
        .appendField(new Blockly.FieldVariable("tag_1"), "TAG_1")
        .appendField("to");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

// Blockly.Blocks['get_tag_1'] = {
//   init: function() {
//     this.appendDummyInput()
//       .appendField(new Blockly.FieldVariable("tag_1"), "TAG_1");
//     this.setColour(colors.pink.hsv);
//     this.setOutput(true, null);
//   }
// };

// Blockly.Blocks['set_tag_1'] = {
//   init: function() {
//     this.appendValueInput("tag_1")
//         .appendField("set")
//         .appendField(new Blockly.FieldVariable("tag_1"), "TAG_1")
//         .appendField("to");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(colors.pink.hsv);
//     this.setOutput(null)
//   }
// };

Blockly.Blocks['get_tag_2'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("tag_2"), "TAG_2");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_tag_2'] = {
  init: function() {
    this.appendValueInput("tag_2")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("tag_2"), "TAG_2")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_tag_3'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("tag_3"), "TAG_3");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_tag_3'] = {
  init: function() {
    this.appendValueInput("tag_3")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("tag_3"), "TAG_3")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_tag_4'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("tag_4"), "TAG_4");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_tag_4'] = {
  init: function() {
    this.appendValueInput("tag_4")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("tag_4"), "TAG_4")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_tag_5'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("tag_5"), "TAG_5");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_tag_5'] = {
  init: function() {
    this.appendValueInput("tag_5")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("tag_5"), "TAG_5")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_comprador_cnpj'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("comprador_cnpj"), "COMPRADOR_CNPJ");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_comprador_cnpj'] = {
  init: function() {
    this.appendValueInput("comprador_cnpj")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("comprador_cnpj"), "COMPRADOR_CNPJ")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_comprador_cidade'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("comprador_cidade"), "COMPRADOR_CIDADE");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_comprador_cidade'] = {
  init: function() {
    this.appendValueInput("comprador_cidade")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("comprador_cidade"), "COMPRADOR_CIDADE")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_comprador_estado'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("comprador_estado"), "COMPRADOR_ESTADO");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_comprador_estado'] = {
  init: function() {
    this.appendValueInput("comprador_estado")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("comprador_estado"), "COMPRADOR_ESTADO")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_quantidade'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("quantidade"), "QUANTIDADE");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_quantidade'] = {
  init: function() {
    this.appendValueInput("quantidade")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("quantidade"), "QUANTIDADE")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_cliente_partnumber'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("cliente_partnumber"), "CLIENTE_PARTNUMBER");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_cliente_partnumber'] = {
  init: function() {
    this.appendValueInput("cliente_partnumber")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("cliente_partnumber"), "CLIENTE_PARTNUMBER")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_cliente_preco'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("cliente_preco"), "CLIENTE_PRECO");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_cliente_preco'] = {
  init: function() {
    this.appendValueInput("cliente_preco")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("cliente_preco"), "CLIENTE_PRECO")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_multiplicador_preco'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("multiplicador_preco"), "MULTIPLICADOR_PRECO");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_multiplicador_preco'] = {
  init: function() {
    this.appendValueInput("multiplicador_preco")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("multiplicador_preco"), "MULTIPLICADOR_PRECO")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_enabled'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("enabled"), "ENABLED");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_enabled'] = {
  init: function() {
    this.appendValueInput("enabled")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("enabled"), "ENABLED")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_cliente_fatmin'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("cliente_fatmin"), "CLIENTE_FATMIN");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_cliente_fatmin'] = {
  init: function() {
    this.appendValueInput("cliente_fatmin")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("cliente_fatmin"), "CLIENTE_FATMIN")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_cliente_frete'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("cliente_frete"), "CLIENTE_FRETE");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_cliente_frete'] = {
  init: function() {
    this.appendValueInput("cliente_frete")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("cliente_frete"), "CLIENTE_FRETE")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};

Blockly.Blocks['get_cliente_condpagto'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldVariable("cliente_condpagto"), "CLIENTE_CONDPAGTO");
    this.setColour(colors.pink.hsv);
    this.setOutput(true, null);
  }
};

Blockly.Blocks['set_cliente_condpagto'] = {
  init: function() {
    this.appendValueInput("cliente_condpagto")
        .appendField("Definir")
        .appendField(new Blockly.FieldVariable("cliente_condpagto"), "CLIENTE_CONDPAGTO")
        .appendField("para");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(colors.pink.hsv);
    this.setOutput(null)
  }
};
