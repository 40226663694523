import * as React from 'react';

import { createContext, useEffect, useState } from 'react';
import { IError } from '../components/Commom/_interfaces/error.interface';
import { IMatch } from '../components/Match/_interfaces/match.interfaces';
import { useFetch } from '../hooks/useFetch';

interface MatchContextData {
  matches: IMatch[] | undefined;
  matchesError: IError | undefined;
  mutate: () => Promise<IMatch[] | undefined>;
}

const MatchContext = createContext<MatchContextData>({} as MatchContextData);

export const MatchProvider: React.FC = ({ children }) => {
  const { data, error: matchesError, mutate } = useFetch<IMatch[]>('match/get');
  const [matches, setMatches] = useState<IMatch[] | undefined>();

  useEffect(() => {
    if (data) {
      setMatches(data);
    }
  }, [data]);

  return (
    <MatchContext.Provider value={{ matches, matchesError, mutate }}>
      {children}
    </MatchContext.Provider>
  );
};

export default MatchContext;
