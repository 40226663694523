/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import useSWR from 'swr';
import api from '../utils/helpers/axios.instance';

interface IError {
  message: string;
  status: number;
  error: { [key: string]: string | number | boolean | Date };
}

export function useFetch<Data = any>(url: string, options = {}) {
  const { data, error, mutate } = useSWR<Data, IError>(
    url,
    async (url) => {
      const response = await api.get(url);

      return response.data;
    },
    options
  );

  return { data, error, mutate };
}

export const revalidateOptions = {
  revalidateOnFocus: true,
  revalidateOnMount: true,
  revalidateOnReconnect: true
};
