/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Popup,
  Grid,
  Search,
  SearchProps,
  Form
} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import { unset } from 'lodash';

interface Props {
  search: string;
  dateIni: string;
  dateFim: string;
  setChangeFilter: (search: any, dateIni: any, dateFim: any) => void;
}

const ActivitySearch: React.FC<Props> = ({
  search,
  dateIni,
  dateFim,
  setChangeFilter
}) => {
  const [searchInput, setsearchInput] = useState('');
  const [dates, setDates] = useState<SemanticDatepickerProps['value']>([]);

  const handleDateChange = () => {
    let dateIniParse = '';
    let dateFimParse = '';
    if (dates !== null && dates[0] !== undefined && dates[0] !== null) {
      dateIniParse =
        dates[0].getFullYear() +
        '-' +
        ('0' + (dates[0].getMonth() + 1)).substr(-2) +
        '-' +
        ('0' + dates[0].getDate()).substr(-2);
      if (dates[1] !== undefined && dates[1] !== null) {
        dateFimParse =
          dates[1].getFullYear() +
          '-' +
          ('0' + (dates[1].getMonth() + 1)).substr(-2) +
          '-' +
          ('0' + dates[1].getDate()).substr(-2);
      } else {
        dateFimParse = dateIniParse;
      }
    }
    setChangeFilter(search, dateIniParse, dateFimParse);
  };

  const handleSearchChange = () => {
    setChangeFilter(searchInput, dateIni, dateFim);
  };

  const handleClear = () => {
    setChangeFilter('', '', '');
  };

  useEffect(() => {
    setsearchInput(search);
    if (dateIni === '') {
      setDates([]);
    } else if (dateFim === '') {
      setDates([dates[0]]);
    }
  }, [search, dateIni, dateFim]);

  const dateOnChange = (data: SemanticDatepickerProps) => {
    setDates(data.value);
  };

  const dateOnBlur = () => {
    handleDateChange();
  };

  const searchFormSubmit = () => {
    handleSearchChange();
  };

  const searchInputOnChange = (e) => {
    setsearchInput(e.target.value);
  };

  return (
    <>
      <Grid.Column largeScreen={4} tablet={6} className={'activity-search'}>
        <Form onSubmit={searchFormSubmit}>
          <Form.Input
            placeholder="Pesquise por"
            value={searchInput}
            onChange={searchInputOnChange}
            size="small"
          />
          <Form.Button type="submit" icon="search" />
        </Form>
      </Grid.Column>
      <Grid.Column largeScreen={3} tablet={5}>
        <SemanticDatepicker
          placeholder="Escolha uma data"
          format="DD/MM/YY"
          onChange={(e, data) => dateOnChange(data)}
          onBlur={(e) => dateOnBlur()}
          locale="pt-BR"
          type="range"
          pointing="left"
          className="teste123"
          value={dates}
          clearable={false}
        />
      </Grid.Column>
      <Grid.Column largeScreen={1} tablet={2}>
        <Popup
          content="Limpar pesquisa e datas"
          trigger={
            <Button
              icon="close"
              onClick={() => {
                handleClear();
              }}
              negative
            />
          }
        />
      </Grid.Column>
    </>
  );
};

export default ActivitySearch;
