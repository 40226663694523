/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';
import { Button, Divider, Form, Grid, Header, Modal } from 'semantic-ui-react';
import api, { axiosHeaderJson } from '../../../utils/helpers/axios.instance';
import ConfirmSecondModal from '../../Commom/ConfirmSecondModal';
import Loading from '../../Commom/Loading';
import FormItemRow from './FormItemRow';
import SimpleItemForm from './SimpleItemForm';
import Dropdown from './Dropdown';
import { IItem } from './_interfaces/item.interfaces';
import {
  useModalData,
  useIsloadingTable
} from '../../../contexts/ItemsContext';

interface Props {
  modalItem: IItem | null;
  click: boolean;
  create: boolean;
  mutate: () => void;
  setCreate: (value: boolean) => void;
  setClick: (value: boolean) => void;
}

const ItemModal: React.FC<Props> = ({
  modalItem,
  click,
  create,
  mutate,
  setCreate,
  setClick
}) => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  // const [formErrors, setFormErrors] = useState(false);
  const { modalData, setAllModalData } = useModalData();
  const { setIsLoadingTable } = useIsloadingTable();

  const [confirmation, setConfirmation] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const enabled = useRef<any>('');

  const setEnabled = (enable: string) => {
    enabled.current = enable;
  };

  const handleDeleteItem = async (id: number) => {
    setIsLoadingButton(true);
    try {
      await api.delete(`items/${id}`);
      setConfirmation(true);
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoadingButton(false);
  };

  const handleConfirmationButton = () => {
    setIsLoadingTable(true);
    setOpen(false);
    setClick(false);
    setCreate(false);
    setAllModalData({} as IItem);
    setConfirmation(false);
    mutate();
  };

  useEffect(() => {
    const modal = () => {
      setIsLoading(true);
      if (click && modalItem && refresh) {
        setAllModalData(modalItem);
        setEnabled(modalItem.enabled);
        setRefresh(false);
        setOpen(true);
      }
      if (create) {
        setAllModalData({
          id: 0,
          skU_CLIENTE: '',
          desC_CLIENTE: '',
          associado: false,
          enabled: '',
          fabricante: '',
          multiplo: 0,
          taG_1: '',
          taG_2: '',
          taG_3: '',
          taG_4: '',
          taG_5: '',
          unidadE_PRECO: '',
          precO_AC_P2: 0,
          precO_AC_P3: 0,
          precO_AC_STD: 0,
          precO_AL_P2: 0,
          precO_AL_P3: 0,
          precO_AL_STD: 0,
          precO_AM_P2: 0,
          precO_AM_P3: 0,
          precO_AM_STD: 0,
          precO_AP_P2: 0,
          precO_AP_P3: 0,
          precO_AP_STD: 0,
          precO_BA_P2: 0,
          precO_BA_P3: 0,
          precO_BA_STD: 0,
          precO_CE_P2: 0,
          precO_CE_P3: 0,
          precO_CE_STD: 0,
          precO_DF_P2: 0,
          precO_DF_P3: 0,
          precO_DF_STD: 0,
          precO_ES_P2: 0,
          precO_ES_P3: 0,
          precO_ES_STD: 0,
          precO_GO_P2: 0,
          precO_GO_P3: 0,
          precO_GO_STD: 0,
          precO_MA_P2: 0,
          precO_MA_P3: 0,
          precO_MA_STD: 0,
          precO_MG_P2: 0,
          precO_MG_P3: 0,
          precO_MG_STD: 0,
          precO_MS_P2: 0,
          precO_MS_P3: 0,
          precO_MS_STD: 0,
          precO_MT_P2: 0,
          precO_MT_P3: 0,
          precO_MT_STD: 0,
          precO_PA_P2: 0,
          precO_PA_P3: 0,
          precO_PA_STD: 0,
          precO_PB_P2: 0,
          precO_PB_P3: 0,
          precO_PB_STD: 0,
          precO_PE_P2: 0,
          precO_PE_P3: 0,
          precO_PE_STD: 0,
          precO_PI_P2: 0,
          precO_PI_P3: 0,
          precO_PI_STD: 0,
          precO_PR_P2: 0,
          precO_PR_P3: 0,
          precO_PR_STD: 0,
          precO_RJ_P2: 0,
          precO_RJ_P3: 0,
          precO_RJ_STD: 0,
          precO_RN_P2: 0,
          precO_RN_P3: 0,
          precO_RN_STD: 0,
          precO_RO_P2: 0,
          precO_RO_P3: 0,
          precO_RO_STD: 0,
          precO_RR_P2: 0,
          precO_RR_P3: 0,
          precO_RR_STD: 0,
          precO_RS_P2: 0,
          precO_RS_P3: 0,
          precO_RS_STD: 0,
          precO_SC_P2: 0,
          precO_SC_P3: 0,
          precO_SC_STD: 0,
          precO_SE_P2: 0,
          precO_SE_P3: 0,
          precO_SE_STD: 0,
          precO_SP_P2: 0,
          precO_SP_P3: 0,
          precO_SP_STD: 0,
          precO_TO_P2: 0,
          precO_TO_P3: 0,
          precO_TO_STD: 0
        });
        setOpen(true);
      }
      setIsLoading(false);
    };
    modal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create, refresh]);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (modalData.current && enabled.current) {
      try {
        Object.keys(modalData.current).forEach((item) => {
          if (
            (item !== 'id' &&
              item !== 'skU_CLIENTE' &&
              item !== 'desC_CLIENTE' &&
              item !== 'associado' &&
              item !== 'enabled' &&
              item !== 'fabricante' &&
              item !== 'taG_1' &&
              item !== 'taG_2' &&
              item !== 'taG_3' &&
              item !== 'taG_4' &&
              item !== 'taG_5' &&
              item !== 'unidadE_PRECO') ||
            modalData.current[item] === 0
          ) {
            modalData.current[item] = parseFloat(
              modalData.current[item] as any
            );
          }
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { ...submitData } = modalData.current as IItem;
        const data = {
          ...submitData
        };
        if (create) {
          await api.post('items', data, axiosHeaderJson);
          setIsLoading(false);
        } else {
          await api.put(`items/${data.id}`, data, axiosHeaderJson);
          setIsLoading(false);
        }
        setConfirmation(true);
      } catch (error) {
        setErrors(error.response);
      }
    } else {
      alert('preencha os campos');
      setIsLoading(false);
    }
  };

  return (
    <Modal
      onUnmount={() => {
        setOpen(false);
        setClick(false);
        setCreate(false);
        setAllModalData({} as IItem);
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmer="blurring"
      size="large"
    >
      <>
        <Modal.Header>
          {modalData.current.skU_CLIENTE
            ? `Item ${modalData.current.desC_CLIENTE}` // criar um state para mudar
            : 'Criar novo item'}
        </Modal.Header>
        {isLoading ? <Loading /> : ''}
        <Modal.Content>
          <Modal.Description>
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
                handleSubmit(e)
              }
            >
              <Grid verticalAlign="top">
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <SimpleItemForm
                      customKey="skU_CLIENTE"
                      customLabel="SKU CLIENTE"
                      stringValue={modalData.current.skU_CLIENTE}
                      create={create}
                      required={true}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <SimpleItemForm
                      customKey="fabricante"
                      customLabel="Fabricante"
                      stringValue={modalData.current.fabricante}
                      create={create}
                      required={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <SimpleItemForm
                      customKey="desC_CLIENTE"
                      customLabel="DESC CLIENTE"
                      stringValue={modalData.current.desC_CLIENTE}
                      create={create}
                      required={true}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Form.Field>
                      <FormItemRow
                        type="multiplo"
                        customKey="multiplo"
                        create={create}
                        stringValue={modalData.current.multiplo as string}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <SimpleItemForm
                      customKey="unidadE_PRECO"
                      customLabel="Unidade"
                      stringValue={modalData.current.unidadE_PRECO}
                      create={create}
                      required={true}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <label>Ativo</label>
                    <Dropdown
                      stringValue={modalData.current.enabled}
                      create={create}
                      setEnable={setEnabled}
                    />
                    {/* <Dropdown
                      fluid
                      selection
                      options={[
                        {
                          key: 'S',
                          value: 'S',
                          text: 'SIM'
                        },
                        {
                          key: 'N',
                          value: 'N',
                          text: 'NÃO'
                        }
                      ]}
                      name="enabled"
                      value={enabled}
                      onChange={(e, data: DropdownProps) => {
                        if (data) {
                          setEnabled(data.value);
                        }
                      }}
                    /> */}
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={5}>
                  <Grid.Column>
                    <SimpleItemForm
                      customKey="taG_1"
                      customLabel="Tag 1"
                      stringValue={modalData.current.taG_1}
                      create={create}
                      required={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <SimpleItemForm
                      customKey="taG_2"
                      customLabel="Tag 2"
                      stringValue={modalData.current.taG_2}
                      create={create}
                      required={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <SimpleItemForm
                      customKey="taG_3"
                      customLabel="Tag 3"
                      stringValue={modalData.current.taG_3}
                      create={create}
                      required={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <SimpleItemForm
                      customKey="taG_4"
                      customLabel="Tag 4"
                      stringValue={modalData.current.taG_4}
                      create={create}
                      required={false}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <SimpleItemForm
                      customKey="taG_5"
                      customLabel="Tag 5"
                      stringValue={modalData.current.taG_5}
                      create={create}
                      required={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Preços" />
                    <Divider />
                    <Header content="Acre" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="AC"
                      create={create}
                      stringValue={modalData.current.precO_AC_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="AC"
                      create={create}
                      stringValue={modalData.current.precO_AC_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="AC"
                      create={create}
                      stringValue={modalData.current.precO_AC_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Alagoas" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="AL"
                      create={create}
                      stringValue={modalData.current.precO_AL_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="AL"
                      create={create}
                      stringValue={modalData.current.precO_AL_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="AL"
                      create={create}
                      stringValue={modalData.current.precO_AL_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Amapá" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="AP"
                      create={create}
                      stringValue={modalData.current.precO_AP_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="AP"
                      create={create}
                      stringValue={modalData.current.precO_AP_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="AP"
                      create={create}
                      stringValue={modalData.current.precO_AP_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Amazonas" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="AM"
                      create={create}
                      stringValue={modalData.current.precO_AM_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="AM"
                      create={create}
                      stringValue={modalData.current.precO_AM_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="AM"
                      create={create}
                      stringValue={modalData.current.precO_AM_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Bahia" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="BA"
                      create={create}
                      stringValue={modalData.current.precO_BA_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="BA"
                      create={create}
                      stringValue={modalData.current.precO_BA_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="BA"
                      create={create}
                      stringValue={modalData.current.precO_BA_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Ceará" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="CE"
                      create={create}
                      stringValue={modalData.current.precO_CE_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="CE"
                      create={create}
                      stringValue={modalData.current.precO_CE_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="CE"
                      create={create}
                      stringValue={modalData.current.precO_CE_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Distrito Federal" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="DF"
                      create={create}
                      stringValue={modalData.current.precO_DF_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="DF"
                      create={create}
                      stringValue={modalData.current.precO_DF_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="DF"
                      create={create}
                      stringValue={modalData.current.precO_DF_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Espírito Santo" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="ES"
                      create={create}
                      stringValue={modalData.current.precO_ES_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="ES"
                      create={create}
                      stringValue={modalData.current.precO_ES_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="ES"
                      create={create}
                      stringValue={modalData.current.precO_ES_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Goiás" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="GO"
                      create={create}
                      stringValue={modalData.current.precO_GO_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="GO"
                      create={create}
                      stringValue={modalData.current.precO_GO_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="GO"
                      create={create}
                      stringValue={modalData.current.precO_GO_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Maranhão" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="MA"
                      create={create}
                      stringValue={modalData.current.precO_MA_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="MA"
                      create={create}
                      stringValue={modalData.current.precO_MA_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="MA"
                      create={create}
                      stringValue={modalData.current.precO_MA_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Mato Grosso" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="MT"
                      create={create}
                      stringValue={modalData.current.precO_MT_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="MT"
                      stringValue={modalData.current.precO_MT_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="MT"
                      create={create}
                      stringValue={modalData.current.precO_MT_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header
                      content="Mato Grosso do Sul"
                      as="h4"
                      color="orange"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="MS"
                      create={create}
                      stringValue={modalData.current.precO_MS_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="MS"
                      create={create}
                      stringValue={modalData.current.precO_MS_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="MS"
                      create={create}
                      stringValue={modalData.current.precO_MS_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Minas Gerais" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="MG"
                      create={create}
                      stringValue={modalData.current.precO_MG_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="MG"
                      create={create}
                      stringValue={modalData.current.precO_MG_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="MG"
                      create={create}
                      stringValue={modalData.current.precO_MG_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Pará" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="PA"
                      create={create}
                      stringValue={modalData.current.precO_PA_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="PA"
                      create={create}
                      stringValue={modalData.current.precO_PA_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="PA"
                      create={create}
                      stringValue={modalData.current.precO_PA_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Paraíba" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="PB"
                      create={create}
                      stringValue={modalData.current.precO_PB_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="PB"
                      create={create}
                      stringValue={modalData.current.precO_PB_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="PB"
                      create={create}
                      stringValue={modalData.current.precO_PB_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Paraná" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="PR"
                      create={create}
                      stringValue={modalData.current.precO_PR_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="PR"
                      create={create}
                      stringValue={modalData.current.precO_PR_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="PR"
                      create={create}
                      stringValue={modalData.current.precO_PR_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Pernambuco" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="PE"
                      create={create}
                      stringValue={modalData.current.precO_PE_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="PE"
                      create={create}
                      stringValue={modalData.current.precO_PE_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="PE"
                      create={create}
                      stringValue={modalData.current.precO_PE_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Piauí" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="PI"
                      create={create}
                      stringValue={modalData.current.precO_PI_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="PI"
                      create={create}
                      stringValue={modalData.current.precO_PI_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="PI"
                      create={create}
                      stringValue={modalData.current.precO_PI_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Rio de Janeiro" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="RJ"
                      create={create}
                      stringValue={modalData.current.precO_RJ_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="RJ"
                      create={create}
                      stringValue={modalData.current.precO_RJ_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="RJ"
                      create={create}
                      stringValue={modalData.current.precO_RJ_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header
                      content="Rio Grande do Norte"
                      as="h4"
                      create={create}
                      color="orange"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="RN"
                      create={create}
                      stringValue={modalData.current.precO_RN_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="RN"
                      create={create}
                      stringValue={modalData.current.precO_RN_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="RN"
                      create={create}
                      stringValue={modalData.current.precO_RN_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header
                      content="Rio Grande do Sul"
                      as="h4"
                      create={create}
                      color="orange"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="RS"
                      create={create}
                      stringValue={modalData.current.precO_RS_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="RS"
                      create={create}
                      stringValue={modalData.current.precO_RS_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="RS"
                      create={create}
                      stringValue={modalData.current.precO_RS_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Roraima" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="RO"
                      create={create}
                      stringValue={modalData.current.precO_RO_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="RO"
                      create={create}
                      stringValue={modalData.current.precO_RO_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="RO"
                      create={create}
                      stringValue={modalData.current.precO_RO_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Rondônia" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="RR"
                      create={create}
                      stringValue={modalData.current.precO_RR_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="RR"
                      create={create}
                      stringValue={modalData.current.precO_RR_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="RR"
                      create={create}
                      stringValue={modalData.current.precO_RR_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Santa Catarina" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="SC"
                      create={create}
                      stringValue={modalData.current.precO_SC_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="SC"
                      create={create}
                      stringValue={modalData.current.precO_SC_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="SC"
                      create={create}
                      stringValue={modalData.current.precO_SC_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="São Paulo" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="SP"
                      create={create}
                      stringValue={modalData.current.precO_SP_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="SP"
                      create={create}
                      stringValue={modalData.current.precO_SP_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="SP"
                      create={create}
                      stringValue={modalData.current.precO_SP_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Sergipe" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="SE"
                      create={create}
                      stringValue={modalData.current.precO_SE_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="SE"
                      create={create}
                      stringValue={modalData.current.precO_SE_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="SE"
                      create={create}
                      stringValue={modalData.current.precO_SE_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Header content="Tocantins" as="h4" color="orange" />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                  <Grid.Column>
                    <FormItemRow
                      type="STD"
                      UF="TO"
                      create={create}
                      stringValue={modalData.current.precO_TO_STD as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P2"
                      UF="TO"
                      create={create}
                      stringValue={modalData.current.precO_TO_P2 as any}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormItemRow
                      type="P3"
                      UF="TO"
                      create={create}
                      stringValue={modalData.current.precO_TO_P3 as any}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Button
                color="green"
                floated="right"
                style={{ marginTop: '35px' }}
              >
                Enviar
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="grey"
            onClick={() => {
              setOpen(false);
              setClick(false);
              setCreate(false);
              setAllModalData({} as IItem);
            }}
          >
            Fechar
          </Button>
          {modalData.current && modalData.current.id ? (
            <Button
              color="red"
              loading={isLoadingButton}
              onClick={() => handleDeleteItem(modalData.current.id)}
            >
              Excluir
            </Button>
          ) : (
            ''
          )}
        </Modal.Actions>
        <ConfirmSecondModal
          confirmation={confirmation}
          errors={errors}
          setConfirmation={handleConfirmationButton}
          customMsg="Item inserido com sucesso"
        />
      </>
    </Modal>
  );
};

export default ItemModal;
