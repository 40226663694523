import { Icon, Message } from 'semantic-ui-react'

import React from 'react'

export const AxiosMessageErrors = ({ error }) => {
	let msg = ''

	if (error === 400) {
		msg = 'O conteúdo não foi encontrado'
	}

	if (error === 404) {
		msg = 'A URL solicitada não existe ou não foi encontrada'
	}

	if (error === 503) {
		msg = 'Houve um problema de conexão'
	}

	if (error === 415) {
		msg = 'O arquivo enviado não é suportado'
	}

	if (error === 500) {
		msg = 'Ocorreu algum erro interno no servidor'
	}

	return (
		<div style={{ textAlignLast: 'center', marginBottom: '15px' }}>
			<Message negative icon attached="top" size="large">
				<Icon name="asterisk" loading />
				<Message.Content>
					<Message.Header>{msg}</Message.Header>
					{`Código ${error}`}
				</Message.Content>
				<Icon name="asterisk" loading />
			</Message>
		</div>
	)
}

export default AxiosMessageErrors
