import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Icon,
  Menu,
  Message,
  Pagination,
  PaginationProps,
  Segment,
  Table
} from 'semantic-ui-react';
import ItemsContext, {
  useIsloadingTable
} from '../../../contexts/ItemsContext';
import { exportExcel } from '../../../hooks/exportExcel';
import NoData from '../../Commom/NoData';
import { IError } from '../../Commom/_interfaces/error.interface';
import AxiosMessageErrors from '../../Errors/AxiosMessageErrors';
import ItemModal from '../../Rules/Item/ItemModal';
import ItemSearch from '../../Rules/Item/ItemSearch';
import ItemTableHeader from '../../Rules/Item/ItemTableHeader';
import ItemTableRows from '../../Rules/Item/ItemTableRows';
import { IItem } from '../../Rules/Item/_interfaces/item.interfaces';
import RulesModalUpload from '../../Rules/ModalUpload';
import RulesButtons from '../../Rules/RulesButtons';

const Item: React.FC = () => {
  const { items, itemsErrors, mutateItems } = useContext(ItemsContext);
  const [page, setPage] = useState<number | string>(1);
  const [errors, setErrors] = useState<IError | boolean>(false);
  const [modalItem, setModalItem] = useState<IItem>({} as IItem);
  const [click, setClick] = useState(false);
  const [flagItem, setFlagItem] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [create, setCreate] = useState(false);
  const [upload, setUpload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [totalItemsActive, setTotalItemsActive] = useState(0);
  const [searchResult, setSearchResult] = useState<IItem[]>([]);
  const [tableData, setTableData] = useState<IItem[]>([]);
  const [noData, setNoData] = useState(false);
  const { isLoadingTable, setIsLoadingTable } = useIsloadingTable();

  const limit = 10;

  useEffect(() => {
    const getData = async () => {
      if (errors) {
        setTimeout(() => {
          setErrors(false);
        }, 10000);
      }

      if (items && items.length === 0) {
        setNoData(true);
      } else if (items) {
        setTableData(items);

        setNoData(false);
      }
      if (itemsErrors) {
        setErrors(itemsErrors);
      }
      setIsLoadingTable(false);
    };
    getData();
  }, [errors, items, itemsErrors]);

  const handleTotalItems = (totalItemsActive: number) => {
    setTotalItemsActive(totalItemsActive);
  };

  const handleIsSearching = (isSearching: boolean) => {
    setIsSearching(isSearching);
  };

  const handlePageChange = (data: PaginationProps) => {
    if (data.activePage !== undefined) {
      setPage(data.activePage);
    }
  };

  const handleTotalPages = (totalPages: number) => {
    setTotalPages(totalPages);
  };

  const handleSearchResult = (searchResult: IItem[]) => {
    setSearchResult(searchResult);
  };

  const handleModal = (modalItem: IItem) => {
    setModalItem(modalItem);
  };

  const handleClick = (click: boolean) => {
    setClick(click);
  };

  const handleCreateClick = (create: boolean) => {
    setCreate(create);
  };

  const handleFlagItem = (flagItem: boolean) => {
    setFlagItem(flagItem);
  };

  const handleUpload = (upload: boolean) => {
    setUpload(upload);
    setFlagItem(upload);
  };

  const handleExport = async () => {
    setIsLoadingButton(true);
    try {
      await exportExcel('items/excel', 'exp_item');
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoadingButton(false);
  };

  const handleDownload = async () => {
    setIsLoadingButton(true);
    try {
      await exportExcel('items/excel/model', 'item_modelo');
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoadingButton(false);
  };

  return (
    <Segment padded="very" textAlign="center">
      {click || create ? (
        <ItemModal
          modalItem={modalItem}
          setClick={handleClick}
          click={click}
          create={create}
          setCreate={handleCreateClick}
          mutate={mutateItems}
        />
      ) : (
        ''
      )}
      {upload ? (
        <RulesModalUpload
          upload={upload}
          flagItem={flagItem}
          setFlagItem={handleFlagItem}
          setUpload={setUpload}
          mutate={mutateItems}
        />
      ) : (
        ''
      )}
      <Grid
        style={{ marginBottom: '10px', justifyContent: 'center' }}
        stackable
        textAlign="center"
        verticalAlign="middle"
        divided
      >
        <Grid.Row>
          <RulesButtons
            isLoadingButton={isLoadingButton}
            handleCreateClick={handleCreateClick}
            handleUpload={handleUpload}
            handleExport={handleExport}
            handleDownload={handleDownload}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} tablet={6}>
            <ItemSearch
              tableData={tableData}
              setIsSearching={handleIsSearching}
              handleSearchResult={handleSearchResult}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} tablet={3}>
            <Menu compact vertical>
              <Menu.Item>
                Total <b>{totalItemsActive}</b>
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {errors ? <AxiosMessageErrors error={errors} /> : ''}
      {noData ? (
        <NoData />
      ) : (
        <div style={{ overflowX: 'scroll' }}>
          <Table
            celled
            striped
            collapsing
            singleLine
            fixed
            definition
            style={{ minWidth: '92.7vw' }}
          >
            <ItemTableHeader />
            {(searchResult.length === 0 && tableData.length === 0 && !errors) ||
            isLoadingTable ? (
              <React.Fragment>
                <Table.Cell />
                <Message size="large">
                  <Icon name="sync" loading />
                  Os dados estão sendo carregados...
                </Message>
              </React.Fragment>
            ) : (
              <Table.Body>
                <ItemTableRows
                  tableData={searchResult.length > 0 ? searchResult : tableData}
                  page={page}
                  limit={limit}
                  isSearching={isSearching}
                  totalItems={tableData.length}
                  setTotalPages={handleTotalPages}
                  setTotalItemsActive={handleTotalItems}
                  setModalItem={handleModal}
                  setClick={handleClick}
                />
              </Table.Body>
            )}
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan="14">
                  <Pagination
                    defaultActivePage={1}
                    totalPages={
                      totalPages || Math.ceil(tableData.length / limit)
                    }
                    onPageChange={(e, data) => handlePageChange(data)}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      )}
    </Segment>
  );
};

export default Item;
