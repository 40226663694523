const handleCognitoErrors = (error: {
  code: string;
  message: string;
}): string => {
  let msg = '';
  if (error && error.code === 'LimitExceededException') {
    msg = 'A quantidade de tentativas excedeu o limite, aguarde alguns minutos';
  }

  if (error && error.code === 'CodeMismatchException') {
    msg = 'O código digitado não é valido';
  }

  if (error && error.code === 'NotAuthorizedException') {
    msg = 'Suas credenciais são inválidas, confira seu email ou senha';
  }

  if (error && error.message === 'User is disabled.') {
    msg = 'Seu usuário está desativado, entre em contato com o adminstrador';
  }

  return msg;
};

export default handleCognitoErrors;
