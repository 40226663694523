/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';

const tableReducer = (
  state: any,
  action: {
    type: 'CHANGE_SORT' | 'ADD_DEFAULT_VALUES';
    column: string;
    initialValue?: any[];
  }
) => {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending'
        };
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending'
      };
    case 'ADD_DEFAULT_VALUES':
      return {
        ...action.initialValue,
        data: action.initialValue,
        direction: state.direction === 'ascending' ? 'descending' : 'ascending'
      };
    default:
      throw new Error();
  }
};

export default tableReducer;
