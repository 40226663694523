import React, { useState } from 'react';
import { Grid, Search } from 'semantic-ui-react';
import { IMatch } from './_interfaces/match.interfaces';

interface Props {
  tableData: IMatch[];
  setIsSearching: (value: boolean) => void;
  handleSearchResult: (searchResult: IMatch[]) => void;
}

const MatchSearch: React.FC<Props> = ({
  tableData,
  setIsSearching,
  handleSearchResult
}) => {
  const [searchResult, setSearchResult] = useState<IMatch[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<string | undefined>('');

  const handleSearchChange = (value: string | undefined) => {
    setIsLoading(true);
    if (value === '') {
      setSearch('');
      setIsSearching(false);
      handleSearchResult([]);
      setSearchResult([]);
    } else if (value !== undefined) {
      setSearch(value);
      setSearchResult(
        tableData.filter((data) =>
          data.desc_cliente.toLowerCase().includes(value)
        )
      );
      setIsSearching(true);
      if (searchResult.length === 0) {
        handleSearchResult([]);
      } else {
        handleSearchResult(searchResult);
      }
    } else {
      setSearch('');
      setIsSearching(false);
      handleSearchResult([]);
      setSearchResult([]);
    }
    setIsLoading(false);
  };

  return (
    <Grid.Column>
      <Search
        onSearchChange={(e, data) => handleSearchChange(data.value)}
        showNoResults={false}
        loading={isLoading}
        value={search}
      />
    </Grid.Column>
  );
};

export default MatchSearch;
