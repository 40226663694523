/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from '../../utils/helpers/axios.instance';
import {
  Button,
  ButtonProps,
  Card,
  Icon,
  Popup,
  Segment
} from 'semantic-ui-react';
import dateTimeConverter from '../../utils/helpers/dateTimeConverter';
import { IIssue } from './_interfaces/issues.interface';
import IssueModal from './IssueModal';
import ConfirmModal from '../Commom/ConfirmModal';
import { IError } from '../Commom/_interfaces/error.interface';

const IssuesItems: React.FC = () => {
  const [issuesList, setIssuesList] = useState<IIssue[]>([]);
  const [modalItem, setModalItem] = useState<IIssue | null>(null);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [click, setClick] = useState(false);
  const [errors, setErrors] = useState<IError | boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (click: boolean) => {
    setClick(click);
  };

  const handleModalItem = (modalItem: IIssue) => {
    setModalItem(modalItem);
    setClick(true);
  };

  const handleConfirmation = () => {
    setConfirmation(false);
  };

  useEffect(() => {
    const getIssues = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.get('/issue/get');
        setIssuesList(data);
      } catch (error) {
        setErrors(error.response);
      }
      setIsLoading(false);
    };
    getIssues();
  }, [setIsLoading]);

  const handleApproved = async (data: ButtonProps) => {
    setLoading(true);
    try {
      await api.patch(`/issue/${data.name}/true`);
      setConfirmation(true);
    } catch (error) {
      setErrors(error.response);
    }
    setLoading(false);
  };

  return (
    <Segment
      padded="very"
      stacked
      basic
      size="large"
      loading={loading}
      // style={loading ? { height: '49vh' } : null}
    >
      {click ? <IssueModal modalItem={modalItem} setClick={handleClick} /> : ''}
      {confirmation ? (
        <ConfirmModal
          confirmation={confirmation}
          setConfirmation={handleConfirmation}
          errors={errors}
        />
      ) : (
        ''
      )}
      <Card.Group itemsPerRow={3}>
        {issuesList?.map((issue) => (
          <Card
            key={issue.id}
            color={issue.verified ? 'green' : 'red'}
            name={issue.id}
          >
            <Card.Content>
              <Card.Header>{issue.empresa.name}</Card.Header>
              <Card.Meta>{`ID Externo Atividade ${issue.pAtividade.external_id}`}</Card.Meta>
              <Card.Description>
                O cliente <strong>{issue.usuario.name}</strong> reportou uma
                inconsistência na atividade{' '}
                <strong>{issue.pAtividade.cliente_item}</strong>
                <br />
                <br />
                <Icon name="clock outline" />
                &nbsp;Aberto em{' '}
                <strong>
                  {dateTimeConverter(issue.created_at, 'DD/MM/YYYY')}
                </strong>
                <br />
                <Icon name="clock outline" />
                &nbsp;Finalizado em{' '}
                <strong>
                  {issue.verified
                    ? dateTimeConverter(issue.updated_at, 'DD/MM/YYYY')
                    : 'EM ABERTO'}
                </strong>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <div className="ui two buttons">
                <Popup
                  trigger={
                    <Button
                      basic
                      color="green"
                      disabled={issue.verified}
                      content={issue.verified ? 'Já resolvido' : 'Resolver'}
                    />
                  }
                  content={
                    <Button
                      color="green"
                      name={issue.id}
                      content="Confirmar"
                      onClick={(e, data) => handleApproved(data)}
                    />
                  }
                  on="click"
                  position="top right"
                />

                <Button
                  name={issue.id}
                  basic
                  color="blue"
                  onClick={() => handleModalItem(issue)}
                >
                  Mais informações
                </Button>
              </div>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </Segment>
  );
};

export default IssuesItems;
