/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { IDateInterval } from './_interfaces/reports.interfaces';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import baseColors from '../../assets/colors';

interface Props {
  setDateInterval: (value: IDateInterval) => void;
  setDates: (value: any) => void;
  dates: any;
  loadingButton: boolean;
  handleLoadingButton: () => void;
}

const ReportDatepicker: React.FC<Props> = ({
  setDateInterval,
  setDates,
  dates,
  loadingButton,
  handleLoadingButton
}) => {
  // const [dates, setDates] = useState<Date | Date[] | null | undefined>([]);
  const [interval, setInterval] = useState<Date[] | null>([]);

  const onChange = (data: SemanticDatepickerProps) => {
    if (data.type === 'range') {
      setDates(data.value);
    }
  };

  const handleSubmit = async () => {
    if (interval) {
      const getData = {
        startDate: interval[0].toLocaleString('en-US'),
        endDate: interval[1].toLocaleString('en-US')
      };
      setDateInterval(getData);
      handleLoadingButton();
    }
  };

  useEffect(() => {
    if (Array.isArray(dates)) {
      const aux: Date[] = [];
      aux.push(dates[0]);
      const dataFinal = new Date(dates[1]);
      dataFinal.setHours(dataFinal.getHours() + 23);
      dataFinal.setMinutes(dataFinal.getMinutes() + 59);
      dataFinal.setSeconds(dataFinal.getSeconds() + 59);
      aux.push(dataFinal);
      if (aux.length > 0) {
        setInterval(aux);
      }
    }
  }, [dates]);

  return (
    <>
      <SemanticDatepicker
        placeholder="Escolha uma data"
        format="DD/MM/YY"
        onChange={(e, data: SemanticDatepickerProps) => onChange(data)}
        locale="pt-BR"
        type="range"
        pointing="left"
        className="teste123"
        value={dates}
        clearable={false}
      />
      <br />
      <Button
        loading={loadingButton}
        content="Buscar"
        onClick={handleSubmit}
        style={{
          margin: '20px 0',
          background: baseColors.blue,
          color: 'white'
        }}
      />
    </>
  );
};

export default ReportDatepicker;
