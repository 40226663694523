import React from 'react';
import { Popup, Marker } from 'react-leaflet';
import { Divider, Header } from 'semantic-ui-react';
import mapMarker from '../../../utils/constants/mapMarker';
import formatNumbers from '../../../utils/helpers/formatNumbers';
import { IMapPoints } from '../_interfaces/reports.interfaces';

interface Props {
  mapPoints: IMapPoints[];
}

const MapMarkers: React.FC<Props> = ({ mapPoints }): JSX.Element | null => {
  return (
    <>
      {mapPoints?.map((point) => (
        <Marker
          icon={mapMarker}
          key={`point-${point.item_name}-${Math.random() * 999}`}
          position={[point.lat, point.lng]}
        >
          <Popup minWidth={200}>
            <Header as="h4">
              {point.item_name}
              <Divider />
              <Header.Subheader>
                <p>
                  Cidade: <strong>{point.city}</strong>
                </p>
                <p>
                  Total:&nbsp;
                  <strong>{formatNumbers(point.quantidade, 'decimal')}</strong>
                </p>
                <p>
                  <strong>{formatNumbers(point.total, 'currency')}</strong>
                </p>
              </Header.Subheader>
            </Header>
          </Popup>
        </Marker>
      ))}
    </>
  );
};

export default MapMarkers;
