import iconUrl from '../../assets/img/iconfinder_map-marker_299087.svg'
import L from 'leaflet'

// não implementado, encontrar um bom svg
const size = 50
const dollarMarker = L.icon({
	iconUrl,
	iconSize: [ size, size ],
	iconAnchor: [ 20, 40 ]
})

export default dollarMarker
