import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonProps,
  Form,
  Grid,
  Header,
  Icon,
  InputOnChangeData,
  Message,
  Segment
} from 'semantic-ui-react';
import CognitoNegativeMessage from '../Errors/CognitoNegativeMessage';
import { IError } from '../Commom/_interfaces/error.interface';
import baseColors from '../../assets/colors';

interface IRecoverData {
  email: string;
  verificationCode: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [recoverData, setRecoverData] = useState<IRecoverData>(
    {} as IRecoverData
  );
  const [recover, setRecover] = useState(true);
  const [errors, setErrors] = useState<IError | boolean>(false);

  const handleChange = (data: InputOnChangeData) => {
    if (data && recoverData) {
      setRecoverData({ ...recoverData, [data.name]: data.value });
    }
  };

  const handleSendCode = async (e: ButtonProps) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await Auth.forgotPassword(recoverData.email);
      setRecover(false);
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoading(false);
  };

  const handlePasswordSubmit = async (e: ButtonProps) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (recoverData.newPassword === recoverData.confirmNewPassword) {
        await Auth.forgotPasswordSubmit(
          recoverData.email,
          recoverData.verificationCode,
          recoverData.newPassword
        );
        alert(
          'Senha alterada com sucesso! Você será redirecionado para o login!'
        );
        setRecoverData({} as IRecoverData);
        history.push('/auth/entrar');
      } else {
        alert('Confirmação da senha está diferente!');
      }
    } catch (error) {
      setErrors(error.response);
    }
    setIsLoading(false);
  };

  return (
    <Grid textAlign="center" style={{ height: '70vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" style={{ color: baseColors.blue }} textAlign="center">
          Trocar senha
        </Header>
        <Form size="large" loading={isLoading}>
          {errors ? <CognitoNegativeMessage props={errors} /> : ''}
          <Segment stacked padded="very">
            <React.Fragment>
              <Form.Input
                fluid
                icon="mail"
                name="email"
                iconPosition="left"
                label="E-mail"
                type="email"
                value={recoverData.email}
                onChange={(e, data: InputOnChangeData) => handleChange(data)}
              />
              {recover ? (
                <Button
                  style={{ background: baseColors.yellow, color: 'white' }}
                  fluid
                  size="medium"
                  onClick={(e: ButtonProps) => handleSendCode(e)}
                >
                  Enviar o código
                </Button>
              ) : (
                ''
              )}
            </React.Fragment>
            {!recover ? (
              <React.Fragment>
                <Form.Input
                  fluid
                  icon="help"
                  name="verificationCode"
                  iconPosition="left"
                  label="Código"
                  type="text"
                  value={recoverData.verificationCode}
                  onChange={(e, data: InputOnChangeData) => handleChange(data)}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  name="newPassword"
                  iconPosition="left"
                  label="Nova senha"
                  type="password"
                  value={recoverData.newPassword}
                  onChange={(e, data: InputOnChangeData) => handleChange(data)}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  name="confirmNewPassword"
                  iconPosition="left"
                  label="Confirme a nova senha"
                  type="password"
                  value={recoverData.confirmNewPassword}
                  onChange={(e, data: InputOnChangeData) => handleChange(data)}
                />
                <Button
                  style={{ background: baseColors.blue, color: 'white' }}
                  fluid
                  size="medium"
                  onClick={(e: ButtonProps) => handlePasswordSubmit(e)}
                >
                  Trocar a senha
                </Button>
              </React.Fragment>
            ) : (
              ''
            )}
          </Segment>
        </Form>
        {!recover && !errors ? (
          <Message attached="bottom" warning>
            <Icon name="help" />
            Não recebeu o código?&nbsp;
            <a href="#" onClick={(e: ButtonProps) => handleSendCode(e)}>
              Tente novamente
            </a>
            &nbsp; inserindo seu e-mail
          </Message>
        ) : (
          ''
        )}
      </Grid.Column>
    </Grid>
  );
};

export default ForgotPassword;
