import React, { useMemo, useState, memo } from 'react';
import { Form, Dropdown, DropdownProps } from 'semantic-ui-react';
import { useModalData } from '../../../contexts/ItemsContext';

interface Props {
  stringValue: string;
  create: boolean;
  setEnable: (enabled: string) => void;
}

const FormItemRow: React.FC<Props> = ({ create, stringValue, setEnable }) => {
  const [stringState, setStringState] = useState('');
  const { setModalData } = useModalData();

  useMemo(() => {
    if (create !== true) {
      setStringState(stringValue);
    } else {
      setStringState('');
    }
  }, [create, stringValue]);

  return (
    <>
      <Dropdown
        fluid
        selection
        options={[
          {
            key: 'S',
            value: 'S',
            text: 'SIM'
          },
          {
            key: 'N',
            value: 'N',
            text: 'NÃO'
          }
        ]}
        name="enabled"
        value={stringState}
        onChange={(e, data: DropdownProps) => {
          setStringState(data.value as string);
          setModalData('enabled', data.value as string);
          setEnable(data.value as string);
        }}
      />
    </>
  );
};

export default memo(FormItemRow);
