/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Pagination, PaginationProps, Table } from 'semantic-ui-react';
import ActivityTableHeader from './ActivityTableHeader';
import ActivityTableRows from './ActivityTableRows';
import ActivityModal from '../ActivityModal';
import { IError } from '../../Commom/_interfaces/error.interface';
import { IActivity } from '../_interfaces/activity.interfaces';

interface Props {
  errors: IError | boolean;
  limit: any;
  totalItems: number;
  tableData: IActivity[];
  page: number;
  setChangePage: (value: any) => void;
  order: string;
  descending: boolean;
  setChangeOrder: (order: any, descending: any) => void;
}

const ActivityTable: React.FC<Props> = ({
  limit,
  tableData,
  totalItems,
  page,
  setChangePage,
  order,
  descending,
  setChangeOrder
}) => {
  const [modalItem, setModalItem] = useState<IActivity>({} as IActivity);
  const [click, setClick] = useState(false);

  const handleModal = (modalItem: IActivity) => {
    setModalItem(modalItem);
  };

  const handlePageChange = (data: PaginationProps) => {
    if (data.activePage) {
      setChangePage(data.activePage);
    }
  };

  const handleClick = (click: boolean) => {
    setClick(click);
  };

  return (
    <div style={{ overflowX: 'scroll' }}>
      {click ? (
        <ActivityModal modalItem={modalItem} setClick={handleClick} />
      ) : (
        ''
      )}
      <Table
        celled
        striped
        collapsing
        fixed
        stackable
        sortable
        style={{ minWidth: '100vw' }}
      >
        <ActivityTableHeader
          setChangeOrder={setChangeOrder}
          order={order}
          descending={descending}
        />
        <Table.Body>
          <ActivityTableRows
            tableData={tableData}
            setModal={handleModal}
            setClick={handleClick}
          />
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell colSpan="14">
              <Pagination
                defaultActivePage={page}
                totalPages={Math.ceil(totalItems / limit)}
                onPageChange={(e, data: PaginationProps) =>
                  handlePageChange(data)
                }
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

export default ActivityTable;
