import { IGeoFormErrors } from '../../components/Commom/_interfaces/error.interface';
import { IGeo } from '../../components/Rules/_interfaces/geo.interfaces';

export function validateGeoModal(
  modal: IGeo
): { errors: IGeoFormErrors | null; validForm: boolean } {
  const errors: IGeoFormErrors = {} as IGeoFormErrors;
  let validForm = true;

  if (!modal.comprador_estado) {
    errors.comprador_estado = 'É necessário escolher um estado';
    validForm = false;
  }

  if (!modal.multiplicador_preco) {
    errors.multiplicador_preco = 'É necessário inserir um preço';
    validForm = false;
  }

  if (!modal.cliente_fatmin) {
    errors.cliente_fatmin = 'É necessário inserir um faturamento mínimo';
    validForm = false;
  }

  if (!modal.cliente_condpagto) {
    errors.cliente_condpagto = 'É necessário inserir uma condição de pagamento';
    validForm = false;
  }

  if (!modal.enabled) {
    errors.enabled = 'É necessário escolher uma opção';
    validForm = false;
  }

  if (!modal.cliente_frete) {
    errors.cliente_frete = 'É necessário inserir um frete';
    validForm = false;
  }

  return { errors, validForm };
}
