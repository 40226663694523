import React from 'react';
import { Table } from 'semantic-ui-react';

const ItemTableHeader: React.FC = () => {
  return (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell>SKU Cliente</Table.HeaderCell>
        <Table.HeaderCell>Descrição cliente</Table.HeaderCell>
        <Table.HeaderCell>Fabricante</Table.HeaderCell>
        <Table.HeaderCell>Ativo</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export default ItemTableHeader;
