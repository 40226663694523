import _ from 'lodash';
import { IMapPoints } from '../../components/Reports/_interfaces/reports.interfaces';

const mapsGroup = (data: IMapPoints[]): IMapPoints[] => {
  const groupByItem = _.chain(data).groupBy('item_name').value();
  const cities = Object.keys(groupByItem).map((el) =>
    _.chain(groupByItem[el]).groupBy('city').value()
  );

  const result: IMapPoints[] = [];
  cities.map((city) =>
    Object.keys(city).map((c) => {
      const total = city[c].map((el) => el.total).reduce((a, b) => a + b);
      const quantidade = city[c]
        .map((el) => el.quantidade)
        .reduce((a, b) => a + b);
      result.push({
        city: city[c][0].city,
        total: parseFloat(total.toFixed(2)),
        item_name: city[c][0].item_name,
        cliente_sku: city[c][0].cliente_sku,
        lat: city[c][0].lat,
        lng: city[c][0].lng,
        uf: city[c][0].uf,
        quantidade
      });

      return result;
    })
  );

  return result;
};

export default mapsGroup;
