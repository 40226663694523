import React from 'react';
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';

import AuthenticatedRoute from './components/Commom/AuthenticatedRoute';
import UnauthenticatedRoute from './components/Commom/UnauthenticatedRoute';

import Register from './components/Views/Register';
import Login from './components/Views/Login';
import ForgotPassword from './components/Views/ForgotPassword';
import ConfirmSignUp from './components/Views/ConfirmSignUp';
import ChangePassword from './components/Views/ChangePassword';

import AppHeader from './components/Commom/AppHeader';
import AppFooter from './components/Commom/AppFooter';

import Item from './components/Views/Rules/Item';
import Geo from './components/Views/Rules/Geo';
import CNPJ from './components/Views/Rules/CNPJ';
import Advanced from './components/Views/Rules/Advanced';

import Reports from './components/Views/Reports';
import Activities from './components/Views/Activities';
import Match from './components/Views/Match';
import Issues from './components/Views/Issues';
import NotFound from './components/Errors/NotFound';
import { MatchProvider } from './contexts/MatchContext';
import { ItemsProvider } from './contexts/ItemsContext';
import { CatalogProvider } from './contexts/CatalogContext';

const Routes: React.FC = () => {
  return (
    <HashRouter>
      <ItemsProvider>
        <AppHeader />
      </ItemsProvider>
      <Switch>
        <AuthenticatedRoute exact path="/">
          <Redirect to="/user/relatorios" />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/user/atividades">
          <Activities />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/user/relatorios">
          <Reports />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/user/regras/item">
          <ItemsProvider>
            <Item />
          </ItemsProvider>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/user/regras/geo">
          <Geo />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/user/regras/cnpj">
          <CNPJ />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/user/regras/avancadas">
          <Advanced />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/user/match">
          <MatchProvider>
            <ItemsProvider>
              <CatalogProvider>
                <Match />
              </CatalogProvider>
            </ItemsProvider>
          </MatchProvider>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/auth/registrar">
          <Register />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/auth/erros">
          <Issues />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/auth/trocar">
          <ChangePassword />
        </AuthenticatedRoute>
        <UnauthenticatedRoute path="/auth/entrar">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute path="/auth/recuperar">
          <ForgotPassword />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute path="/auth/confirmar">
          <ConfirmSignUp />
        </UnauthenticatedRoute>
        <Route>
          <NotFound />
        </Route>
      </Switch>
      <AppFooter />
    </HashRouter>
  );
};

export default Routes;
