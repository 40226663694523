import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Icon,
  Menu,
  Message,
  Pagination,
  PaginationProps,
  Segment,
  Table
} from 'semantic-ui-react';

import MatchContext from '../../contexts/MatchContext';
import NoData from '../Commom/NoData';
import MatchDropdowns from '../Match/MatchDropdowns';
import MatchModal from '../Match/MatchModal';
import MatchSearch from '../Match/MatchSearch';
import MatchTableRows from '../Match/MatchTableRows';
import { IMatch } from '../Match/_interfaces/match.interfaces';
import CatalogContex from '../../contexts/CatalogContext';
import MatchTableHeader from '../Match/MatchTableHeader';

const Match: React.FC = () => {
  const { matches, matchesError, mutate: mutateMatch } = useContext(
    MatchContext
  );
  const {
    catalogB2S,
    catalogError,
    mutateCatalog,
    mutateRawMatch
  } = useContext(CatalogContex);
  const limit = 10;
  const [page, setPage] = useState<number | string>(1);
  const [modalItem, setModalItem] = useState<IMatch | null>(null);
  const [click, setClick] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [totalItemsActive, setTotalItemsActive] = useState(0);
  const [searchResult, setSearchResult] = useState<IMatch[]>([]);
  const [tableData, setTableData] = useState<IMatch[]>([]);

  const handleTotalItems = (totalItemsActive: number) => {
    setTotalItemsActive(totalItemsActive);
  };

  const handleIsSearching = (isSearching: boolean) => {
    setIsSearching(isSearching);
  };

  const handlePageChange = (data: PaginationProps) => {
    if (data.activePage !== undefined) {
      setPage(data.activePage);
    }
  };

  const handleTotalPages = (totalPages: number) => {
    setTotalPages(totalPages);
  };

  const handleSearchResult = (searchResult: IMatch[]) => {
    setSearchResult(searchResult);
  };

  const handleModal = (modalItem: IMatch) => {
    setModalItem(modalItem);
  };

  const handleClick = (click: boolean) => {
    setClick(click);
  };

  useEffect(() => {
    if (matches) {
      setTableData(matches);
    }
  }, [matches]);

  return (
    <React.Fragment>
      <MatchDropdowns mutateMatch={mutateMatch} />
      <Segment padded="very">
        {click ? (
          <MatchModal
            modalItem={modalItem}
            setClick={handleClick}
            click={click}
            mutateMatch={mutateMatch}
            catalogB2S={catalogB2S}
            catalogError={catalogError}
            mutateCatalog={mutateCatalog}
            mutateRawMatch={mutateRawMatch}
          />
        ) : (
          ''
        )}
        <Grid
          style={{ marginBottom: '10px', justifyContent: 'center' }}
          stackable
          textAlign="center"
          verticalAlign="middle"
        >
          <Grid.Row>
            <Grid.Column widescreen={2} largeScreen={4} tablet={6}>
              <MatchSearch
                tableData={tableData}
                setIsSearching={handleIsSearching}
                handleSearchResult={handleSearchResult}
              />
            </Grid.Column>
            <Grid.Column widescreen={2} largeScreen={2} tablet={3}>
              <Menu compact vertical>
                <Menu.Item>
                  Total <b>{totalItemsActive}</b>
                </Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {matches && matches.length === 0 ? (
          <NoData
            customMainMsg="Não foi encontrada nenhuma associação, tente inserir uma nova combinação"
            customSubMsg="Caso já tenha feito associações, tente atualizar a página"
          />
        ) : (
          <div style={{ overflowX: 'scroll' }}>
            <Segment style={{ textAlign: '-webkit-center' }} basic>
              <Table celled striped collapsing singleLine fixed definition>
                <MatchTableHeader />
                {searchResult.length === 0 &&
                tableData.length === 0 &&
                !matchesError ? (
                  <React.Fragment>
                    <Table.Cell />
                    <Message size="large">
                      <Icon name="sync" loading />
                      Os dados estão sendo carregados...
                    </Message>
                  </React.Fragment>
                ) : (
                  <Table.Body>
                    <MatchTableRows
                      tableData={
                        searchResult.length > 0 ? searchResult : tableData
                      }
                      setTotalPages={handleTotalPages}
                      setTotalItemsActive={handleTotalItems}
                      setModalItem={handleModal}
                      setClick={handleClick}
                      page={page}
                      limit={limit}
                      isSearching={isSearching}
                      totalItems={tableData ? tableData.length : 0}
                    />
                  </Table.Body>
                )}
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell colSpan="14">
                      <Pagination
                        defaultActivePage={1}
                        totalPages={
                          totalPages ||
                          (tableData ? Math.ceil(tableData.length / limit) : 0)
                        }
                        onPageChange={(e, data) => handlePageChange(data)}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Segment>
          </div>
        )}
      </Segment>
    </React.Fragment>
  );
};

export default Match;
