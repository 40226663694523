/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

const pieCalc = (
  pieData: Array<Array<string | number>>,
  pieHeader: string[],
  top8: Array<Array<string | number>>,
  namesArray: any
): Array<Array<string | number>> => {
  const newData = [];
  if (pieData.length > 0 && top8.length > 0 && namesArray) {
    const original = pieData.find((pie) => {
      let aux;
      if (typeof namesArray === 'string') {
        aux = pie[0] === namesArray.substring(0, 55);
      }
      return aux;
    });
    if (original) {
      const idxTop = top8.findIndex((top) => top[0] === original[0]);
      for (let i = 0; i < top8[idxTop].length; i++) {
        const idx = original.findIndex((ori) => ori === top8[idxTop][i]);
        const aux = [];
        if (idx !== 0 && idx !== undefined) {
          const state = pieHeader[idx];
          aux.push(state, top8[idxTop][i]);
          newData.push(aux);
        }
      }
    }
  }
  return newData;
};

export default pieCalc;
