import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Dropdown, Icon, Label, Menu, Image } from 'semantic-ui-react';
import baseColors from '../../assets/colors';
import AppContext from '../../contexts/AppContext';
import ItemsContext from '../../contexts/ItemsContext';
import { removeToken } from '../../utils/service/auth';

const AppHeader: React.FC = () => {
  const history = useHistory();
  const { userHasAuthenticated, userData, isAuthenticated } = useContext(
    AppContext
  );

  const { notAssociatedCount } = useContext(ItemsContext);
  const [name, setName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const logout = async () => {
    await Auth.signOut();
    removeToken();
    userHasAuthenticated(false);
    history.push('/auth/entrar');
  };

  useEffect(() => {
    if (userData && userData.data) {
      setName(userData.data.name);
      if (userData.data.role === 'admin') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [userData]);

  return (
    <Menu
      borderless
      color="red"
      size="large"
      stackable
      style={{ background: baseColors.blue }}
      inverted
    >
      <Menu.Item>
        <Image
          src="/b2bMax_white.png"
          size="small"
          as={NavLink}
          exact
          to="/user/relatorios"
        />
      </Menu.Item>
      {!isAuthenticated ? (
        <></>
      ) : (
        <React.Fragment>
          <Menu.Menu position="left">
            <Menu.Item as={NavLink} exact to="/user/relatorios" name="report">
              Relatórios
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              exact
              to="/user/atividades"
              name="activities"
            >
              Atividades
            </Menu.Item>
            <Dropdown item text="Regras">
              <Dropdown.Menu>
                <Dropdown.Item
                  name="items"
                  as={NavLink}
                  exact
                  to="/user/regras/item"
                >
                  Item
                </Dropdown.Item>
                <Dropdown.Item
                  name="geo"
                  as={NavLink}
                  exact
                  to="/user/regras/geo"
                >
                  Geo
                </Dropdown.Item>
                <Dropdown.Item
                  name="cnpj"
                  as={NavLink}
                  exact
                  to="/user/regras/cnpj"
                >
                  CNPJ
                </Dropdown.Item>
                <Dropdown.Item
                  name="advanced"
                  as={NavLink}
                  exact
                  to="/user/regras/avancadas"
                >
                  Avançadas
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Menu.Item as={NavLink} exact to="/user/match" name="match">
              Match &nbsp;
              {notAssociatedCount && notAssociatedCount !== 0 ? (
                <Label circular color="red">
                  {notAssociatedCount}
                </Label>
              ) : (
                <Icon name="check" color="green" />
              )}
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            {/* isAdmin && (
              <>
                <Dropdown item text="Ações">
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={NavLink}
                      exact
                      to="/auth/registrar"
                      name="register"
                    >
                      Criar conta
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      as={NavLink}
                      exact
                      to="/auth/erros"
                      name="issues"
                    >
                      Registro de erros
                    </Dropdown.Item> *\/}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) */}
            <Dropdown item text={`Olá ${name || ''}`}>
              <Dropdown.Menu>
                <Dropdown.Item
                  name="changePassword"
                  as={NavLink}
                  exact
                  to="/auth/trocar"
                >
                  Trocar senha
                </Dropdown.Item>
                <Dropdown.Item name="logout" onClick={logout}>
                  Sair
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </React.Fragment>
      )}
    </Menu>
  );
};

export default AppHeader;
