export const colors = {
  green: {
    rgb: '#5CA65C',
    hsv: 120
  },
  blue: {
    rgb: '#5C81A6',
    hsv: 210
  },
  darkerBlue: {
    rgb: '#5B67A5',
    hsv: 230
  },
  pink: {
    rgb: '#844966',
    hsv: 330
  }
}

const toolboxCategories = [
  {
    name: 'Lógica',
    colour: colors.blue.rgb,
    blocks: [
      {
        type: 'controls_if'
      },
      {
        type: 'logic_compare'
      },
      {
        type: 'logic_operation'
      },
      {
        type: 'logic_boolean'
      },
      {
        type: 'logic_negate'
      }
    ]
  },
  {
    name: 'Texto',
    colour: colors.green.rgb,
    blocks: [
      {
        type: 'text'
      },
      {
        type: 'text_print'
      }
    ]
  },
  {
    name: 'Matemática',
    colour: colors.darkerBlue.rgb,
    blocks: [
      {
        type: 'math_round'
      },
      {
        type: 'math_number'
      },
      {
        type: 'math_arithmetic'
      },
      {
        type: 'math_constrain'
      }
    ]
  },
  {
    name: 'Variáveis',
    colour: colors.pink.rgb,
    blocks: [
      {
        type: 'set_tag_1'
      },
      {
        type: 'get_tag_1'
      },
      {
        type: 'set_tag_2'
      },
      {
        type: 'get_tag_2'
      },
      {
        type: 'set_tag_3'
      },
      {
        type: 'get_tag_3'
      },
      {
        type: 'set_tag_4'
      },
      {
        type: 'get_tag_4'
      },
      {
        type: 'set_tag_5'
      },
      {
        type: 'get_tag_5'
      },
      {
        type: 'set_comprador_cnpj'
      },
      {
        type: 'get_comprador_cnpj'
      },
      {
        type: 'set_comprador_cidade'
      },
      {
        type: 'get_comprador_cidade'
      },
      {
        type: 'set_comprador_estado'
      },
      {
        type: 'get_comprador_estado'
      },
      {
        type: 'set_quantidade'
      },
      {
        type: 'get_quantidade'
      },
      {
        type: 'set_cliente_partnumber'
      },
      {
        type: 'get_cliente_partnumber'
      },
      {
        type: 'set_cliente_preco'
      },
      {
        type: 'get_cliente_preco'
      },
      {
        type: 'set_multiplicador_preco'
      },
      {
        type: 'get_multiplicador_preco'
      },
      {
        type: 'set_enabled'
      },
      {
        type: 'get_enabled'
      },
      {
        type: 'set_cliente_fatmin'
      },
      {
        type: 'get_cliente_fatmin'
      },
      {
        type: 'set_cliente_frete'
      },
      {
        type: 'get_cliente_frete'
      },
      {
        type: 'set_cliente_condpagto'
      },
      {
        type: 'get_cliente_condpagto'
      }
    ]
  }
];

export default toolboxCategories
