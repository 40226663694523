import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  InputOnChangeData,
  Message,
  Segment
} from 'semantic-ui-react';
import baseColors from '../../assets/colors';
import AppContext from '../../contexts/AppContext';
import { IError } from '../Commom/_interfaces/error.interface';
import AxiosErrors from '../Errors/AxiosErrors';
import RequiredPasswordChange from './RequiredPasswordChange';

export interface IUserLogin {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const {
    userHasAuthenticated,
    isAuthenticated,
    errorsAppContext,
    onLoad
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState<IUserLogin>({
    email: '',
    password: ''
  });
  const [changePassword, setChangePassword] = useState(false);
  const [tryedLogin, setTryedLogin] = useState(false);
  const [errors, setErrors] = useState<IError | boolean>(false);

  const [credentials, setCredentials] = useState<IUserLogin>({
    email: '',
    password: ''
  });

  const handleNewPassword = (changePassword: boolean) => [
    setChangePassword(changePassword)
  ];

  const handleChange = (data: InputOnChangeData) => {
    if (data.name) {
      setCredentials({ ...credentials, [data.name]: data.value });
    }
  };

  const authenticated = useMemo(() => isAuthenticated, [isAuthenticated]);
  const errorsApp = useMemo(() => errorsAppContext, [errorsAppContext]);

  useEffect(() => {
    if (authenticated) {
      history.push('/user/relatorios');
      setIsLoading(false);
    }
    if (errorsApp) {
      setErrors(errorsApp);
      setIsLoading(false);
    }
  }, [authenticated, errorsApp, history]);

  const handleSubmit = async () => {
    setTryedLogin(true);
    setIsLoading(true);
    try {
      const user = await Auth.signIn(credentials.email, credentials.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(user);
        setChangePassword(true);
        setSuccess(true);
      }
      setTryedLogin(false);
      onLoad();
    } catch (err) {
      userHasAuthenticated(false);
      setErrors(err);
    }
    setIsLoading(false);
  };

  function mainLogin() {
    return (
      <Segment basic>
        <Grid
          textAlign="center"
          style={{ height: '60ßvh' }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header
              as="h2"
              textAlign="center"
              style={{ color: baseColors.blue }}
            >
              Seja bem vindo!
              <Header.Subheader>Para começar faça seu login</Header.Subheader>
            </Header>
            {success ? (
              <Message
                content="Usuário criado com sucesso!"
                success
                attached="top"
              />
            ) : (
              ''
            )}
            {tryedLogin && errors && <AxiosErrors error={errors} />}
            <Form size="large" loading={isLoading}>
              <Segment stacked padded="very">
                <>
                  <Form.Input
                    fluid
                    icon="user"
                    name="email"
                    iconPosition="left"
                    label="E-mail"
                    type="email"
                    value={credentials.email}
                    onChange={(e, data: InputOnChangeData) =>
                      handleChange(data)
                    }
                  />
                  <Form.Input
                    fluid
                    icon="lock"
                    name="password"
                    iconPosition="left"
                    label="Senha"
                    type="password"
                    value={credentials.password}
                    onChange={(e, data: InputOnChangeData) =>
                      handleChange(data)
                    }
                  />
                  <Button
                    style={{
                      background: baseColors.blue,
                      color: 'white',
                      marginBottom: '25px'
                    }}
                    fluid
                    size="large"
                    onClick={handleSubmit}
                  >
                    Entrar
                  </Button>
                  <Divider horizontal>OU</Divider>
                  <Button
                    style={{
                      marginTop: '15px',
                      background: baseColors.yellow,
                      color: 'white'
                    }}
                    onClick={() => history.push('/auth/recuperar')}
                  >
                    Esqueci minha senha{' '}
                  </Button>
                </>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  return changePassword ? (
    <RequiredPasswordChange user={user} setChangePassword={handleNewPassword} />
  ) : (
    mainLogin()
  );
};

export default Login;
