import React from 'react';
import { Table } from 'semantic-ui-react';
import formatNumbers from '../../../utils/helpers/formatNumbers';
import { IMapPoints } from '../_interfaces/reports.interfaces';

// import SearchNotFound from '../../Errors/SearchNotFound'

interface Props {
  mapPoints: IMapPoints[];
  page: number | string;
  limit: number;
  isSearching: boolean;
  totalItems: number;
  setTotalItemsActive: (totalItemsActive: number) => void;
  setTotalPages: (totalPages: number) => void;
}

const ReportTableRows: React.FC<Props> = ({
  mapPoints,
  page,
  limit,
  isSearching,
  totalItems,
  setTotalItemsActive,
  setTotalPages
}): JSX.Element | null => {
  let result;
  let start = 0;
  if (typeof page === 'number') {
    start = page === 1 ? 0 : (page - 1) * limit;
  } else if (typeof page === 'string') {
    start = page === '1' ? 0 : (parseInt(page) - 1) * limit;
  }

  const tableRows = (array: IMapPoints[], slice: string) => {
    if (array.length === 0) {
      return null;
    }

    const table = mapPoints.map((point) => (
      <Table.Row
        key={`${point.city} - ${point.uf} - ${point.item_name} - ${
          Math.random() * 999
        }`}
      >
        <Table.Cell width={2}>{point.city}</Table.Cell>
        <Table.Cell width={1}>{point.uf}</Table.Cell>
        <Table.Cell width={7}>{point.item_name}</Table.Cell>
        <Table.Cell width={2}>
          {formatNumbers(point.quantidade, 'decimal')}
        </Table.Cell>
        <Table.Cell width={2}>
          {formatNumbers(point.total, 'currency')}
        </Table.Cell>
      </Table.Row>
    ));

    setTotalItemsActive(isSearching ? array.length : mapPoints.length);
    if (slice === 'Y') {
      setTotalPages(
        Math.ceil(table.length / limit) - 1 !== 0
          ? Math.floor(table.length / limit) + 1
          : 1
      );
      return table.slice(start, start + limit);
    } else {
      return table;
    }
  };

  if (isSearching) {
    if (mapPoints.length === totalItems) {
      setTotalItemsActive(0);
      setTotalPages(1);
      // return SearchNotFound
    } else if (mapPoints.length !== 0) {
      result = tableRows(mapPoints, 'Y');
    }
  } else if (!isSearching) {
    if (mapPoints.length > 0) {
      result = tableRows(mapPoints, 'Y');
    }
  }

  if (!result) {
    return null;
  }

  return <React.Fragment>{result}</React.Fragment>;
};

export default ReportTableRows;
