import React from 'react';
import { Confirm } from 'semantic-ui-react';
import { IError } from './_interfaces/error.interface';

interface Props {
  confirmation: boolean;
  setConfirmation: () => void;
  errors?: boolean | IError;
  customMsg?: string;
}

const ConfirmModal: React.FC<Props> = ({
  setConfirmation,
  confirmation,
  errors,
  customMsg
}) => {
  return (
    <Confirm
      open={confirmation}
      header={!errors ? `Sucesso!` : `Ocorreu um erro`}
      content={
        errors
          ? `Ao tentar salvar sua requisição ocorreu algum erro`
          : customMsg ||
            `Sua solicitação foi gerada com sucesso! Os dados da tabela serão atualizados em alguns instantes. Dependendo da quantidade adicionada de uma vez, pode demorar um pouco mais`
      }
      onConfirm={setConfirmation}
    />
  );
};

export default ConfirmModal;
