/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Icon,
  Menu,
  Message,
  Pagination,
  PaginationProps,
  Segment,
  Table
} from 'semantic-ui-react';
import { exportExcel } from '../../../hooks/exportExcel';
import { revalidateOptions, useFetch } from '../../../hooks/useFetch';
import NoData from '../../Commom/NoData';
import { IError } from '../../Commom/_interfaces/error.interface';
import AxiosMessageErrors from '../../Errors/AxiosMessageErrors';
import GeoModal from '../../Rules/Geo/GeoModal';
import GeoTableHeader from '../../Rules/Geo/GeoTableHeader';
import GeoTableRows from '../../Rules/Geo/GeoTableRows';
import RulesModalUpload from '../../Rules/ModalUpload';
import { IGeo } from '../../Rules/_interfaces/geo.interfaces';

const Geo: React.FC = () => {
  const { data, error, mutate: mutateGeo } = useFetch<IGeo[]>(
    'geo/get',
    revalidateOptions
  );
  const limit = 10;
  const [flagGeo, setFlagGeo] = useState(false);
  const [page, setPage] = useState<string | number>(1);
  const [errors, setErrors] = useState<IError | boolean>(false);
  const [modalItem, setModalItem] = useState<IGeo | null>(null);
  const [click, setClick] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [create, setCreate] = useState(false);
  const [upload, setUpload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [totalItemsActive, setTotalItemsActive] = useState(0);
  const [searchResult, setSearchResult] = useState<IGeo[]>([]);
  const [tableData, setTableData] = useState<IGeo[]>([]);
  const [noData, setNoData] = useState(false);
  const [takenStates, setTakenStates] = useState<string[]>([]);

  useEffect(() => {
    const getData = async () => {
      if (errors) {
        setTimeout(() => {
          setErrors(false);
        }, 10000);
      }
      if (data && data.length === 0) {
        setNoData(true);
      } else if (data) {
        setTableData(data);
        const aux: string[] = [];
        data.forEach((el) => aux.push(el.comprador_estado));
        setTakenStates(aux);
        setNoData(false);
      }
      if (error) {
        setErrors(error);
      }
    };
    getData();
  }, [errors, data, error]);

  const handleFlagGeo = (flagGeo: boolean) => {
    setFlagGeo(flagGeo);
  };

  const handleTotalItems = (totalItemsActive: number) => {
    setTotalItemsActive(totalItemsActive);
  };

  const handleIsSearching = (isSearching: boolean) => {
    setIsSearching(isSearching);
  };

  const handlePageChange = (data: PaginationProps) => {
    if (data.activePage) {
      setPage(data.activePage);
    }
  };

  const handleTotalPages = (totalPages: number) => {
    setTotalPages(totalPages);
  };

  const handleSearchResult = (searchResult: IGeo[]) => {
    setSearchResult(searchResult);
  };

  const handleModal = (modalItem: IGeo) => {
    setModalItem(modalItem);
  };

  const handleClick = (click: boolean) => {
    setClick(click);
  };

  const handleCreateClick = (create: boolean) => {
    setCreate(create);
  };

  const handleUpload = (uploadState: boolean) => {
    setUpload(uploadState);
    setFlagGeo(uploadState);
  };

  const handleExport = async () => {
    setIsLoadingButton(true);
    try {
      await exportExcel('geo/excel', 'exp_geo');
    } catch (error) {
      setErrors(error);
    }
    setIsLoadingButton(false);
  };

  const handleDownload = async () => {
    setIsLoadingButton(true);
    try {
      await exportExcel('geo/excel/model', 'geo_modelo');
    } catch (error) {
      setErrors(error);
    }
    setIsLoadingButton(false);
  };

  return (
    <Segment padded="very" textAlign="center">
      {click || create ? (
        <GeoModal
          takenStates={takenStates}
          modalItem={modalItem}
          setClick={handleClick}
          click={click}
          create={create}
          setCreate={handleCreateClick}
          mutate={mutateGeo}
        />
      ) : (
        ''
      )}
      {upload ? (
        <RulesModalUpload
          upload={upload}
          setUpload={setUpload}
          setFlagGeo={handleFlagGeo}
          flagGeo={flagGeo}
          mutate={mutateGeo}
        />
      ) : (
        ''
      )}
      <Grid
        style={{ marginBottom: '10px', justifyContent: 'center' }}
        stackable
        textAlign="center"
        verticalAlign="middle"
      >
        <Grid.Row>
          <Grid.Column largeScreen={2} tablet={4} mobile={1}>
            <Button
              loading={isLoadingButton}
              content="Exportar"
              icon="file alternate outline"
              labelPosition="right"
              color="youtube"
              onClick={handleExport}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} tablet={3}>
            <Menu fluid vertical>
              <Menu.Item>
                Total <b>{totalItemsActive}</b>
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* <div style={{ textAlign: '-webkit-center' }}> */}
      <div>
        {errors ? <AxiosMessageErrors error={errors} /> : ''}
        {noData ? (
          <NoData />
        ) : (
          <div style={{ overflowX: 'scroll' }}>
            <Table
              celled
              striped
              collapsing
              fixed
              stackable
              sortable
              style={{ minWidth: '100%' }}
            >
              <GeoTableHeader />
              {searchResult.length === 0 &&
              tableData.length === 0 &&
              !errors ? (
                <>
                  <Table.Cell />
                  <Message size="large">
                    <Icon name="sync" loading />
                    Os dados estão sendo carregados...
                  </Message>
                </>
              ) : (
                <Table.Body>
                  <GeoTableRows
                    tableData={
                      searchResult.length > 0 ? searchResult : tableData
                    }
                    page={page}
                    limit={limit}
                    isSearching={isSearching}
                    totalItems={tableData.length}
                    setTotalPages={handleTotalPages}
                    setTotalItemsActive={handleTotalItems}
                    setModalItem={handleModal}
                    setClick={handleClick}
                  />
                </Table.Body>
              )}
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell colSpan="14">
                    <Pagination
                      defaultActivePage={1}
                      totalPages={
                        totalPages || Math.ceil(tableData.length / limit)
                      }
                      onPageChange={(e, data: PaginationProps) =>
                        handlePageChange(data)
                      }
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
        )}
      </div>
    </Segment>
  );
};

export default Geo;
