import React from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import cnpjFormatter from '../../../utils/helpers/cnpjFormatter';
import { ICnpj } from '../_interfaces/cnpj.interfaces';

interface Props {
  tableData: ICnpj[];
  page: number | string;
  limit: number;
  isSearching: boolean;
  totalItems: number;
  setTotalPages: (value: number) => void;
  setTotalItemsActive: (value: number) => void;
  setModalItem: (value: ICnpj) => void;
  setClick: (value: boolean) => void;
}

const CnpjTableRows: React.FC<Props> = ({
  tableData,
  page,
  limit,
  setTotalItemsActive,
  setTotalPages,
  isSearching,
  totalItems,
  setModalItem,
  setClick
}): JSX.Element | null => {
  let result;
  let start = 0;
  if (typeof page === 'number') {
    start = page === 1 ? 0 : (page - 1) * limit;
  } else if (typeof page === 'string') {
    start = page === '1' ? 0 : (parseInt(page) - 1) * limit;
  }

  const handleClick = (item: ICnpj) => {
    setModalItem(item);
    setClick(true);
  };

  const createTableRows = (array: ICnpj[], slice: string) => {
    if (array.length === 0) {
      return null;
    }

    const table = array.map((arr) => (
      <Table.Row key={arr.id}>
        <Table.Cell>
          <Button icon="search" onClick={() => handleClick(arr)} />
        </Table.Cell>
        <Table.Cell>
          {arr.comprador_cnpj ? cnpjFormatter(arr.comprador_cnpj) : ''}
        </Table.Cell>
        <Table.Cell>{arr.multiplicador_preco}</Table.Cell>
        <Table.Cell>
          {arr.enabled === 'S' ? (
            <Icon name="checkmark" color="green" />
          ) : (
            <Icon name="dont" color="red" />
          )}
        </Table.Cell>
        <Table.Cell>{arr.cliente_frete}</Table.Cell>
        <Table.Cell>{arr.cliente_fatmin}</Table.Cell>
        <Table.Cell>{arr.cliente_condpagto}</Table.Cell>
      </Table.Row>
    ));
    setTotalItemsActive(isSearching ? array.length : tableData.length);
    if (slice === 'Y') {
      setTotalPages(
        Math.ceil(table.length / limit) - 1 !== 0
          ? Math.floor(table.length / limit) + 1
          : 1
      );
      return table.slice(start, start + limit);
    } else {
      return table;
    }
  };

  if (isSearching) {
    if (tableData.length === totalItems) {
      setTotalItemsActive(0);
      setTotalPages(1);
      // return SearchNotFound
    } else if (tableData.length > 0) {
      result = createTableRows(tableData, 'Y');
    }
  } else {
    if (tableData.length > 0) {
      result = createTableRows(tableData, 'Y');
    }
  }

  if (!result) {
    return null;
  }

  return <React.Fragment>{result}</React.Fragment>;
};

export default CnpjTableRows;
