import config from '../../config';

export const TOKEN_KEY = config.cognito.userPoolWebClientId as string;

export const ID_TOKEN_KEY = ('id' +
  config.cognito.userPoolWebClientId) as string;

export const removeToken = (): void => localStorage.removeItem(TOKEN_KEY);

export const addToken = (jwt: string): void =>
  localStorage.setItem(TOKEN_KEY, jwt);

export const addIdToken = (jwt: string): void =>
  localStorage.setItem(ID_TOKEN_KEY, jwt);

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);

export const getIdToken = (): string | null =>
  localStorage.getItem(ID_TOKEN_KEY);
