/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface Props {
  customMainMsg?: string;
  customSubMsg?: string;
  iconName?: SemanticICONS;
  loading?: boolean;
  style?: any;
}

const NoData: React.FC<Props> = ({
  customMainMsg,
  customSubMsg,
  iconName,
  loading = true,
  style
}) => {
  return (
    <Segment padded="very" style={{ textAlign: 'center' }}>
      <Header icon>
        <Icon name={iconName || 'sync'} loading={loading} style={style} />
        {!customMainMsg
          ? `Parece que não foi encontrado nenhum registro, caso tenha feito um upload recente, por favor aguarde
				alguns instantes`
          : customMainMsg}
        <Header.Subheader>
          {!customSubMsg
            ? `Caso não tenha feito, tente adicionar manualmente ou importando com uma planilha do excel no menu acima`
            : customSubMsg}
        </Header.Subheader>
      </Header>
    </Segment>
  );
};

export default NoData;
