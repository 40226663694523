/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Divider, Grid, Segment } from 'semantic-ui-react';
import AppContext from '../../contexts/AppContext';
import api from '../../utils/helpers/axios.instance';
import ActivityInfos from '../Activities/Infos/ActivityInfos';
import ActivityLimitTotal from '../Activities/Infos/ActivityLimitTotal';
import ActivitySearch from '../Activities/Infos/ActivitySearch';
import ActivityTable from '../Activities/Table/ActivityTable';
import { IActivity } from '../Activities/_interfaces/activity.interfaces';
import Loading from '../Commom/Loading';
import NoData from '../Commom/NoData';
import { IError } from '../Commom/_interfaces/error.interface';

const Activities: React.FC = () => {
  const { userData } = useContext(AppContext);
  const [errors, setErrors] = useState<IError | boolean>(false);
  const [apiData, setApiData] = useState<IActivity[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);
  const [order, setOrder] = useState<any>('data_expiracao');
  const [descending, setDescending] = useState<any>(true);
  const [search, setSearch] = useState<any>('');
  const [dateIni, setDateIni] = useState<any>('');
  const [dateFim, setDateFim] = useState<any>('');
  const [queryString, setQueryString] = useState<any>(
    'descending=true&order=data_expiracao&size=10&page=1'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [totalItemsActive, setTotalItemsActive] = useState(0);
  const [totalCotados, setTotalCotados] = useState(0);
  const [unidadesCotadas, setUnidadesCotadas] = useState(0);
  const [mediaPorCotacao, setMediaPorCotacao] = useState(0);
  const [valorCotado, setValorCotado] = useState(0);

  const handleApiData = (apiData: IActivity[]) => {
    setApiData(apiData);
  };

  const handleSetLimit = (limit: any) => {
    setLimit(limit);
    setPage(1);
    setQueryString(
      `search=${search}&dateIni=${dateIni}&dateFim=${dateFim}&descending=${descending}&order=${order}&size=${limit}&page=1`
    );
  };

  const handleSetPage = (page: any) => {
    setPage(page);
    setQueryString(
      `search=${search}&dateIni=${dateIni}&dateFim=${dateFim}&descending=${descending}&order=${order}&size=${limit}&page=${page}`
    );
  };

  const handleSetOrder = (order: any, descending: any) => {
    setOrder(order);
    setDescending(descending);
    setQueryString(
      `search=${search}&dateIni=${dateIni}&dateFim=${dateFim}&descending=${descending}&order=${order}&size=${limit}&page=${page}`
    );
  };

  const handleSetFilter = (search: any, dateIni: any, dateFim: any) => {
    setSearch(search);
    setDateIni(dateIni);
    setDateFim(dateFim);
    setPage(1);
    setQueryString(
      `search=${search}&dateIni=${dateIni}&dateFim=${dateFim}&descending=${descending}&order=${order}&size=${limit}&page=1`
    );
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      if (userData) {
        const { data } = await api.get(
          `/activity/get/user/${userData.data.id}?${queryString}`
        );
        if (data) {
          handleApiData(data.pAtividades);
          setTotalItemsActive(data.totalItems);
          setTotalCotados(data.totalCotados);
          setValorCotado(data.valorCotado);
          setMediaPorCotacao(data.mediaPorCotacao);
          setUnidadesCotadas(data.unidadesCotadas);
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    };
    getData();
  }, [queryString, userData]);

  return (
    <Segment padded loading={isLoading}>
      <Grid
        style={{ margin: 2 }}
        verticalAlign="middle"
        stackable
        textAlign="center"
        divided
      >
        <Grid.Row style={{ placeContent: 'center' }}>
          <ActivityInfos
            average={mediaPorCotacao}
            units={unidadesCotadas}
            countQuoted={totalCotados}
            totalValueQuoted={valorCotado}
          />
        </Grid.Row>
        <Divider />
        <Grid.Row verticalAlign="middle" columns={5}>
          <ActivityLimitTotal
            limit={limit}
            totalItemsActive={totalItemsActive}
            setChangeLimit={handleSetLimit}
          />
          <ActivitySearch
            search={search}
            dateIni={dateIni}
            dateFim={dateFim}
            setChangeFilter={handleSetFilter}
          />
        </Grid.Row>
      </Grid>

      {isLoading ? (
        <Loading />
      ) : totalItemsActive === 0 ? (
        <NoData
          loading={false}
          iconName={'close'}
          customMainMsg={`Parece que não foi encontrado nenhum registro, caso tenha feito uma busca, utilize a opção Limpar pesquisa e datas.`}
          customSubMsg={`Caso não tenha feito, realmente não existem nenhuma atividade cadastrada no momento`}
        />
      ) : (
        <ActivityTable
          tableData={apiData}
          errors={errors}
          limit={limit}
          totalItems={totalItemsActive}
          page={page}
          setChangePage={handleSetPage}
          order={order}
          descending={descending}
          setChangeOrder={handleSetOrder}
        />
      )}
    </Segment>
  );
};

export default Activities;
