/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Table } from 'semantic-ui-react';

interface Props {
  order: string;
  descending: boolean;
  setChangeOrder: (order: any, descending: any) => void;
}

const ActivityTableHeader: React.FC<Props> = ({
  order,
  descending,
  setChangeOrder
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}) => {
  const handleClick = (newOrder: string) => {
    if (order === newOrder) {
      setChangeOrder(newOrder, !descending);
    } else {
      setChangeOrder(newOrder, false);
    }
  };

  useEffect(() => {
    // realizar ações aqui
  }, [order, descending]);

  return (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell
          sorted={
            order === 'external_id'
              ? descending
                ? 'descending'
                : 'ascending'
              : null
          }
          onClick={() => handleClick('external_id')}
        >
          ID Externo
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={
            order === 'comprador_nome'
              ? descending
                ? 'descending'
                : 'ascending'
              : null
          }
          onClick={() => handleClick('comprador_nome')}
        >
          Comprador
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={
            order === 'comprador_cidade_estado'
              ? descending
                ? 'descending'
                : 'ascending'
              : null
          }
          onClick={() => handleClick('comprador_cidade_estado')}
        >
          Cidade/Estado
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={
            order === 'data_expiracao'
              ? descending
                ? 'descending'
                : 'ascending'
              : null
          }
          onClick={() => handleClick('data_expiracao')}
        >
          Expira em
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={
            order === 'status_cotacao'
              ? descending
                ? 'descending'
                : 'ascending'
              : null
          }
          onClick={() => handleClick('status_cotacao')}
        >
          Status cotação
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={
            order === 'total' ? (descending ? 'descending' : 'ascending') : null
          }
          onClick={() => handleClick('total')}
        >
          Total
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export default ActivityTableHeader;
