import { IDropDown } from '../../components/Commom/_interfaces/dropdown.interface';
import {
  IB2sList,
  IB2sQueryResult
} from '../../components/Match/_interfaces/match.interfaces';
import { IItem } from '../../components/Rules/Item/_interfaces/item.interfaces';

export const matchB2SDropdownQueryOptions = (
  data: IB2sQueryResult[]
): IDropDown[] => {
  let result: IDropDown;
  const aux: IDropDown[] = [];
  if (data.length > 0) {
    data.map((d) => {
      result = {
        key: d.b2s_sku,
        value: d.b2s_sku,
        text: d.item
      };
      aux.push(result);
      return aux;
    });
  }
  return aux;
};

export const matchB2SDropdownShowAll = (data: IB2sList[]): IDropDown[] => {
  let result: IDropDown;
  const aux: IDropDown[] = [];
  if (data.length > 0) {
    data.map((d) => {
      result = {
        key: d.b2S_SKU,
        value: d.b2S_SKU,
        text: d.b2S_DESC
      };
      aux.push(result);
      return aux;
    });
  }
  return aux;
};

export const matchB2SDropdownOptions = (data: IB2sList[]): IDropDown[] => {
  let result: IDropDown;
  const aux: IDropDown[] = [];
  if (data.length > 0) {
    data.map((d) => {
      result = {
        key: d.b2S_SKU * Math.random() * 999,
        value: d.b2S_SKU,
        text: d.b2S_DESC
      };
      // if (d.prinC_ATIVO.toLowerCase() !== 'none') {
      //   const initialDesc = d.b2S_DESC.split('(')[0];
      //   const finalDesc = d.b2S_DESC.split(')')[1];
      //   result.text = `Item: ${initialDesc + finalDesc} - Principio Ativo: ${
      //     d.prinC_ATIVO
      //   }`;
      // } else {
      //   result.text = `Item: ${d.b2S_DESC}`;
      // }
      aux.push(result);
      return aux;
    });
  }

  return aux;
};

export const matchClientDropdownOptions = (data: IItem[]): IDropDown[] => {
  let result: IDropDown;
  const aux: IDropDown[] = [];
  if (data.length > 0) {
    data.map((d) => {
      result = {
        key: d.id,
        value: d.id,
        text: d.desC_CLIENTE
      };
      aux.push(result);
      return aux;
    });
  }

  return aux;
};

export const b2sCategoriesDropdownOptions = (
  categories: string[]
): IDropDown[] => {
  let count = 1;
  let result: IDropDown;
  const aux: IDropDown[] = [];
  if (categories.length > 0) {
    categories.map((cat) => {
      result = {
        key: count,
        value: cat,
        text: cat
      };
      aux.push(result);
      count++;
      return aux;
    });
  }

  return aux;
};
